import React, { useRef, useState, useEffect, useContext } from "react";
import {
    SttButton,
    SttLoading,
    SttGrid,
    SttInput,
    SttAutocomplete,
    SttTranslateHook
} from '@stt-componentes/core';
import {
    Formik,
    FastField,
    Field
} from 'formik';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../../request';
import {
    MOTIVO,
    JUSTIFICATIVA
} from './fieldNames';

const validationSchema = (strings) => {
    return yup.object().shape({
        [MOTIVO]: yup
            .object().shape({
                id: yup.number(),
                descricao: yup.string(),
                exige_complemento: yup.string(),
                etapa_fluxo: yup.string(),
            })
            .nullable()
            .required(strings.campoObrigatorio)
    });
}

const OperacaoDevolver = ({ callback, callbackSucesso, id, alerta,...props }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const formRef = useRef();
    const TFD_API_BASE_URL = global.gConfig.url_base_tfd;

    const initialValues = {
        justificativa: '',
        motivo: null
    };

    const [motivosDevolucao, setMotivosDevolucao] = useState(null);

    const {
        setMostrarAlerta,
        setTituloAlerta,
        setMensagemAlerta,
        setTipoAlerta,
        setOpcoesAlerta,
        setOnCloseAlerta
    } = alerta;

    useEffect(() => {
        axios
            .get(`${TFD_API_BASE_URL}/motivo-devolucao`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setMotivosDevolucao(itens);
                }
            })
            .catch(err => {
                setMotivosDevolucao([]);
                setTituloAlerta(strings.erro);
                setMensagemAlerta(strings.erroRecuperacaoMotivoDevolucao);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        }
                    }
                ]);
                setOnCloseAlerta(() => () => {
                    setMostrarAlerta(false);
                });
                setMostrarAlerta(true);
            });
    }, []);

    const closeAlerta = (resetForm) => {
        setMostrarAlerta(false);
        resetForm({ values: initialValues });
        callbackSucesso();
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(dados, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                dados.justificativa = dados.justificativa.trim().toUpperCase();
                dados.id = id;
                
                axios.post(`${TFD_API_BASE_URL}/devolver-processo`, dados, { headers: getHeaders() })
                    .then((response) => {

                        const { data } = response;
                        if (response.status === HttpStatus.OK) {
                            setTituloAlerta(strings.sucesso);
                            setMensagemAlerta(data.message ? data.message : strings.sucessoOperacao);
                            setTipoAlerta('success');
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        closeAlerta(resetForm);
                                    }
                                }
                            ]);
                            setOnCloseAlerta(() => () => {
                                closeAlerta(resetForm);
                            });
                            setMostrarAlerta(true);
                        }
                    })
                    .catch(err => {
                        const { response } = err;
                        let mensagem = strings.mensagemErroGeral;

                        if (response) {
                            if (response.status === HttpStatus.BAD_REQUEST) {
                                const { data } = response;
                                let arrMensagem = [];
                                data.errors.forEach(error => {
                                    arrMensagem.push(`- ${error.message}`);
                                });
                                if(arrMensagem) {
                                    mensagem = arrMensagem.join('\n');
                                }
                            }
                        }

                        setTituloAlerta(strings.erro);
                        setMensagemAlerta(mensagem);
                        setTipoAlerta('error');
                        setOpcoesAlerta([
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setMostrarAlerta(false);
                                }
                            }
                        ]);
                        setOnCloseAlerta(() => () => {
                            setMostrarAlerta(false);
                        });
                        setMostrarAlerta(true);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {
                ({
                    isSubmitting,
                    handleSubmit,
                    resetForm,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <Field name={MOTIVO}>
                                        {({
                                            field: { name, value, onBlur },
                                            form: { setFieldValue },
                                            meta,
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.motivo,
                                                    required: true,
                                                    error: meta.touched && meta.error ? meta.error : undefined
                                                }}
                                                getOptionLabel={option => (option && option.descricao) || ''}
                                                getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                options={motivosDevolucao}
                                                carregando={!motivosDevolucao}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={(e, item) => setFieldValue('motivo', item || null)}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12}>
                                    <FastField name={JUSTIFICATIVA}>
                                        {({
                                            field,
                                            meta
                                        }) => (
                                            <SttInput
                                                multiline
                                                rows={5}
                                                {...field}
                                                label={strings.justificativa}
                                                inputProps={{ style: { textTransform: "uppercase" } }}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.salvandoMensagemEspera}
                                />
                            </SttGrid>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        nomarginleft="true"
                                        disabled={isSubmitting}
                                    >
                                        {strings.confirmar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                        }}
                                    >
                                        {strings.limpar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                            callback();
                                        }}
                                    >
                                        {strings.fechar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        </form>
                    )
                }
            }
        </Formik>
    );
}

export default OperacaoDevolver;