import TYPES from '../types'

export const setAuthenticated = (authenticated) => {
    return {
        type: TYPES.SET_AUTHENTICATED,
        payload: { authenticated }
    };
}
export const setUser = (user) => {
    return {
        type: TYPES.SET_USER,
        payload: { user }
    };
}
export const logoutDispatch = () => {
    return {
        type: TYPES.LOGOUT
    };
}
export const setMessage = (message) => {
    return {
        type: TYPES.SET_MESSAGE,
        payload: { message }
    };
}
export const setParametrosPesquisa = (parametros) => {
    return {
        type: TYPES.SET_PARAMETROS_PESQUISA,
        payload: { parametros }
    };
}

export const limparParametrosPesquisa = () => {
    return {
        type: TYPES.LIMPAR_PARAMETROS_PESQUISA
    };
}

export const setTfd = (tfd) => {
    return {
        type: TYPES.SET_TFD,
        payload: tfd 
    };
}
 
export const setIdComprovanteComparecimento = (id) => {
    return {
        type: TYPES.SET_ID_COMPROVANTE_COMPARECIMENTO,
        payload: id 
    };
}

export const setPodeSalvarAutomatico = (podeSalvarAutomatico) => {
    return {
        type: TYPES.SET_PODE_SALVAR_AUTOMATICO,
        payload: podeSalvarAutomatico 
    };
}