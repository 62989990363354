import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { setAuthenticated as setAuthenticatedAction, setUser as setUserAction } from '../reducers/actions';
import { Switch, Route, useHistory } from 'react-router-dom';
import Erro401 from './erro/401';
import Menu from '../componentes/menu';
import { SttCircularProgress } from '@stt-componentes/core';

import Pesquisa from './pesquisa/index';
import Solicitacao from './solicitacao/index';
import { temPermissaoRBAC } from '../security/acl';
import { SttCacheManager } from '@stt-componentes/core';
import { Constants } from '@stt-componentes/cache';

const useStyles = makeStyles(theme => ({
    wrapper: {
        paddingBottom: theme.spacing(4)
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    }
}));

const Interno = ({ isAuthenticated, setAuthenticated, setUser }) => {
    const classes = useStyles();

    const history = useHistory();

    return (
        <div>
            {
                isAuthenticated
                    ?
                    <div className={classes.wrapper}>
                        <Menu />
                        <Switch>
                            <Route path="/solicitacao" exact component={Solicitacao} />
                            <Route path={['/', '/pesquisa']} exact component={Pesquisa} />
                            <Route path="/nao-autorizado" exact component={Erro401} />
                        </Switch>

                    </div>
                    :
                    <>
                        <div className={classes.loading}>
                            <SttCircularProgress />
                        </div>
                        <SttCacheManager config={global.gConfig} onSync={loaded => {
                            setAuthenticated(loaded);

                            const user = JSON.parse(localStorage.getItem(Constants.LOCALSTORAGE_ITEMS.USER_INFO));
                            if (!temPermissaoRBAC(user, 'TFD_TFD')) {
                                history.push(`/nao-autorizado`);
                            }
                            setUser(user);
                        }} />
                    </>
            }
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.index.isAuthenticated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthenticated: (authenticated) => dispatch(setAuthenticatedAction(authenticated)),
        setUser: (user) => dispatch(setUserAction(user))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Interno);
