module.exports = {
    alteracaoRede: 'Alteração de rede',
    aoVivo: 'Ao vivo',
    cadastroRede: 'Cadastro de rede',
    cadastroCategoria: 'Cadastro de categoria',
    categorias: 'Categorias',
    confirmarAlterarRede: 'Você tem certeza que deseja alterar esta rede?',
    confirmarSalvarRede: 'Você tem certeza que deseja cadastrar esta rede?',
    confirmarAlterarCategoria: 'Você tem certeza que deseja alterar esta categoria?',
    confirmarSalvarCategoria: 'Você tem certeza que deseja cadastrar esta categoria?',
    convites: 'Convites',
    instrucaoCadastroCategoria: `<span>- A partir dessa tela, é possível pesquisar e cadastrar categorias de redes.</span>
                <span>- A partir dessa tela, também é possível alterar e inativar categorias de redes previamente cadastradas.</span>`,
    instrucaoCadastroRede: `<span>- A partir dessa tela, é possível pesquisar e cadastrar redes de interação de usuários.</span>
                <span>- A partir dessa tela, também é possível alterar e inativar redes previamente cadastradas.</span>`,
    interacoes: 'Interações',
    interacoesFinalizadas: 'Interações finalizadas',
    permiteRegistro: 'Permite registro do atendimento?',
    proximasInteracoes: 'Próximas interações',
    redes: 'Redes'
};