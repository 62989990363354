import * as yup from 'yup';
import {
    DADOS_GERAIS,
    TIPO_PROCESSO,
    NUMERO_PEDIDO,
    DATA,
    MUNICIPIO_ORIGEM,
    CIDADE_AGENDADORA,
    SITUACAO_PACIENTE,
    CARATER_ATENDIMENTO,
    CATEGORIA_ATENDIMENTO_HOSPITALAR,
    PROCEDIMENTO_SOLICITADO,
    ESTADO_ORIGEM
} from './fieldNames';
import {
    TIPO_PROCESSO as TIPO_PROCESSO_CONSTANTES,
    CARATER_ATENDIMENTO as CARATER_ATENDIMENTO_CONSTANTES
} from '../../../../common/Constants';

const hoje = new Date();
hoje.setHours(23, 59, 0, 0);

export default (strings) => {
    return yup.object().shape({
        [DADOS_GERAIS]: yup.object().shape({
            [TIPO_PROCESSO]: yup
                .string()
                .nullable()
                .required(strings.tipoProcessoObrigatorio),
            [ESTADO_ORIGEM]: yup
                .object().shape({
                    id: yup.number(),
                    nome: yup.string()
                })
                .nullable()
                .required(strings.estadoOrigemObrigatorio),
            [MUNICIPIO_ORIGEM]: yup
                .object().shape({
                    id: yup.number(),
                    nome: yup.string()
                })
                .nullable()
                .required(strings.municipioOrigemObrigatorio),
            [CIDADE_AGENDADORA]: yup
                .object().shape({
                    id: yup.number(),
                    nome: yup.string()
                })
                .nullable()
                .required(strings.cidadeAgendadoraObrigatoria),
            [DATA]: yup
                .date()
                .typeError(strings.dataInvalida)
                .max(hoje, strings.erroDataFutura)
                .nullable()
                .required(strings.dataPedidoObrigatoria),
            [NUMERO_PEDIDO]: yup
                .string()
                .when(TIPO_PROCESSO, {
                    is: (val) => val !== TIPO_PROCESSO_CONSTANTES.JUDICIAL,
                    then: yup.string()
                        .test('tamanho', strings.numeroPedidoCaracteres, (val) => {
                            if (!val) {
                                return true;
                            }

                            return val.toString().length <= 20;
                        })
                        .required(strings.numeroPedidoObrigatorio)
                }),
            [PROCEDIMENTO_SOLICITADO]: yup
                .string()
                .when(TIPO_PROCESSO, {
                    is: (val) => val !== TIPO_PROCESSO_CONSTANTES.JUDICIAL,
                    then: yup.string()
                        .required(strings.campoObrigatorio)
                }),
            [SITUACAO_PACIENTE]: yup
                .string()
                .when(TIPO_PROCESSO, {
                    is: (val) => val !== TIPO_PROCESSO_CONSTANTES.JUDICIAL,
                    then: yup.string()
                        .nullable()
                        .required(strings.situacaoPaciente)
                }),
            [CARATER_ATENDIMENTO]: yup.string()
                .when(TIPO_PROCESSO, {
                    is: (val) => val === TIPO_PROCESSO_CONSTANTES.INTRAESTADUAL,
                    then: yup.string()
                        .nullable()
                        .required(strings.caraterAtendimentoObrigatorio)
                }),
            [CATEGORIA_ATENDIMENTO_HOSPITALAR]: yup.string()
                .when(CARATER_ATENDIMENTO, {
                    is: (val) => val === CARATER_ATENDIMENTO_CONSTANTES.HOSPITALAR,
                    then: yup.string()
                        .nullable()
                        .required(strings.categoriaAtendimentoHospitalarObrigatoria)
                }).nullable(),
        }).required()
    });
}
