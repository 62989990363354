import * as yup from 'yup'

import dadosGeraisSchema from '../../componentes/solicitacao/dadosGerais/form/validationSchema';
import dadosInterestadualSchema from '../../componentes/solicitacao/interestadual/form/validationSchema';
import dadosJudicialSchema from '../../componentes/solicitacao/judicial/form/validationSchema';
import dadosTransporteSchema from '../../componentes/solicitacao/transporte/form/validationSchema';
import dadosMedicoSolicitanteSchema from '../../componentes/solicitacao/medicoSolicitante/validationSchema';
import dadosAnexosSchema from '../../componentes/solicitacao/anexos/validationSchema';
import dadosPacienteSchema from '@stt-componentes/paciente/dist/lib/form/validationSchema';

export default (strings, camposPaciente) => {
    const schema = yup.object().shape({});
    const dadosGerais = dadosGeraisSchema(strings);
    const dadosPaciente = yup.object().shape({ ['paciente']: dadosPacienteSchema(strings, camposPaciente) });
    const dadosJudicial = dadosJudicialSchema(strings);
    const dadosTransporte = dadosTransporteSchema(strings);
    const dadosMedicoSolicitante = dadosMedicoSolicitanteSchema(strings);
    const dadosAnexos = dadosAnexosSchema(strings);
    const dadosInterestadual = dadosInterestadualSchema(strings);

    return schema
        .concat(dadosGerais)
        .concat(dadosPaciente)
        .concat(dadosInterestadual)
        .concat(dadosJudicial)
        .concat(dadosTransporte)
        .concat(dadosMedicoSolicitante)
        .concat(dadosAnexos);
}