import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Util } from '@stt-utilitarios/core';
import { useHistory } from 'react-router-dom';
import Acoes from '../../common/Acoes';
import moment from 'moment';
import axios from 'axios';
import { situacao } from '../../common/Constants';
import {
    SttButton,
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttCarimboTempo,
    SttLink,
    SttDivider,
    SttAlerta,
    SttLoading,
    SttTranslateHook,
} from '@stt-componentes/core';
import {
    TIPO_OPERACAO,
    SITUACAO_PROCESSO,
    TIPO_PROCESSO as TIPO_PROCESSO_CONSTANTES,
} from '../../common/Constants';
import Operacoes from '../pesquisa/operacoes';
import {
    setTfd as setTfdAction,
    setIdComprovanteComparecimento as setIdComprovanteComparecimentoAction,
} from '../../reducers/actions/index';
import { getHeaders } from '../../request';
import HttpStatus from 'http-status-codes';
import DeleteIcon from '@material-ui/icons/Delete';

import { IconButton } from '@material-ui/core';

const { LABEL } = SITUACAO_PROCESSO;
const useStyles = makeStyles((theme) => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
    textoQuebraLinha: {
        wordBreak: 'break-all',
        whiteSpace: 'pre-line',
        textTransform: 'uppercase',
    },
    buttonWrapper: {
        marginTop: theme.spacing(1),
    },
    divWrapperMovimentacao: {
        marginBottom: theme.spacing(1),
    },
    button: {
        marginBottom: theme.spacing(1),
    },
    textItem: {
        textTransform: 'uppercase',
    },
}));
const useStylesHeading = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
}));

const Detalhes = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const {
        id,
        setIdSolicitacaoSelecionada,
        user,
        voltar,
        setTfd,
        tfd,
        setIdComprovanteComparecimento,
        idComprovanteComparecimento,
    } = props;

    const TFD_API_BASE_URL = global.gConfig.url_base_tfd;
    const TFD_PASSAGENS_BASE_URL = global.gConfig.url_base_tfd_passagens;
    const TFD_PASSAGENS_API_BASE_URL = global.gConfig.url_base_tfd_api_passagens;
    const classes = useStyles();
    const classesHeading = useStylesHeading();
    const [detalhesTfd, setDetalhesTfd] = useState(null);
    const history = useHistory();

    //Tratamento de operações
    const [openModalOperacao, setOpenModalOperacao] = useState(false);
    const [pesquisandoTfdPassagens, setPesquisandoTfdPassagens] = useState(false);
    const [tipoOperacao, setTipoOperacao] = useState(null);

    //Alertas
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const [onCloseAlerta, setOnCloseAlerta] = useState(() => {
        setMostrarAlerta(false);
    });

    const alerta = {
        setMostrarAlerta,
        setTituloAlerta,
        setMensagemAlerta,
        setTipoAlerta,
        setOpcoesAlerta,
        setOnCloseAlerta,
    };

    //Carregando
    const [erroCarregamento, setErroCarregamento] = useState(false);
    const [carregado, setCarregado] = useState(false);
    const [carregandoArquivo, setCarregandoArquivo] = useState(false);

    const imprimirTfd = () => {
        const idCodificado = window.btoa(id);
        const params = {
            idCodificado,
        };

        setCarregandoArquivo(true);

        axios
            .get(`${TFD_API_BASE_URL}/impressao`, {
                params,
                headers: getHeaders(),
                responseType: 'blob',
            })
            .then((response) => {
                setCarregandoArquivo(false);
                if (response.data) {
                    const fileURL = URL.createObjectURL(response.data);
                    window.open(fileURL);
                }
            })
            .catch((err) => {
                const { response } = err;
                let mensagem = strings.erroOperacao;

                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        if (data && data.errors && data.errors.length > 0) {
                            let arrMensagem = [];
                            data.errors.forEach((error) => {
                                arrMensagem.push(`- ${error.message}`);
                            });
                            if (arrMensagem.length > 0) {
                                mensagem = arrMensagem.join('\n');
                            }
                        } else {
                            mensagem =
                                `${response.statusText} (código: ${response.status})` ||
                                strings.erro;
                        }
                    } else {
                        mensagem =
                            `${response.statusText} (código: ${response.status})` ||
                            strings.erro;
                    }
                } else {
                    mensagem = `${err.message} (código: ${err.code})` || strings.erro;
                }

                setTituloAlerta(strings.erro);
                setMensagemAlerta(mensagem);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        },
                    },
                ]);
                setMostrarAlerta(true);
                setCarregandoArquivo(false);
            });
    };

    useEffect(() => {
        if (id) {
            axios
                .get(`${TFD_API_BASE_URL}/solicitacao/${id}`, { headers: getHeaders() })
                .then((response) => {
                    const dados = response.data.data;
                    formatarDadosExibicao(dados);
                    dados.situacao_formatada = LABEL[dados.situacao];
                    setDetalhesTfd(dados);
                    setTfd({ ...tfd, id: id, situacao: dados.situacao });
                    setCarregado(true);
                })
                .catch((err) => {
                    const { response } = err;
                    let mensagem = strings.erroOperacao;

                    if (response) {
                        if (response.status === HttpStatus.BAD_REQUEST) {
                            const { data } = response;
                            if (data && data.errors && data.errors.length > 0) {
                                let arrMensagem = [];
                                data.errors.forEach((error) => {
                                    arrMensagem.push(`- ${error.message}`);
                                });
                                if (arrMensagem.length > 0) {
                                    mensagem = arrMensagem.join('\n');
                                }
                            } else {
                                mensagem =
                                    `${response.statusText} (código: ${response.status})` ||
                                    strings.erro;
                            }
                        } else {
                            mensagem =
                                `${response.statusText} (código: ${response.status})` ||
                                strings.erro;
                        }
                    } else {
                        mensagem = `${err.message} (código: ${err.code})` || strings.erro;
                    }

                    setTituloAlerta(strings.erroCarregarSolicitacao);
                    setMensagemAlerta(mensagem);
                    setTipoAlerta('error');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setMostrarAlerta(false);
                            },
                        },
                    ]);
                    setMostrarAlerta(true);
                    setErroCarregamento(true);
                });
        }
    }, [id]);

    const formatarDadosExibicao = (dados) => {
        if (dados.cpf_paciente) {
            dados.cpf_paciente = (
                '00000000000' + dados.cpf_paciente.replace(/[\. ,:-]+/g, '')
            ).slice(-11);
        }

        if (dados.cpf_funcionario_requisitante) {
            dados.cpf_funcionario_requisitante = (
                '00000000000' +
                dados.cpf_funcionario_requisitante.replace(/[\. ,:-]+/g, '')
            ).slice(-11);
        }

        dados.acoes = Acoes.definirAcoes(user, dados);

        if (
            dados.numero_conselho_regulador &&
            dados.sigla_estado_conselho_regulador &&
            dados.descricao_conselho_regulador
        ) {
            dados.conselho_trabalho_regulador_formatado = `${dados.descricao_conselho_regulador} ${dados.numero_conselho_regulador}/${dados.sigla_estado_conselho_regulador}`;
        }

        if (dados.anexos?.length) {
            dados.anexos.forEach((anexo) => {
                anexo.idCodificado = window.btoa(anexo.id);
            });
        }

        if (
            dados.comprovantesComparecimento &&
            dados.comprovantesComparecimento.length
        ) {
            dados.comprovantesComparecimento.forEach((comprovante) => {
                if (comprovante.anexos?.length) {
                    comprovante.anexos.forEach((anexo) => {
                        anexo.idCodificado = window.btoa(anexo.id);
                    });
                }
            });
        }

        if (dados.data_nascimento_paciente) {
            let dataMoment = moment(dados.data_nascimento_paciente);

            if (dataMoment.isValid()) {
                var now = moment();
                var dataMomentFormatada = moment(dataMoment, 'DD-MM-YYYY');
                var idade = moment.duration(now.diff(dataMomentFormatada));
                var anos = idade.years();
                var meses = idade.months();
                var dias = idade.days();
                dados.data_nascimento_paciente_formatada += ` (${anos} ano(s), ${meses} mes(es) e ${dias} dia(s))`;
            }
        }
    };

    const callbackVoltar = () => {
        voltar(true);
    };

    //Operações
    const abrirOperacao = (tipo) => {
        setTipoOperacao(tipo);
        setOpenModalOperacao(true);
    };

    const abrirConfirmacaoAgendamentoCNRAC = () => {
        setTituloAlerta(strings.confirmacao);
        setTipoAlerta('alert');
        setMensagemAlerta(strings.confirmacaoAgendamentoCnrac);
        setOpcoesAlerta([
            {
                title: strings.ok,
                onClick: () => {
                    confirmarAgendamentoCNRAC();
                },
            },
            {
                title: strings.cancelar,
                onClick: () => {
                    setMostrarAlerta(false);
                },
            },
        ]);
        setMostrarAlerta(true);
    };

    const abrirConfirmacaoAgendamento = () => {
        setTituloAlerta(strings.confirmacao);
        setTipoAlerta('alert');
        setMensagemAlerta(strings.confirmacaoRealizacaoAgendamento);
        setOpcoesAlerta([
            {
                title: strings.ok,
                onClick: () => {
                    confirmarAgendamento();
                },
            },
            {
                title: strings.cancelar,
                onClick: () => {
                    setMostrarAlerta(false);
                },
            },
        ]);
        setMostrarAlerta(true);
    };

    const abrirAguardandoAgendamento = () => {
        setTituloAlerta(strings.confirmacao);
        setTipoAlerta('alert');
        setMensagemAlerta(strings.confirmacaoAguardandoAgendamento);
        setOpcoesAlerta([
            {
                title: strings.ok,
                onClick: () => {
                    aguardarAgendamento();
                },
            },
            {
                title: strings.cancelar,
                onClick: () => {
                    setMostrarAlerta(false);
                },
            },
        ]);
        setMostrarAlerta(true);
    };

    const confirmarAgendamento = () => {
        let data = {};
        data.id = id;

        axios
            .post(`${TFD_API_BASE_URL}/confirmar-agendamento`, data, {
                headers: getHeaders(),
            })
            .then((response) => {
                const { data } = response;
                if (response.status === HttpStatus.OK) {
                    setTituloAlerta(strings.sucesso);
                    setMensagemAlerta(
                        data.message ? data.message : strings.sucessoOperacao
                    );
                    setTipoAlerta('success');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setMostrarAlerta(false);
                                voltar();
                            },
                        },
                    ]);
                    setMostrarAlerta(true);
                } else {
                    setTituloAlerta(strings.erro);
                    setMensagemAlerta(data.message ? data.message : strings.erroOperacao);
                    setTipoAlerta('error');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setMostrarAlerta(false);
                            },
                        },
                    ]);
                    setMostrarAlerta(true);
                }
            })
            .catch((err) => {
                setTituloAlerta(strings.erro);
                setMensagemAlerta(err.message ? err.message : strings.erroOperacao);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        },
                    },
                ]);
                setMostrarAlerta(true);
            });
    };

    const aguardarAgendamento = () => {
        let data = {};
        data.id = id;

        axios
            .post(`${TFD_API_BASE_URL}/aguardar-agendamento`, data, {
                headers: getHeaders(),
            })
            .then((response) => {
                const { data } = response;
                if (response.status === HttpStatus.OK) {
                    setTituloAlerta(strings.sucesso);
                    setMensagemAlerta(
                        data.message ? data.message : strings.sucessoOperacao
                    );
                    setTipoAlerta('success');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setMostrarAlerta(false);
                                voltar();
                            },
                        },
                    ]);
                    setMostrarAlerta(true);
                } else {
                    setTituloAlerta(strings.erro);
                    setMensagemAlerta(data.message ? data.message : strings.erroOperacao);
                    setTipoAlerta('error');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setMostrarAlerta(false);
                            },
                        },
                    ]);
                    setMostrarAlerta(true);
                }
            })
            .catch((err) => {
                setTituloAlerta(strings.erro);
                setMensagemAlerta(err.message ? err.message : strings.erroOperacao);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        },
                    },
                ]);
                setMostrarAlerta(true);
            });
    };

    const confirmarAgendamentoCNRAC = () => {
        let data = {};
        data.id = id;

        axios
            .post(`${TFD_API_BASE_URL}/confirmar-agendamento-cnrac`, data, {
                headers: getHeaders(),
            })
            .then((response) => {
                const { data } = response;
                if (response.status === HttpStatus.OK) {
                    setTituloAlerta(strings.sucesso);
                    setMensagemAlerta(
                        data.message ? data.message : strings.sucessoOperacao
                    );
                    setTipoAlerta('success');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setMostrarAlerta(false);
                                voltar();
                            },
                        },
                    ]);
                    setMostrarAlerta(true);
                } else {
                    setTituloAlerta(strings.erro);
                    setMensagemAlerta(data.message ? data.message : strings.erroOperacao);
                    setTipoAlerta('error');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setMostrarAlerta(false);
                            },
                        },
                    ]);
                    setMostrarAlerta(true);
                }
            })
            .catch((err) => {
                setTituloAlerta(strings.erro);
                setMensagemAlerta(err.message ? err.message : strings.erroOperacao);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        },
                    },
                ]);
                setMostrarAlerta(true);
            });
    };

    const abrirFormularioEdicao = () => {
        history.replace('/solicitacao');
    };

    const pesquisarRascunhosPassagemPorIdTfd = () => {
        setPesquisandoTfdPassagens(true);
        axios
            .get(
                `${TFD_PASSAGENS_API_BASE_URL}/pesquisar-rascunhos-passagem-por-id-tfd/${detalhesTfd.id}`,
                { headers: getHeaders() }
            )
            .then((response) => {
                const { data } = response;

                //Não há nenhum processo em rascunho.
                if (data.data && data.data.length == 0) {
                    abrirSolicitacaoTransporte();
                }

                //Há um processo de rascunho para ser editado, então redireciona a ele
                if (data.data && data.data.length > 0) {
                    setTituloAlerta(strings.atencao);
                    setMensagemAlerta(strings.redirecionarSolicitacaoPassagemExistente);
                    setTipoAlerta('alert');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setMostrarAlerta(false);
                                redirecionarSolicitacaoTransporte(data.data[0].id);
                            },
                        },
                    ]);
                    setMostrarAlerta(true);
                }
            })
            .catch((err) => {
                const { response } = err;
                let mensagem = strings.erroSolicitacaoPassagem;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach((error) => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        mensagem = arrMensagem.join('\n');
                    }
                }
                setTituloAlerta(strings.erro);
                setMensagemAlerta(mensagem);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        },
                    },
                ]);
                setMostrarAlerta(true);
            })
            .finally(() => {
                setPesquisandoTfdPassagens(false);
            });
    };

    const abrirSolicitacaoTransporte = () => {
        let dados = {};
        dados.idTfd = detalhesTfd.id;

        axios
            .post(`${TFD_PASSAGENS_API_BASE_URL}/processo`, dados, {
                headers: getHeaders(),
            })
            .then((response) => {
                const { data } = response;
                setTituloAlerta(strings.atencao);
                setMensagemAlerta(strings.redirecionarSolicitacaoPassagem);
                setTipoAlerta('alert');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                            redirecionarSolicitacaoTransporte(data.data.idPassagem);
                        },
                    },
                ]);
                setMostrarAlerta(true);
            })
            .catch((err) => {
                const { response } = err;
                let mensagem = strings.erroSolicitacaoPassagem;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach((error) => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        mensagem = arrMensagem.join('\n');
                    }
                }
                setTituloAlerta(strings.erro);
                setMensagemAlerta(mensagem);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        },
                    },
                ]);
                setMostrarAlerta(true);
            });
    };

    const redirecionarSolicitacaoTransporte = (idTfdPassagem) => {
        const idCodificado = window.btoa(idTfdPassagem);
        const tokenLocalStorage = localStorage.getItem('stt-authorization');
        var url = `${TFD_PASSAGENS_BASE_URL}/solicitacao/?id=${idCodificado}&token=${tokenLocalStorage}`;
        window.open(url, '_blank');
    };

    const callbackRetornoOperacao = (idNovoProcesso) => {
        if (tipoOperacao === TIPO_OPERACAO.INFORMAR_PROCEDIMENTO_SOLICITADO) {
            setIdSolicitacaoSelecionada(idNovoProcesso);
            setCarregado(false);
        }
    };

    const baixarArquivoTfd = (url) => {
        setCarregandoArquivo(true);
        axios
            .get(url, { responseType: 'blob', headers: getHeaders(), timeout: 0 })
            .then((response) => {
                setCarregandoArquivo(false);
                var fileURL = URL.createObjectURL(response.data);
                window.open(fileURL);
            })
            .catch((err) => {
                const { response } = err;
                let mensagem = strings.erroOperacao;

                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        if (data && data.errors && data.errors.length > 0) {
                            let arrMensagem = [];
                            data.errors.forEach((error) => {
                                arrMensagem.push(`- ${error.message}`);
                            });
                            if (arrMensagem.length > 0) {
                                mensagem = arrMensagem.join('\n');
                            }
                        } else {
                            mensagem =
                                `${response.statusText} (código: ${response.status})` ||
                                strings.erro;
                        }
                    } else {
                        mensagem =
                            `${response.statusText} (código: ${response.status})` ||
                            strings.erro;
                    }
                } else {
                    mensagem = `${err.message} (código: ${err.code})` || strings.erro;
                }

                setTituloAlerta(strings.erroBaixarArquivo);
                setMensagemAlerta(mensagem);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        },
                    },
                ]);
                setMostrarAlerta(true);
                setCarregandoArquivo(false);
            });
    };

    if (erroCarregamento) {
        return (
            <SttContainer>
                <SttButton
                    type='button'
                    variant='outlined'
                    color='primary'
                    onClick={callbackVoltar}
                    nomarginleft='true'
                >
                    Voltar
                </SttButton>

                <SttAlerta
                    open={mostrarAlerta}
                    title={tituloAlerta}
                    message={mensagemAlerta}
                    type={tipoAlerta}
                    onClose={onCloseAlerta}
                    options={opcoesAlerta}
                />
            </SttContainer>
        );
    }

    return (
        <SttContainer>
            <SttButton
                type='button'
                variant='outlined'
                color='primary'
                onClick={callbackVoltar}
                nomarginleft='true'
            >
                Voltar
            </SttButton>
            {!carregado ? (
                <div className={classes.carregando}>
                    <SttCircularProgress color='primary' />
                </div>
            ) : (
                <>
                    <SttHeading variant='h1' color='primary' align='center'>
                        {strings.solicitacaoTfd}
                    </SttHeading>
                    <SttExpansionPanel
                        title='Dados gerais'
                        children={
                            <div>
                                {detalhesTfd.situacao_formatada && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='gerais_16'
                                        title={strings.situacaoSolicitacao}
                                        content={detalhesTfd.situacao_formatada}
                                    />
                                )}
                                {detalhesTfd.numero_pedido && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='gerais_1'
                                        title={strings.numeroPedido}
                                        content={detalhesTfd.numero_pedido}
                                    />
                                )}
                                {detalhesTfd.itinerario && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='gerais_19'
                                        title={strings.tipoProcesso}
                                        content={detalhesTfd.itinerario}
                                    />
                                )}
                                {detalhesTfd.cidade_uf_origem && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='gerais_2'
                                        title={strings.cidadeOrigem}
                                        content={detalhesTfd.cidade_uf_origem}
                                    />
                                )}
                                {detalhesTfd.cidade_uf_destino && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='gerais_3'
                                        title={strings.cidadeDestino}
                                        content={detalhesTfd.cidade_uf_destino}
                                    />
                                )}
                                {detalhesTfd.descricao_cidade_agendadora && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='gerais_21'
                                        title={strings.regionalDestino}
                                        content={detalhesTfd.descricao_cidade_agendadora}
                                    />
                                )}
                                {detalhesTfd.data_tfd_formatada && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='gerais_4'
                                        title={strings.dataInclusaoProcesso}
                                        content={detalhesTfd.data_tfd_formatada}
                                    />
                                )}
                                {detalhesTfd.nome_regulador && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='gerais_5'
                                        title={strings.regulador}
                                        content={detalhesTfd.nome_regulador}
                                    />
                                )}
                                {detalhesTfd.conselho_trabalho_regulador_formatado && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='gerais_22'
                                        title={strings.conselhoTrabalhoRegulador}
                                        content={detalhesTfd.conselho_trabalho_regulador_formatado}
                                    />
                                )}
                                {detalhesTfd.procedimento_solicitado && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='gerais_6'
                                        title={strings.procedimentoSolicitacdo}
                                        content={detalhesTfd.procedimento_solicitado}
                                    />
                                )}
                                {detalhesTfd.carater_atendimento_formatado && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='gerais_17'
                                        title={strings.caraterAtendimento}
                                        content={detalhesTfd.carater_atendimento_formatado}
                                    />
                                )}
                                {detalhesTfd.categoria_atendimento_hospitalar_formatado && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='gerais_18'
                                        title={strings.categoriaAtendimentoHospitalar}
                                        content={
                                            detalhesTfd.categoria_atendimento_hospitalar_formatado
                                        }
                                    />
                                )}
                                {detalhesTfd.diagnostico_inicial && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='gerais_7'
                                        title={strings.diagnosticoInicial}
                                        content={detalhesTfd.diagnostico_inicial}
                                    />
                                )}
                                {detalhesTfd.numero_laudo_cnrac && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='gerais_8'
                                        title={strings.numeroLaudoCnrac}
                                        content={detalhesTfd.numero_laudo_cnrac}
                                    />
                                )}
                                {detalhesTfd.descricao_tfd_cadastro_procedimento_solicitado && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='gerais_20'
                                        title={strings.procedimentoSolicitadoPosSolicitacao}
                                        content={
                                            detalhesTfd.descricao_tfd_cadastro_procedimento_solicitado
                                        }
                                    />
                                )}
                            </div>
                        }
                    />

                    {detalhesTfd['tipo_processo'] ==
                        TIPO_PROCESSO_CONSTANTES.JUDICIAL && (
                            <SttExpansionPanel
                                title={strings.dadosJudicial}
                                children={
                                    <div>
                                        {detalhesTfd.sigla_orgao_sgpe && (
                                            <SttTextItem
                                                classContent={classes.textItem}
                                                key='judicial_1'
                                                title={strings.siglaOrgaoSgpe}
                                                content={detalhesTfd.sigla_orgao_sgpe}
                                            />
                                        )}
                                        {detalhesTfd.numero_processo_sgpe && (
                                            <SttTextItem
                                                classContent={classes.textItem}
                                                key='judicial_2'
                                                title={strings.numeroProcessoSgpe}
                                                content={detalhesTfd.numero_processo_sgpe}
                                            />
                                        )}
                                        {detalhesTfd.ano_processo_sgpe && (
                                            <SttTextItem
                                                classContent={classes.textItem}
                                                key='judicial_3'
                                                title={strings.anoProcessoSgpe}
                                                content={detalhesTfd.ano_processo_sgpe}
                                            />
                                        )}
                                        {detalhesTfd.unidade_atendimento_hospitalar && (
                                            <SttTextItem
                                                classContent={classes.textItem}
                                                key='judicial_4'
                                                title={strings.unidadeAtendimentoHospitalar}
                                                content={detalhesTfd.unidade_atendimento_hospitalar}
                                            />
                                        )}
                                        {detalhesTfd.prazo_cumprimento_processo_formatado && (
                                            <SttTextItem
                                                classContent={classes.textItem}
                                                key='judicial_5'
                                                title={strings.prazoCumprimentoProcesso}
                                                content={detalhesTfd.prazo_cumprimento_processo_formatado}
                                            />
                                        )}
                                        {detalhesTfd.numero_autos_processo_sgpe && (
                                            <SttTextItem
                                                classContent={classes.textItem}
                                                key='judicial_6'
                                                title={strings.numeroAutos}
                                                content={Util.util.adicionarMascara(
                                                    detalhesTfd.numero_autos_processo_sgpe,
                                                    '0000000-00.0000.0.00.0000'
                                                )}
                                            />
                                        )}
                                        {detalhesTfd.numero_registro_sisreg && (
                                            <SttTextItem
                                                classContent={classes.textItem}
                                                key='judicial_8'
                                                title={strings.numeroRegistroSisreg}
                                                content={detalhesTfd.numero_registro_sisreg}
                                            />
                                        )}
                                    </div>
                                }
                            />
                        )}

                    {detalhesTfd.id_paciente && (
                        <SttExpansionPanel
                            title={strings.dadosPaciente}
                            children={
                                <div>
                                    {detalhesTfd.nome_paciente && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='paciente_1'
                                            title={strings.nome}
                                            content={detalhesTfd.nome_paciente}
                                        />
                                    )}
                                    {detalhesTfd.nome_mae && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='paciente_16'
                                            title={strings.nomeMae}
                                            content={detalhesTfd.nome_mae}
                                        />
                                    )}
                                    {detalhesTfd.sexo_paciente && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='paciente_2'
                                            title={strings.sexo}
                                            content={detalhesTfd.sexo_paciente}
                                        />
                                    )}
                                    {detalhesTfd.data_nascimento_paciente_formatada && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='paciente_3'
                                            title={strings.dataNascimento}
                                            content={detalhesTfd.data_nascimento_paciente_formatada}
                                        />
                                    )}
                                    {detalhesTfd.cpf_paciente && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='paciente_4'
                                            title={strings.cpf}
                                            content={Util.util.adicionarMascara(
                                                detalhesTfd.cpf_paciente,
                                                '000.000.000-00'
                                            )}
                                        />
                                    )}
                                    {detalhesTfd.cartao_sus_paciente && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='paciente_5'
                                            title={strings.cartaoSus}
                                            content={detalhesTfd.cartao_sus_paciente}
                                        />
                                    )}
                                    {detalhesTfd.peso_paciente && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='paciente_6'
                                            title={strings.peso}
                                            content={detalhesTfd.peso_paciente}
                                        />
                                    )}
                                    {detalhesTfd.altura_paciente && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='paciente_7'
                                            title={strings.altura}
                                            content={detalhesTfd.altura_paciente}
                                        />
                                    )}
                                    {detalhesTfd.grupo_sanguineo_paciente && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='paciente_8'
                                            title={strings.grupoSanguineo}
                                            content={detalhesTfd.grupo_sanguineo_paciente}
                                        />
                                    )}
                                    {detalhesTfd.fator_rh_paciente && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='paciente_9'
                                            title={strings.fatorRh}
                                            content={detalhesTfd.fator_rh_paciente}
                                        />
                                    )}
                                    {detalhesTfd.logradouro_paciente && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='paciente_10'
                                            title={strings.logradouro}
                                            content={detalhesTfd.logradouro_paciente}
                                        />
                                    )}
                                    {detalhesTfd.bairro_paciente && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='paciente_11'
                                            title={strings.bairro}
                                            content={detalhesTfd.bairro_paciente}
                                        />
                                    )}
                                    {detalhesTfd.cep_paciente && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='paciente_12'
                                            title={strings.cep}
                                            content={Util.util.adicionarMascara(
                                                detalhesTfd.cep_paciente,
                                                '00000-000'
                                            )}
                                        />
                                    )}
                                    {detalhesTfd.cidade_uf_paciente && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='paciente_13'
                                            title={strings.cidade}
                                            content={detalhesTfd.cidade_uf_paciente}
                                        />
                                    )}
                                    {detalhesTfd.numero_paciente && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='paciente_14'
                                            title={strings.numero}
                                            content={detalhesTfd.numero_paciente}
                                        />
                                    )}
                                    {
                                        // detalhesTfd.contatosPaciente &&
                                        detalhesTfd.contatosPaciente.map((contato, index) => {
                                            return (
                                                <div key={index}>
                                                    <SttTextItem
                                                        key={`paciente_14_${index}`}
                                                        title={contato.descricao_tipo_contato}
                                                        content={Util.util.adicionarMascara(
                                                            contato.contato,
                                                            '(00) 90000-0000'
                                                        )}
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                    {detalhesTfd.situacao_paciente_formatado && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='paciente_15'
                                            title={strings.situacaoPaciente}
                                            content={detalhesTfd.situacao_paciente_formatado}
                                        />
                                    )}
                                </div>
                            }
                        />
                    )}

                    {detalhesTfd.sigtap && detalhesTfd.sigtap.length > 0 && (
                        <SttExpansionPanel
                            title={strings.procedimentoSigtap}
                            children={detalhesTfd.sigtap.map((sigtap, index) => (
                                <div key={index}>
                                    <SttTextItem
                                        key={`sigtap_${index}`}
                                        title={`Procedimento ${index + 1}`}
                                        content={sigtap.procedimento_sigtap}
                                    />
                                </div>
                            ))}
                        />
                    )}

                    {detalhesTfd.cid10 && detalhesTfd.cid10.length > 0 && (
                        <SttExpansionPanel
                            title={strings.cid10}
                            children={detalhesTfd.cid10.map((cid10, index) => (
                                <div key={index}>
                                    {cid10.descricao_categoria && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key={`cid10_1_${index}`}
                                            title={strings.categoria}
                                            content={cid10.descricao_categoria}
                                        />
                                    )}
                                    {cid10.descricao_subcategoria && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key={`cid10_2_${index}`}
                                            title={strings.subcategoria}
                                            content={cid10.descricao_subcategoria}
                                        />
                                    )}
                                </div>
                            ))}
                        />
                    )}

                    <SttExpansionPanel
                        title={strings.dadosSolicitacao}
                        children={
                            <div>
                                {detalhesTfd.historico_doenca && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_19'
                                        title={strings.historicoDoenca}
                                        content={detalhesTfd.historico_doenca}
                                    />
                                )}
                                {detalhesTfd.exame_fisico && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_1'
                                        title={strings.exameFisico}
                                        content={detalhesTfd.exame_fisico}
                                    />
                                )}
                                {detalhesTfd.exame_complementar && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_2'
                                        title={strings.examesComplementaresRealizados}
                                        content={detalhesTfd.exame_complementar}
                                    />
                                )}
                                {detalhesTfd.diagnostico_procedimento && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_3'
                                        title={strings.diagnosticoRelacionadoProcedimentoSolicitado}
                                        content={detalhesTfd.diagnostico_procedimento}
                                    />
                                )}
                                {detalhesTfd.tratamento_realizado && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_4'
                                        title={strings.tratamentoRealizado}
                                        content={detalhesTfd.tratamento_realizado}
                                    />
                                )}
                                {detalhesTfd.condicao_internacao_consulta && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_5'
                                        title={strings.condicoesJustificamInternacao}
                                        content={detalhesTfd.condicao_internacao_consulta}
                                    />
                                )}
                                {detalhesTfd.classe_funcional_insuficiencia_cardiaca && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_6'
                                        title={strings.classeFuncionalInsuficienciaCardiaca}
                                        content={
                                            detalhesTfd.classe_funcional_insuficiencia_cardiaca
                                        }
                                    />
                                )}
                                {detalhesTfd.funcao_ventricular && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_7'
                                        title={strings.funcaoVentricular}
                                        content={detalhesTfd.funcao_ventricular}
                                    />
                                )}
                                {detalhesTfd.padrao_respiratorio && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_8'
                                        title={strings.padraoRespiratorio}
                                        content={detalhesTfd.padrao_respiratorio}
                                    />
                                )}
                                {detalhesTfd.funcao_renal_hepatica && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_9'
                                        title={strings.funcaoRenalHepatica}
                                        content={detalhesTfd.funcao_renal_hepatica}
                                    />
                                )}
                                {detalhesTfd.estado_neurologico_sumario && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_10'
                                        title={strings.estadoNeurologicoSumario}
                                        content={detalhesTfd.estado_neurologico_sumario}
                                    />
                                )}
                                {detalhesTfd.medicamento_dose && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_11'
                                        title={strings.medicamentoUsoDose}
                                        content={detalhesTfd.medicamento_dose}
                                    />
                                )}
                                {detalhesTfd.caracterizacao_urgencia && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_12'
                                        title={strings.caracterizacaoEventualUrgencia}
                                        content={detalhesTfd.caracterizacao_urgencia}
                                    />
                                )}
                                {detalhesTfd.historia_pregressa_atual && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_13'
                                        title={strings.historiaPregressa}
                                        content={detalhesTfd.historia_pregressa_atual}
                                    />
                                )}
                                {detalhesTfd.justificativa_nao_tratamento_local && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_14'
                                        title={strings.justificarRazoesLocalidade}
                                        content={detalhesTfd.justificativa_nao_tratamento_local}
                                    />
                                )}
                                {detalhesTfd.justificativa_acompanhante && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_15'
                                        title={strings.justificarNecessidadeAcompanhante}
                                        content={detalhesTfd.justificativa_acompanhante}
                                    />
                                )}
                                {detalhesTfd.transporte_recomendado && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_16'
                                        title={strings.transporteRecomendado}
                                        content={detalhesTfd.transporte_recomendado}
                                    />
                                )}
                                {detalhesTfd.transporte_recomendavel_justificativa && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_17'
                                        title={strings.justificativaTransporteRecomendado}
                                        content={detalhesTfd.transporte_recomendavel_justificativa}
                                    />
                                )}
                                {detalhesTfd.numero_acompanhantes != null && (
                                    <SttTextItem
                                        classContent={classes.textItem}
                                        key='solicitacao_18'
                                        title={strings.numeroAcompanhantes}
                                        content={detalhesTfd.numero_acompanhantes}
                                    />
                                )}
                            </div>
                        }
                    />

                    {detalhesTfd.id_funcionario_requisitante && (
                        <SttExpansionPanel
                            title={strings.dadosRequisitante}
                            children={
                                <div>
                                    {detalhesTfd.funcionario_requisitante && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='requisitante_1'
                                            title={strings.nome}
                                            content={detalhesTfd.funcionario_requisitante}
                                        />
                                    )}
                                    {detalhesTfd.numero_conselho_funcionario_requisitante && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='requisitante_2'
                                            title={strings.crmRms}
                                            content={
                                                detalhesTfd.numero_conselho_funcionario_requisitante
                                            }
                                        />
                                    )}
                                    {detalhesTfd.profissao_requisitante && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='requisitante_3'
                                            title={strings.profissao}
                                            content={detalhesTfd.profissao_requisitante}
                                        />
                                    )}
                                    {detalhesTfd.cbo_ocupacao_funcionario_requisitante && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='requisitante_4'
                                            title={strings.cnes}
                                            content={
                                                detalhesTfd.cbo_ocupacao_funcionario_requisitante
                                            }
                                        />
                                    )}
                                    {detalhesTfd.equipe_saude_familia_funcionario_requisitante && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='requisitante_5'
                                            title={strings.equipeSaudeFamilia}
                                            content={
                                                detalhesTfd.equipe_saude_familia_funcionario_requisitante
                                            }
                                        />
                                    )}
                                    {detalhesTfd.local_preenchimento_laudo_medico && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='requisitante_6'
                                            title={strings.localPreenchimentoLaudoMedico}
                                            content={detalhesTfd.local_preenchimento_laudo_medico}
                                        />
                                    )}
                                    {detalhesTfd.data_preenchimento_laudo_medico_formatada && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='requisitante_7'
                                            title={strings.dataPreenchimentoLaudoMedico}
                                            content={
                                                detalhesTfd.data_preenchimento_laudo_medico_formatada
                                            }
                                        />
                                    )}
                                </div>
                            }
                        />
                    )}

                    {detalhesTfd.movimentacoes &&
                        detalhesTfd.movimentacoes.length > 0 && (
                            <SttExpansionPanel
                                title={strings.movimentacoes}
                                children={detalhesTfd.movimentacoes.map(
                                    (movimentacao, index) => (
                                        <div key={index} className={classes.divWrapperMovimentacao}>
                                            {movimentacao.data_hora_formatada && (
                                                <SttTextItem
                                                    classContent={classes.textItem}
                                                    key={`movimentacoes_1_${index}`}
                                                    title={strings.data}
                                                    content={movimentacao.data_hora_formatada}
                                                />
                                            )}
                                            {movimentacao.nome_funcionario && (
                                                <SttTextItem
                                                    classContent={classes.textItem}
                                                    key={`movimentacoes_2_${index}`}
                                                    title={strings.responsavel}
                                                    content={movimentacao.nome_funcionario}
                                                />
                                            )}
                                            {movimentacao.conteudo && (
                                                <SttTextItem
                                                    classContent={classes.textoQuebraLinha}
                                                    key={`movimentacoes_3_${index}`}
                                                    title={strings.movimentacao}
                                                    content={movimentacao.conteudo.replace(/<br>/g, '\n')}
                                                />
                                            )}
                                            {index + 1 != detalhesTfd.movimentacoes.length && (
                                                <SttDivider />
                                            )}
                                        </div>
                                    )
                                )}
                            />
                        )}

                    {detalhesTfd.id_instituicao_executante && (
                        <SttExpansionPanel
                            title={strings.dadosAgendamento}
                            children={
                                <div>
                                    {detalhesTfd.instituicao_executante && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='agendamento_1'
                                            title={strings.instituicaoExecutante}
                                            content={detalhesTfd.instituicao_executante}
                                        />
                                    )}
                                    {detalhesTfd.cidade_uf_instituicao_executante && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='agendamento_2'
                                            title={strings.cidade}
                                            content={detalhesTfd.cidade_uf_instituicao_executante}
                                        />
                                    )}
                                    {detalhesTfd.funcionario_executante && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='agendamento_3'
                                            title={strings.funcionarioExecutante}
                                            content={detalhesTfd.funcionario_executante}
                                        />
                                    )}
                                    {detalhesTfd.data_hora_atendimento_formatada && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='agendamento_4'
                                            title={strings.dataAtendimento}
                                            content={detalhesTfd.data_hora_atendimento_formatada}
                                        />
                                    )}
                                    {detalhesTfd.periodo_atendimento_formatado && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='agendamento_6'
                                            title={strings.periodoAtendimento}
                                            content={detalhesTfd.periodo_atendimento_formatado}
                                        />
                                    )}
                                    {detalhesTfd.preparo_procedimento && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key='agendamento_5'
                                            title={strings.preparoProcedimento}
                                            content={detalhesTfd.preparo_procedimento}
                                        />
                                    )}
                                </div>
                            }
                        />
                    )}

                    {detalhesTfd.anexos?.length > 0 && (
                        <SttExpansionPanel
                            title={strings.anexos}
                            children={detalhesTfd.anexos.map((anexo, index) => (
                                <div key={index}>
                                    <SttHeading
                                        variant='h4'
                                        color='primary'
                                        className={classesHeading.root}
                                    >{`Anexo ${index + 1}`}</SttHeading>
                                    {anexo.nome && (
                                        <SttTextItem
                                            key={`anexos_1_${index}`}
                                            title={strings.arquivo}
                                            content={
                                                <SttLink
                                                    onClick={(e) => {
                                                        baixarArquivoTfd(
                                                            `${TFD_API_BASE_URL}/arquivo/${anexo.idCodificado
                                                            }/${encodeURIComponent(anexo.nome)}`
                                                        );
                                                        e.preventDefault();
                                                    }}
                                                    target='_blank'
                                                >
                                                    {anexo.nome}
                                                </SttLink>
                                            }
                                        />
                                    )}
                                    {anexo.tipo_anexo && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key={`anexos_2_${index}`}
                                            title={strings.tipoAnexo}
                                            content={anexo.tipo_anexo}
                                        />
                                    )}
                                    {anexo.observacao && (
                                        <SttTextItem
                                            classContent={classes.textItem}
                                            key={`anexos_3_${index}`}
                                            title={strings.observacoes}
                                            content={anexo.observacao}
                                        />
                                    )}
                                </div>
                            ))}
                        />
                    )}
                    {detalhesTfd.comprovantesComparecimento &&
                        detalhesTfd.comprovantesComparecimento.length > 0 && (
                            <SttExpansionPanel
                                title={strings.comprovantesComparecimento}
                                children={detalhesTfd.comprovantesComparecimento.map(
                                    (comprovante, index) => (
                                        <div key={index} style={{ display: 'flex' }}>
                                            <div style={{ width: '90%' }}>
                                                <SttHeading
                                                    variant='h4'
                                                    color='primary'
                                                    className={classesHeading.root}
                                                >{`Comprovante ${index + 1}`}</SttHeading>
                                                {comprovante.data_formatada && (
                                                    <SttTextItem
                                                        classContent={classes.textItem}
                                                        key={`comparecimento_1_${index}`}
                                                        title={strings.data}
                                                        content={comprovante.data_formatada}
                                                    />
                                                )}
                                                {comprovante.funcionario_responsavel && (
                                                    <SttTextItem
                                                        classContent={classes.textItem}
                                                        key={`comparecimento_2_${index}`}
                                                        title={strings.responsavel}
                                                        content={comprovante.funcionario_responsavel}
                                                    />
                                                )}
                                                {comprovante.numero_pedido && (
                                                    <SttTextItem
                                                        classContent={classes.textItem}
                                                        key={`comparecimento_3_${index}`}
                                                        title={strings.numeroPedidoProcessoTfd}
                                                        content={comprovante.numero_pedido}
                                                    />
                                                )}
                                                {comprovante.requisicao_tfd_solicitacao_passagem && (
                                                    <SttTextItem
                                                        classContent={classes.textItem}
                                                        key={`comparecimento_4_${index}`}
                                                        title={strings.requisicaoSolicitacaoPassagem}
                                                        content={
                                                            comprovante.requisicao_tfd_solicitacao_passagem
                                                        }
                                                    />
                                                )}
                                                {comprovante.justificativa_vinculo_tfd && (
                                                    <SttTextItem
                                                        classContent={classes.textItem}
                                                        key={`comparecimento_5_${index}`}
                                                        title={strings.justificativaVinculoTfd}
                                                        content={comprovante.justificativa_vinculo_tfd}
                                                    />
                                                )}
                                                {comprovante.numero_pernoites.toString() && (
                                                    <SttTextItem
                                                        classContent={classes.textItem}
                                                        key={`comparecimento_6_${index}`}
                                                        title={strings.ajudaCustoComPernoite}
                                                        content={comprovante.numero_pernoites}
                                                    />
                                                )}
                                                {comprovante.numero_diarias.toString() && (
                                                    <SttTextItem
                                                        classContent={classes.textItem}
                                                        key={`comparecimento_7_${index}`}
                                                        title={strings.ajudaCustoSemPernoite}
                                                        content={comprovante.numero_diarias}
                                                    />
                                                )}
                                                {comprovante.numero_acompanhantes.toString() && (
                                                    <SttTextItem
                                                        classContent={classes.textItem}
                                                        key={`comparecimento_8_${index}`}
                                                        title={strings.numeroAcompanhantes}
                                                        content={comprovante.numero_acompanhantes}
                                                    />
                                                )}
                                                {comprovante.observacao && (
                                                    <SttTextItem
                                                        classContent={classes.textItem}
                                                        key={`comparecimento_9_${index}`}
                                                        title={strings.observacao}
                                                        content={comprovante.observacao}
                                                    />
                                                )}

                                                {comprovante.anexos?.length &&
                                                    comprovante.anexos.map((anexo, indiceComprovante) => (
                                                        <div key={indiceComprovante}>
                                                            {anexo.nome && (
                                                                <SttTextItem
                                                                    key={`comparecimento_anexo_${indiceComprovante}`}
                                                                    title={`Anexo ${indiceComprovante + 1}`}
                                                                    content={
                                                                        <SttLink
                                                                            onClick={(e) => {
                                                                                baixarArquivoTfd(
                                                                                    `${TFD_API_BASE_URL}/arquivo-comprovante-comparecimento/${anexo.idCodificado
                                                                                    }/${encodeURIComponent(anexo.nome)}`
                                                                                );
                                                                                e.preventDefault();
                                                                            }}
                                                                            target='_blank'
                                                                        >
                                                                            {anexo.nome}
                                                                        </SttLink>
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                            </div>
                                            {comprovante.id_funcionario == user.idFuncionario &&
                                                detalhesTfd.situacao !== situacao.ARQUIVADO && (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <IconButton
                                                            style={{ marginLeft: 'auto', marginRight: '0' }}
                                                            onClick={() => {
                                                                setIdComprovanteComparecimento(comprovante.id);
                                                                abrirOperacao(
                                                                    TIPO_OPERACAO.INATIVAR_COMPROVANTE_COMPARECIMENTO
                                                                );
                                                            }}
                                                        >
                                                            <DeleteIcon color='primary' />
                                                        </IconButton>
                                                    </div>
                                                )}
                                        </div>
                                    )
                                )}
                            />
                        )}
                    {detalhesTfd.carimboTempo && detalhesTfd.carimboTempo.length > 0 && (
                        <SttExpansionPanel
                            title={strings.carimboTempo}
                            children={
                                <SttCarimboTempo
                                    text={detalhesTfd.carimboTempo[0].hash_carimbo}
                                    date={detalhesTfd.carimboTempo[0].data_hora_formatada}
                                    url={`${TFD_API_BASE_URL}/carimbo-tempo/${detalhesTfd.carimboTempo[0].id_carimbo_tempo}/${id}`}
                                    headers={getHeaders()}
                                />
                            }
                        />
                    )}
                    <SttDivider />
                    <div className={classes.buttonWrapper}>
                        {
                            <SttButton
                                type='button'
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                onClick={imprimirTfd}
                            >
                                {strings.imprimir}
                            </SttButton>
                        }
                        {detalhesTfd.acoes.podeDevolverTfd && (
                            <SttButton
                                type='button'
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                onClick={() => {
                                    abrirOperacao(TIPO_OPERACAO.DEVOLUCAO);
                                }}
                            >
                                {strings.devolver}
                            </SttButton>
                        )}
                        {detalhesTfd.acoes.podeCancelarTfd && (
                            <SttButton
                                type='button'
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                onClick={() => {
                                    abrirOperacao(TIPO_OPERACAO.CANCELAMENTO);
                                }}
                            >
                                {strings.cancelar}
                            </SttButton>
                        )}
                        {detalhesTfd.acoes.podeNegarTfd && (
                            <SttButton
                                type='button'
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                onClick={() => {
                                    abrirOperacao(TIPO_OPERACAO.NEGACAO);
                                }}
                            >
                                {strings.negar}
                            </SttButton>
                        )}
                        {detalhesTfd.acoes.podeEncaminharTfd && (
                            <SttButton
                                type='button'
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                onClick={() => {
                                    abrirOperacao(TIPO_OPERACAO.ENCAMINHAMENTO);
                                }}
                            >
                                {strings.encaminhar}
                            </SttButton>
                        )}

                        {detalhesTfd.acoes.podeEncaminharTfdEstadual && (
                            <SttButton
                                type='button'
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                onClick={() => {
                                    abrirOperacao(TIPO_OPERACAO.ENCAMINHAMENTO_TFD_ESTADUAL);
                                }}
                            >
                                {strings.encaminhar}
                            </SttButton>
                        )}

                        {
                            detalhesTfd.acoes.podeAguardarAgendamento &&
                            (
                                <SttButton
                                    type='button'
                                    variant='contained'
                                    color='primary'
                                    className={classes.button}
                                    onClick={() => {
                                        abrirAguardandoAgendamento();
                                    }}
                                >
                                    {strings.aguardarAgendamento}
                                </SttButton>
                            )
                        }

                        {detalhesTfd.acoes.podeAguardarAgendamentoCNRAC && (
                            <SttButton
                                type='button'
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                onClick={() => {
                                    abrirConfirmacaoAgendamentoCNRAC();
                                }}
                            >
                                {strings.aguardarAgendamentoCnrac}
                            </SttButton>
                        )}

                        {detalhesTfd.acoes.podeInformarNumeroCNRAC && (
                            <SttButton
                                type='button'
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                onClick={() => {
                                    abrirOperacao(TIPO_OPERACAO.INFORMAR_NUMERO_CNRAC);
                                }}
                            >
                                {strings.informarNumeroCnrac}
                            </SttButton>
                        )}

                        {detalhesTfd.acoes.podeConfirmarAgendamento && (
                            <SttButton
                                type='button'
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                onClick={() => {
                                    abrirConfirmacaoAgendamento();
                                }}
                            >
                                {strings.confirmarAgendamento}
                            </SttButton>
                        )}

                        {detalhesTfd.acoes.podeRegular && (
                            <SttButton
                                type='button'
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                onClick={() => {
                                    abrirOperacao(TIPO_OPERACAO.REGULAR);
                                }}
                            >
                                {strings.regular}
                            </SttButton>
                        )}

                        {detalhesTfd.acoes.podeAnexarComprovanteComparecimento && (
                            <SttButton
                                type='button'
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                onClick={() => {
                                    abrirOperacao(
                                        TIPO_OPERACAO.ANEXAR_COMPROVANTE_COMPARECIMENTO
                                    );
                                }}
                            >
                                {strings.anexarComprovanteComparecimento}
                            </SttButton>
                        )}

                        {detalhesTfd.acoes.podeEditar && (
                            <SttButton
                                type='button'
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                onClick={() => {
                                    abrirFormularioEdicao();
                                }}
                            >
                                {strings.alterar}
                            </SttButton>
                        )}

                        {detalhesTfd.acoes.podeSolicitarTransporte && (
                            <SttButton
                                type='button'
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                disabled={pesquisandoTfdPassagens}
                                onClick={() => {
                                    pesquisarRascunhosPassagemPorIdTfd();
                                }}
                            >
                                {strings.solicitarTransporte}
                            </SttButton>
                        )}

                        {detalhesTfd.acoes.podeArquivarProcesso && (
                            <SttButton
                                type='button'
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                onClick={() => {
                                    abrirOperacao(TIPO_OPERACAO.ARQUIVAR_PROCESSO);
                                }}
                            >
                                {strings.arquivarProcesso}
                            </SttButton>
                        )}

                        {detalhesTfd.acoes.necessitaProcedimentoSolicitado && (
                            <SttButton
                                type='button'
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                onClick={() => {
                                    abrirOperacao(TIPO_OPERACAO.INFORMAR_PROCEDIMENTO_SOLICITADO);
                                }}
                            >
                                {strings.inserirProcedimentoSolicitado}
                            </SttButton>
                        )}
                    </div>

                    <Operacoes
                        tipoOperacao={tipoOperacao}
                        open={openModalOperacao}
                        tipoProcesso={detalhesTfd['tipo_processo']}
                        situacao={detalhesTfd['situacao']}
                        callbackDetalhes={callbackRetornoOperacao}
                        setOpen={setOpenModalOperacao}
                        id={id}
                        alerta={alerta}
                        idComprovanteComparecimento={idComprovanteComparecimento}
                        voltarPesquisa={voltar}
                    />

                    <SttAlerta
                        open={mostrarAlerta}
                        title={tituloAlerta}
                        message={mensagemAlerta}
                        type={tipoAlerta}
                        onClose={onCloseAlerta}
                        options={opcoesAlerta}
                    />

                    <SttLoading open={carregandoArquivo} text={strings.carregando} />
                </>
            )}
        </SttContainer>
    );
};

Detalhes.propTypes = {
    user: PropTypes.object.isRequired,
    setTfd: PropTypes.func.isRequired,
    setIdComprovanteComparecimento: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        idComprovanteComparecimento: state.index.idComprovanteComparecimento,
        tfd: state.index.tfd,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTfd: (tfd) => dispatch(setTfdAction(tfd)),
        setIdComprovanteComparecimento: (id) =>
            dispatch(setIdComprovanteComparecimentoAction(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalhes);
