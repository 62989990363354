import React, { useRef, useEffect, useState, lazy, Suspense, useContext } from "react";
import {
    SttButton,
    SttGrid,
    SttInput,
    SttRadioButton,
    SttRadioGroup,
    SttFormControlLabel,
    SttFormHelperText,
    SttFormControl,
    SttHeading,
    SttCircularProgress,
    SttDatePicker,
    SttTranslateHook
} from '@stt-componentes/core';
import { getHeaders } from '../../../request';
import {
    Formik,
    FastField,
    Field
} from 'formik';
import axios from 'axios';
import * as yup from 'yup';

import {
    TIPO_ENCAMINHAMENTO,
    PROCEDIMENTO,
    MOVIMENTACAO,
    INSTITUICAO,
    PERIODO_ATENDIMENTO,
    DATA_HORA
} from "./fieldNames";
import {
    TIPO_AGENDAMENTO_CONSTANTES,
    PERIODO_ATENDIMENTO_CONSTANTES,
    TIPO_PROCESSO as TIPO_PROCESSO_CONSTANTES
} from "../../../common/Constants";
import { makeStyles } from "@material-ui/core/styles";
const SttInstituicao = lazy(() => import('@stt-componentes/instituicao'));

const validationSchema = (strings, dados) => {
    return yup.object().shape({
        [TIPO_ENCAMINHAMENTO]: yup
            .string()
            .nullable()
            .required(strings.campoObrigatorio),
        [DATA_HORA]: yup.date().when(TIPO_ENCAMINHAMENTO, {
            is: (val) => val === TIPO_AGENDAMENTO_CONSTANTES.COM_AGENDAMENTO,
            then: yup.date().typeError('Data inválida').required('Campo obrigatório')
        }).nullable(),
        [INSTITUICAO]: yup.object().when(TIPO_ENCAMINHAMENTO, {
            is: (val) => {
                return val && val === TIPO_AGENDAMENTO_CONSTANTES.COM_AGENDAMENTO;
            },
            then: yup.object().shape({
                id: yup.number()
            }).required(strings.campoObrigatorio)
        }).nullable()
    });
}

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }
}));

const EncaminhamentoSolicitacao = ({ callback, callbackFecharModal, id, tipoProcesso }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const schema = validationSchema(strings, { tipoProcesso: tipoProcesso });
    const formRef = useRef();
    const TFD_API_BASE_URL = global.gConfig.url_base_tfd;
    const [initialValues, setInitialValues] = useState({
        tipoEncaminhamento: TIPO_AGENDAMENTO_CONSTANTES.SEM_AGENDAMENTO,
        instituicao: null,
        movimentacao: '',
        data_hora: null,
        periodoAtendimento: null
    });
    const [carregado, setCarregado] = useState(false);

    useEffect(() => {
        if (id) {
            axios
                .get(`${TFD_API_BASE_URL}/agendamento/${id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        const agendamento = itens[0];

                        if (agendamento.id_instituicao_executante) {
                            initialValues.instituicao = {
                                id: agendamento.id_instituicao_executante,
                                nome: agendamento.nome_instituicao_executante
                            };
                        }

                        if (agendamento.preparo_procedimento) {
                            initialValues.procedimento = agendamento.preparo_procedimento;
                        }

                        if (agendamento.data_hora_atendimento) {
                            initialValues.data_hora = new Date(agendamento.data_hora_atendimento);
                        }

                        if (agendamento.periodo_atendimento) {
                            initialValues.periodoAtendimento = agendamento.periodo_atendimento;
                        }

                        if (agendamento.parecer_inconclusivo_regulador) {
                            initialValues.parecer_inconclusivo_regulador = agendamento.parecer_inconclusivo_regulador;
                        }

                        if (tipoProcesso === TIPO_PROCESSO_CONSTANTES.JUDICIAL) {
                            initialValues.tipoEncaminhamento = TIPO_AGENDAMENTO_CONSTANTES.COM_AGENDAMENTO;
                        }

                        setInitialValues(initialValues);
                        setCarregado(true);
                    }
                })
                .catch(err => console.log(err));
        }
    }, [id]);

    const estruturarFiltros = (data) => {
        let filtros = {
            ...data
        };

        if (data.instituicao) {
            filtros.instituicao = data.instituicao.id;
        }

        if (data.avaliador) {
            filtros.avaliador = data.avaliador.id;
        }

        if (data.procedimento) {
            data.procedimento = data.procedimento.trim().toUpperCase();
        }

        if (data.movimentacao) {
            data.movimentacao = data.movimentacao.trim().toUpperCase();
        }

        if (data.data_hora) {
            let dataFormatada = new Date(data.data_hora.valueOf() - data.data_hora.getTimezoneOffset() * 60000);
            filtros.data_hora = dataFormatada.toISOString();
        }

        return filtros;
    };

    return (
        <>
            {
                carregado ?
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(data, { setSubmitting, resetForm }) => {
                            callback(estruturarFiltros(data), setSubmitting);
                        }}
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit,
                                resetForm,
                                values,
                                ...formikEvents
                            }) => {
                                return (
                                    <form onSubmit={handleSubmit} noValidate>
                                        <>
                                            <SttGrid container spacing={3}>
                                                <SttGrid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                    <Field name={TIPO_ENCAMINHAMENTO}>
                                                        {({
                                                            field,
                                                            meta,
                                                        }) => (
                                                            <div>
                                                                <SttHeading required variant="h4" color="primary">{strings.tipoEncaminhamento}</SttHeading>
                                                                <br />
                                                                <SttFormControl
                                                                    required
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    variant="outlined"
                                                                    disabled={tipoProcesso === TIPO_PROCESSO_CONSTANTES.JUDICIAL}
                                                                    onChange={(e) => {
                                                                        resetForm({
                                                                            values: {
                                                                                ...initialValues,
                                                                                tipoEncaminhamento: e.target.value
                                                                            }
                                                                        });
                                                                    }}>
                                                                    <SttRadioGroup row>

                                                                        {
                                                                            <SttFormControlLabel
                                                                                {...field}
                                                                                control={
                                                                                    <SttRadioButton
                                                                                        type="radio"
                                                                                        value={TIPO_AGENDAMENTO_CONSTANTES.COM_AGENDAMENTO}
                                                                                        color="primary"
                                                                                        checked={field.value === TIPO_AGENDAMENTO_CONSTANTES.COM_AGENDAMENTO}
                                                                                    />
                                                                                }
                                                                                label={strings.comAgendamento}
                                                                            />
                                                                        }

                                                                        <SttFormControlLabel
                                                                            {...field}
                                                                            control={
                                                                                <SttRadioButton
                                                                                    type="radio"
                                                                                    value={TIPO_AGENDAMENTO_CONSTANTES.SEM_AGENDAMENTO}
                                                                                    color="primary"
                                                                                    checked={field.value === TIPO_AGENDAMENTO_CONSTANTES.SEM_AGENDAMENTO}
                                                                                />
                                                                            }
                                                                            label={strings.semAgendamento}
                                                                        />
                                                                    </SttRadioGroup>
                                                                    {
                                                                        meta.touched && meta.error &&
                                                                        <SttFormHelperText error>
                                                                            {meta.error}
                                                                        </SttFormHelperText>
                                                                    }
                                                                </SttFormControl>
                                                            </div>
                                                        )}
                                                    </Field>
                                                </SttGrid>

                                                {
                                                    (values.tipoEncaminhamento == TIPO_AGENDAMENTO_CONSTANTES.COM_AGENDAMENTO) &&
                                                    <SttGrid item xs={12} sm={6}>
                                                        <SttHeading required={true} variant="h4" color="primary">{strings.instituicaoExecutante}</SttHeading>
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <SttInstituicao
                                                                fieldName={'instituicao'}
                                                                headers={getHeaders()}
                                                                strings={strings}
                                                                obrigatorio={true}
                                                                formExterno={{
                                                                    instituicao: values.instituicao,
                                                                    ...formikEvents
                                                                }}
                                                            />
                                                        </Suspense>
                                                    </SttGrid>
                                                }
                                            </SttGrid>

                                            <SttGrid container spacing={3}>
                                                {
                                                    (values.tipoEncaminhamento == TIPO_AGENDAMENTO_CONSTANTES.COM_AGENDAMENTO) &&
                                                    <>
                                                        <div style={{ width: '100%' }}></div>
                                                        <SttGrid item xs={12} sm={6}>
                                                            <SttHeading required variant="h4" color="primary">{strings.dataAtendimento}</SttHeading>
                                                            <FastField name={'data_hora'}>
                                                                {({
                                                                    field: { name, value, },
                                                                    form: { setFieldValue, setFieldTouched },
                                                                    meta
                                                                }) => {
                                                                    return (
                                                                        <SttDatePicker
                                                                            label={strings.dataAtendimento}
                                                                            inputprops={{
                                                                                name: name
                                                                            }}
                                                                            required
                                                                            error={meta.touched && meta.error ? true : false}
                                                                            value={value}
                                                                            onBlur={() => {
                                                                                setFieldTouched('data_hora', true);
                                                                            }}
                                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                            onChange={date => setFieldValue('data_hora', date, true)}
                                                                            onClose={() => setFieldTouched('data_hora', true)}
                                                                        />
                                                                    );
                                                                }}
                                                            </FastField>
                                                        </SttGrid>
                                                        <SttGrid item xs={12} sm={6}>
                                                            <Field name={PERIODO_ATENDIMENTO}>
                                                                {({
                                                                    field,
                                                                    form,
                                                                    meta,
                                                                }) => (
                                                                    <div>
                                                                        <SttHeading variant="h4" color="primary">{strings.periodoAtendimento}</SttHeading>
                                                                        <br />
                                                                        <SttFormControl
                                                                            required
                                                                            error={meta.touched && meta.error ? true : false}
                                                                            variant="outlined"
                                                                        >
                                                                            <SttRadioGroup row>
                                                                                <SttFormControlLabel
                                                                                    {...field}
                                                                                    control={
                                                                                        <SttRadioButton
                                                                                            type="radio"
                                                                                            value={PERIODO_ATENDIMENTO_CONSTANTES.MATUTINO}
                                                                                            color="primary"
                                                                                            checked={field.value === PERIODO_ATENDIMENTO_CONSTANTES.MATUTINO}
                                                                                        />
                                                                                    }
                                                                                    label={strings.matutino}
                                                                                />
                                                                                <SttFormControlLabel
                                                                                    {...field}
                                                                                    control={
                                                                                        <SttRadioButton
                                                                                            type="radio"
                                                                                            value={PERIODO_ATENDIMENTO_CONSTANTES.VESPERTINO}
                                                                                            color="primary"
                                                                                            checked={field.value === PERIODO_ATENDIMENTO_CONSTANTES.VESPERTINO}
                                                                                        />
                                                                                    }
                                                                                    label={strings.vespertino}
                                                                                />
                                                                                <SttFormControlLabel
                                                                                    {...field}
                                                                                    control={
                                                                                        <SttRadioButton
                                                                                            type="radio"
                                                                                            value={PERIODO_ATENDIMENTO_CONSTANTES.INTERNACAO}
                                                                                            color="primary"
                                                                                            checked={field.value === PERIODO_ATENDIMENTO_CONSTANTES.INTERNACAO}
                                                                                        />
                                                                                    }
                                                                                    label={strings.internacao}
                                                                                />
                                                                            </SttRadioGroup>
                                                                            {
                                                                                meta.touched && meta.error &&
                                                                                <SttFormHelperText error>
                                                                                    {meta.error}
                                                                                </SttFormHelperText>
                                                                            }
                                                                        </SttFormControl>
                                                                    </div>
                                                                )}
                                                            </Field>
                                                        </SttGrid>

                                                        <SttGrid item xs={12}>
                                                            <FastField name={PROCEDIMENTO}>
                                                                {({
                                                                    field,
                                                                    meta
                                                                }) => (
                                                                    <SttInput
                                                                        multiline
                                                                        rows={5}
                                                                        {...field}
                                                                        label={strings.preparoProcedimento}
                                                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                                                        error={meta.touched && meta.error ? true : false}
                                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                    />
                                                                )}
                                                            </FastField>
                                                        </SttGrid>
                                                    </>
                                                }

                                                {
                                                    values.tipoEncaminhamento &&
                                                    <SttGrid item xs={12}>
                                                        <FastField name={MOVIMENTACAO}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttInput
                                                                    multiline
                                                                    rows={5}
                                                                    {...field}
                                                                    label={strings.movimentacao}
                                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>
                                                }
                                            </SttGrid>
                                            <SttGrid container spacing={3}>
                                                <SttGrid item xs={12}>
                                                    <SttButton
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        nomarginleft="true"
                                                        disabled={isSubmitting}
                                                    >
                                                        {strings.confirmar}
                                                    </SttButton>
                                                    <SttButton
                                                        type="button"
                                                        variant="outlined"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                        onClick={() => {
                                                            resetForm({ values: initialValues });
                                                        }}
                                                    >
                                                        {strings.limpar}
                                                    </SttButton>
                                                    <SttButton
                                                        type="button"
                                                        variant="outlined"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                        onClick={() => {
                                                            resetForm({ values: initialValues });
                                                            callbackFecharModal();
                                                        }}
                                                    >
                                                        {strings.fechar}
                                                    </SttButton>
                                                </SttGrid>
                                            </SttGrid>
                                        </>
                                    </form>
                                )
                            }
                        }
                    </Formik> :
                    (
                        <div className={classes.carregando}>
                            <SttCircularProgress color="primary" />
                        </div>
                    )
            }
        </>
    );
}

export default EncaminhamentoSolicitacao;