import * as yup from 'yup';
import { DADOS_GERAIS, TIPO_PROCESSO } from '../../dadosGerais/form/fieldNames';
import {
    ANO_PROCESSO,
    JUDICIAL,
    NUMERO_AUTOS,
    NUMERO_PROCESSO,
    SIGLA,
} from './fieldNames';
import {
    TIPO_PROCESSO as TIPO_PROCESSO_CONSTANTES
} from '../../../../common/Constants';

export default (strings) => {
    return yup.object().shape({
        [JUDICIAL]: yup.object().when(`${DADOS_GERAIS}.${TIPO_PROCESSO}`, {
            is: (val) => val === TIPO_PROCESSO_CONSTANTES.JUDICIAL,
            then: yup.object().shape({
                [SIGLA]: yup
                    .string()
                    .nullable()
                    .required(strings.siglaObrigatoria),
                [NUMERO_PROCESSO]: yup
                    .string()
                    .nullable()
                    .required(strings.numeroSgpeObrigatorio),
                [ANO_PROCESSO]: yup
                    .number()
                    .nullable()
                    .test('tamanho', strings.anoConterQuatroCaracteres, val => {
                        if (val && val.toString().length != 4) {
                            return false;
                        }

                        return true;
                    })
                    .required(strings.anoProcessoSgpeObrigatorio),
                [NUMERO_AUTOS]: yup
                    .string()
                    .nullable()
                    .test("tamanho", strings.numeroAutosVinteCaracteres, (val) => {
                        if (!val) {
                            return true;
                        }
                        val = val.replace(/[\. ,:-]+/g, "");
                        return val && val.length === 20;
                    })
                    .required(strings.numeroAutosObrigatorio),
            }).required()
        })
    });
}
