import React, { useRef, useState, useEffect, Suspense, lazy, useContext } from "react";
import {
    SttButton,
    SttLoading,
    SttNumberInput,
    SttGrid,
    SttInput,
    SttRadioButton,
    SttRadioGroup,
    SttFormControlLabel,
    SttFormHelperText,
    SttFormControl,
    SttHeading,
    SttCircularProgress,
    SttDatePicker,
    SttTranslateHook
} from '@stt-componentes/core';
import {
    Formik,
    Field,
    FastField
} from 'formik';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { makeStyles } from "@material-ui/core/styles";
import {
    TIPO_ENCAMINHAMENTO,
    REGISTRO_SISREG,
    TIPO_AGENDAMENTO,
    PROCEDIMENTO,
    MOVIMENTACAO,
    INSTITUICAO,
    PERIODO_ATENDIMENTO,
    DATA_HORA
} from "./fieldNames";
import { TIPO_PROCESSO, TIPO_ENCAMINHAMENTO_CONSTANTES, TIPO_AGENDAMENTO_CONSTANTES, PERIODO_ATENDIMENTO_CONSTANTES } from "../../../common/Constants";
const SttInstituicao = lazy(() => import('@stt-componentes/instituicao'));

const validationSchema = (strings) => {
    return yup.object().shape({
        [TIPO_ENCAMINHAMENTO]: yup
            .string()
            .required(strings.campoObrigatorio),
        [REGISTRO_SISREG]: yup.string().when(TIPO_ENCAMINHAMENTO, {
            is: (val) => val === TIPO_ENCAMINHAMENTO_CONSTANTES.ENCAMINHAR_SISREG,
            then: yup.string().required(strings.campoObrigatorio)
        }),
        [TIPO_AGENDAMENTO]: yup.string().when(TIPO_ENCAMINHAMENTO, {
            is: (val) => val === TIPO_ENCAMINHAMENTO_CONSTANTES.ENCAMINHAR_TFD_ESTADUAL,
            then: yup.string().required(strings.campoObrigatorio)
        }),
        [DATA_HORA]: yup.date()
            .when(TIPO_AGENDAMENTO, {
                is: (val) => val === TIPO_AGENDAMENTO_CONSTANTES.COM_AGENDAMENTO,
                then: yup.date()
                    .typeError('Data inválida')
                    .nullable()
                    .required('Campo obrigatório'),
                otherwise: yup.date().when(TIPO_ENCAMINHAMENTO, {
                    is: (val) => val === TIPO_ENCAMINHAMENTO_CONSTANTES.AGENDAMENTO_LOCAL,
                    then: yup.date()
                        .typeError('Data inválida')
                        .nullable()
                        .required('Campo obrigatório')
                })
            }).nullable()
        ,
        [INSTITUICAO]: yup.object().when(TIPO_AGENDAMENTO, {
            is: (val) => val === TIPO_AGENDAMENTO_CONSTANTES.COM_AGENDAMENTO,
            then: yup.object().shape({
                id: yup.number()
            })
                .nullable()
                .required(strings.campoObrigatorio),
            otherwise: yup.object().when(TIPO_ENCAMINHAMENTO, {
                is: (val) => val === TIPO_ENCAMINHAMENTO_CONSTANTES.AGENDAMENTO_LOCAL,
                then: yup.object().shape({
                    id: yup.number()
                })
                    .nullable()
                    .required(strings.campoObrigatorio)
            })
        }).nullable()
    });
}

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }
}));

const OperacaoEncaminhamento = ({ callback, callbackSucesso, id, tipoProcesso, alerta,...props }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const schema = validationSchema(strings);
    const formRef = useRef();
    const TFD_API_BASE_URL = global.gConfig.url_base_tfd;
    const [initialValues, setInitialValues] = useState(
        {
            tipoEncaminhamento: '',
            tipoAgendamento: '',
            registroSISREG: '',
            instituicao: null,
            movimentacao: '',
            data_hora: null,
            periodoAtendimento: null
        }
    );

    const [carregado, setCarregado] = useState(false);

    const {
        setMostrarAlerta,
        setTituloAlerta,
        setMensagemAlerta,
        setTipoAlerta,
        setOpcoesAlerta,
        setOnCloseAlerta
    } = alerta;

    const estruturarFiltros = (data) => {
        let filtros = {
            ...data
        };

        if (data.instituicao) {
            filtros.instituicao = data.instituicao.id;
        }

        if (data.movimentacao) {
            data.movimentacao = data.movimentacao.trim().toUpperCase();
        }

        if (data.procedimento) {
            data.procedimento = data.procedimento.trim().toUpperCase();
        }

        if (data.movimentacao) {
            data.movimentacao = data.movimentacao.trim().toUpperCase();
        }

        if (data.data_hora) {
            let dataFormatada = new Date(data.data_hora.valueOf() - data.data_hora.getTimezoneOffset() * 60000);
            filtros.data_hora = dataFormatada.toISOString();
        }

        return filtros;
    };

    useEffect(() => {
        if (id) {
            axios
                .get(`${TFD_API_BASE_URL}/agendamento/${id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        const agendamento = itens[0];

                        if (agendamento.id_instituicao_executante) {
                            initialValues.instituicao = {
                                id: agendamento.id_instituicao_executante,
                                nome: agendamento.nome_instituicao_executante
                            };
                        }

                        if (agendamento.preparo_procedimento) {
                            initialValues.procedimento = agendamento.preparo_procedimento;
                        }

                        if (agendamento.data_hora_atendimento) {
                            initialValues.data_hora = new Date(agendamento.data_hora_atendimento);
                        }

                        if (agendamento.periodo_atendimento) {
                            initialValues.periodoAtendimento = agendamento.periodo_atendimento;
                        }

                        if (agendamento.parecer_inconclusivo_regulador) {
                            initialValues.parecer_inconclusivo_regulador = agendamento.parecer_inconclusivo_regulador;
                        }

                        setInitialValues(initialValues);
                        setCarregado(true);
                    }
                })
                .catch(err => {
                    setTituloAlerta(strings.erro);
                    setMensagemAlerta(strings.erroRecuperacaoAgendamento);
                    setTipoAlerta('error');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setMostrarAlerta(false);
                            }
                        }
                    ]);
                    setOnCloseAlerta(() => () => {
                        setMostrarAlerta(false);
                    });
                    setMostrarAlerta(true);
                });
        }
    }, [id]);

    const closeAlerta = (resetForm) => {
        setMostrarAlerta(false);
        resetForm({ values: initialValues });
        callbackSucesso();
    }

    return (
        carregado ?
            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(dados, { setSubmitting, resetForm }) => {
                    setSubmitting(true);

                    dados.movimentacao = dados.movimentacao.trim();
                    dados.id = id;

                    axios.post(`${TFD_API_BASE_URL}/encaminhar-processo`, estruturarFiltros(dados), { headers: getHeaders() })
                        .then((response) => {
                            const { data } = response;
                            if (response.status === HttpStatus.OK) {
                                setTituloAlerta(strings.sucesso);
                                setMensagemAlerta(data.message ? data.message : strings.sucessoOperacao);
                                setTipoAlerta('success');
                                setOpcoesAlerta([
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            closeAlerta(resetForm);
                                        }
                                    }
                                ]);
                                setOnCloseAlerta(() => () => {
                                    closeAlerta(resetForm);
                                });
                                setMostrarAlerta(true);
                            }
                        })
                        .catch(err => {
                            const { response } = err;
                            let mensagem = strings.mensagemErroGeral;

                            if (response) {
                                if (response.status === HttpStatus.BAD_REQUEST) {
                                    const { data } = response;
                                    let arrMensagem = [];
                                    data.errors.forEach(error => {
                                        arrMensagem.push(`- ${error.message}`);
                                    });
                                    if (arrMensagem) {
                                        mensagem = arrMensagem.join('\n');
                                    }
                                }
                            }

                            setTituloAlerta(strings.erro);
                            setMensagemAlerta(mensagem);
                            setTipoAlerta('error');
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        setMostrarAlerta(false);
                                    }
                                }
                            ]);
                            setOnCloseAlerta(() => () => {
                                setMostrarAlerta(false);
                            });
                            setMostrarAlerta(true);
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {
                    ({
                        isSubmitting,
                        handleSubmit,
                        resetForm,
                        values,
                        ...formikEvents
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <SttGrid container spacing={3}>
                                    <SttGrid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Field name={TIPO_ENCAMINHAMENTO}>
                                            {({
                                                field,
                                                form,
                                                meta,
                                            }) => (
                                                <div>
                                                    <SttHeading required variant="h4" color="primary">{strings.tipoEncaminhamento}</SttHeading>
                                                    <br />
                                                    <SttFormControl
                                                        required
                                                        error={meta.touched && meta.error ? true : false}
                                                        variant="outlined"
                                                        onChange={(e, item) => {
                                                            resetForm({
                                                                values: {
                                                                    ...initialValues,
                                                                    tipoEncaminhamento: e.target.value
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <SttRadioGroup row>
                                                            {
                                                                tipoProcesso == TIPO_PROCESSO.INTRAESTADUAL &&
                                                                <SttFormControlLabel
                                                                    {...field}
                                                                    control={
                                                                        <SttRadioButton
                                                                            type="radio"
                                                                            value={TIPO_ENCAMINHAMENTO_CONSTANTES.AGENDAMENTO_LOCAL}
                                                                            color="primary"
                                                                            checked={field.value === TIPO_ENCAMINHAMENTO_CONSTANTES.AGENDAMENTO_LOCAL}
                                                                        />
                                                                    }
                                                                    label={strings.agendamentoLocal}
                                                                />
                                                            }

                                                            <SttFormControlLabel
                                                                {...field}
                                                                control={
                                                                    <SttRadioButton
                                                                        type="radio"
                                                                        value={TIPO_ENCAMINHAMENTO_CONSTANTES.ENCAMINHAR_TFD_ESTADUAL}
                                                                        color="primary"
                                                                        checked={field.value === TIPO_ENCAMINHAMENTO_CONSTANTES.ENCAMINHAR_TFD_ESTADUAL}
                                                                    />
                                                                }
                                                                label={strings.encaminharTfdEstadual}
                                                            />
                                                            <SttFormControlLabel
                                                                {...field}
                                                                control={
                                                                    <SttRadioButton
                                                                        type="radio"
                                                                        value={TIPO_ENCAMINHAMENTO_CONSTANTES.ENCAMINHAR_SISREG}
                                                                        color="primary"
                                                                        checked={field.value === TIPO_ENCAMINHAMENTO_CONSTANTES.ENCAMINHAR_SISREG}
                                                                    />
                                                                }
                                                                label={strings.encaminharSisreg}
                                                            />
                                                        </SttRadioGroup>
                                                        {
                                                            meta.touched && meta.error &&
                                                            <SttFormHelperText error>
                                                                {meta.error}
                                                            </SttFormHelperText>
                                                        }
                                                    </SttFormControl>
                                                </div>
                                            )}
                                        </Field>
                                    </SttGrid>

                                    {
                                        values.tipoEncaminhamento == TIPO_ENCAMINHAMENTO_CONSTANTES.ENCAMINHAR_SISREG &&
                                        <SttGrid item xs={12}>
                                            <Field name={REGISTRO_SISREG}>
                                                {({
                                                    field,
                                                    meta
                                                }) => (
                                                    <SttNumberInput
                                                        required
                                                        inputProps={{
                                                            maxLength: 18,
                                                            min: 0,
                                                            max: 999999999999999999
                                                        }}
                                                        label={strings.registroSisreg}
                                                        {...field}
                                                        error={meta.touched && meta.error ? true : false}
                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    />
                                                )}
                                            </Field>
                                        </SttGrid>
                                    }

                                    {
                                        values.tipoEncaminhamento == TIPO_ENCAMINHAMENTO_CONSTANTES.ENCAMINHAR_TFD_ESTADUAL &&
                                        <SttGrid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <Field name={TIPO_AGENDAMENTO}>
                                                {({
                                                    field,
                                                    form,
                                                    meta,
                                                }) => (
                                                    <div>
                                                        <SttHeading required variant="h4" color="primary">{strings.tipoAgendamento}</SttHeading>
                                                        <br />
                                                        <SttFormControl
                                                            required
                                                            error={meta.touched && meta.error ? true : false}
                                                            variant="outlined"
                                                        >
                                                            <SttRadioGroup row>
                                                                <SttFormControlLabel
                                                                    {...field}
                                                                    control={
                                                                        <SttRadioButton
                                                                            type="radio"
                                                                            value={TIPO_AGENDAMENTO_CONSTANTES.COM_AGENDAMENTO}
                                                                            color="primary"
                                                                            checked={field.value === TIPO_AGENDAMENTO_CONSTANTES.COM_AGENDAMENTO}
                                                                        />
                                                                    }
                                                                    label={strings.comAgendamento}
                                                                />
                                                                <SttFormControlLabel
                                                                    {...field}
                                                                    control={
                                                                        <SttRadioButton
                                                                            type="radio"
                                                                            value={TIPO_AGENDAMENTO_CONSTANTES.AGENDAMENTO_CNRAC}
                                                                            color="primary"
                                                                            checked={field.value === TIPO_AGENDAMENTO_CONSTANTES.AGENDAMENTO_CNRAC}
                                                                        />
                                                                    }
                                                                    label={strings.agendamentoCnrac}
                                                                />
                                                            </SttRadioGroup>
                                                            {
                                                                meta.touched && meta.error &&
                                                                <SttFormHelperText error>
                                                                    {meta.error}
                                                                </SttFormHelperText>
                                                            }
                                                        </SttFormControl>
                                                    </div>
                                                )}
                                            </Field>
                                        </SttGrid>
                                    }

                                    {
                                        (values.tipoAgendamento == TIPO_AGENDAMENTO_CONSTANTES.COM_AGENDAMENTO || values.tipoEncaminhamento == TIPO_ENCAMINHAMENTO_CONSTANTES.AGENDAMENTO_LOCAL) &&
                                        <>
                                            <SttGrid item xs={12} sm={6}>
                                                <SttHeading required variant="h4" color="primary">{strings.instituicaoExecutante}</SttHeading>
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <SttInstituicao
                                                        fieldName={'instituicao'}
                                                        headers={getHeaders()}
                                                        strings={strings}
                                                        obrigatorio={true}
                                                        formExterno={{
                                                            instituicao: values.instituicao,
                                                            ...formikEvents
                                                        }}
                                                    />
                                                </Suspense>
                                            </SttGrid>

                                            <SttGrid item xs={12} sm={6}>
                                                <SttHeading required variant="h4" color="primary">{strings.dataAtendimento}</SttHeading>
                                                <Field name={DATA_HORA}>
                                                    {({
                                                        field: { name, value, },
                                                        form: { setFieldValue, setFieldError, setFieldTouched },
                                                        meta
                                                    }) => {
                                                        return (
                                                            <SttDatePicker
                                                                label={strings.dataAtendimento}
                                                                inputprops={{
                                                                    name: name
                                                                }}
                                                                required
                                                                timeZoneOffsetInMinutes={90}
                                                                error={meta.touched && meta.error ? true : false}
                                                                value={value}
                                                                onBlur={() => {
                                                                    setFieldTouched('data_hora', true);
                                                                }}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                onChange={date => setFieldValue('data_hora', date, true)}
                                                                onClose={() => setFieldTouched('data_hora', true)}
                                                            />
                                                        );
                                                    }}
                                                </Field>
                                            </SttGrid>

                                            <SttGrid item xs={12} sm={6}>
                                                <Field name={PERIODO_ATENDIMENTO}>
                                                    {({
                                                        field,
                                                        form,
                                                        meta,
                                                    }) => (
                                                        <div>
                                                            <SttHeading variant="h4" color="primary">{strings.periodoAtendimento}</SttHeading>
                                                            <br />
                                                            <SttFormControl
                                                                required
                                                                error={meta.touched && meta.error ? true : false}
                                                                variant="outlined"
                                                            >
                                                                <SttRadioGroup row>
                                                                    <SttFormControlLabel
                                                                        {...field}
                                                                        control={
                                                                            <SttRadioButton
                                                                                type="radio"
                                                                                value={PERIODO_ATENDIMENTO_CONSTANTES.MATUTINO}
                                                                                color="primary"
                                                                                checked={field.value === PERIODO_ATENDIMENTO_CONSTANTES.MATUTINO}
                                                                            />
                                                                        }
                                                                        label={strings.matutino}
                                                                    />
                                                                    <SttFormControlLabel
                                                                        {...field}
                                                                        control={
                                                                            <SttRadioButton
                                                                                type="radio"
                                                                                value={PERIODO_ATENDIMENTO_CONSTANTES.VESPERTINO}
                                                                                color="primary"
                                                                                checked={field.value === PERIODO_ATENDIMENTO_CONSTANTES.VESPERTINO}
                                                                            />
                                                                        }
                                                                        label={strings.vespertino}
                                                                    />
                                                                    <SttFormControlLabel
                                                                        {...field}
                                                                        control={
                                                                            <SttRadioButton
                                                                                type="radio"
                                                                                value={PERIODO_ATENDIMENTO_CONSTANTES.INTERNACAO}
                                                                                color="primary"
                                                                                checked={field.value === PERIODO_ATENDIMENTO_CONSTANTES.INTERNACAO}
                                                                            />
                                                                        }
                                                                        label={strings.internacao}
                                                                    />
                                                                </SttRadioGroup>
                                                                {
                                                                    meta.touched && meta.error &&
                                                                    <SttFormHelperText error>
                                                                        {meta.error}
                                                                    </SttFormHelperText>
                                                                }
                                                            </SttFormControl>
                                                        </div>
                                                    )}
                                                </Field>
                                            </SttGrid>

                                            <SttGrid item xs={12}>
                                                <FastField name={PROCEDIMENTO}>
                                                    {({
                                                        field,
                                                        meta
                                                    }) => (
                                                        <SttInput
                                                            multiline
                                                            rows={5}
                                                            {...field}
                                                            label={strings.preparoProcedimento}
                                                            inputProps={{ style: { textTransform: "uppercase" } }}
                                                            error={meta.touched && meta.error ? true : false}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        />
                                                    )}
                                                </FastField>
                                            </SttGrid>
                                        </>
                                    }

                                    {
                                        values.tipoEncaminhamento &&
                                        <SttGrid item xs={12}>
                                            <FastField name={MOVIMENTACAO}>
                                                {({
                                                    field,
                                                    meta
                                                }) => (
                                                    <SttInput
                                                        multiline
                                                        rows={5}
                                                        {...field}
                                                        label={strings.movimentacao}
                                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                                        error={meta.touched && meta.error ? true : false}
                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    />
                                                )}
                                            </FastField>
                                        </SttGrid>
                                    }
                                    <SttLoading
                                        open={isSubmitting}
                                        text={strings.salvandoMensagemEspera}
                                    />
                                </SttGrid>
                                <SttGrid container spacing={3}>
                                    <SttGrid item xs={12}>
                                        <SttButton
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            nomarginleft="true"
                                            disabled={isSubmitting}
                                        >
                                            {strings.confirmar}
                                        </SttButton>
                                        <SttButton
                                            type="button"
                                            variant="outlined"
                                            color="primary"
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                resetForm({ values: initialValues });
                                            }}
                                        >
                                            {strings.limpar}
                                        </SttButton>
                                        <SttButton
                                            type="button"
                                            variant="outlined"
                                            color="primary"
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                resetForm({ values: initialValues });
                                                callback();
                                            }}
                                        >
                                            {strings.fechar}
                                        </SttButton>
                                    </SttGrid>
                                </SttGrid>
                            </form>
                        )
                    }
                }
            </Formik> :
            (
                <div className={classes.carregando}>
                    <SttCircularProgress color="primary" />
                </div>
            )
    );
}

export default OperacaoEncaminhamento;