export const NUMERO_PEDIDO = 'numero_pedido';
export const DATA_INICIAL = 'data_inicial';
export const DATA_FINAL = 'data_final';
export const UF_ORIGEM = 'uf_origem';
export const CIDADE_ORIGEM = 'cidade_origem';
export const UF_DESTINO = 'uf_destino';
export const CIDADE_DESTINO = 'cidade_destino';
export const NOME_PACIENTE = 'nome_paciente';
export const DATA_NASCIMENTO = 'data_nascimento';
export const CPF_PACIENTE = 'cpf_paciente';
export const SITUACAO = 'situacao';
export const ATRASADOS = 'atrasados';
export const REGULADOR = 'regulador';
export const AVALIADOR = 'avaliador';
export const REGIONAL = 'regional';