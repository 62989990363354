import * as yup from 'yup';
import { FIELDS } from './fieldNames';

const MAX_FILE_SIZE = 52428800; // 50MB
const ALLOWED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export default (strings) => {
  return yup.object().shape({
    [FIELDS.ANEXOS]: yup
      .array()
      .of(
        yup.object().shape({
          [FIELDS.ANEXO]: yup
            .mixed()
            .nullable()
            .test('formatoArquivo', strings.formatoArquivoInvalido, (value) => {
              if (!value) {
                return true;
              }

              // Na alteração, isso ocorre
              if (value && (!value.type || value.type === '')) {
                return true;
              }

              return value && ALLOWED_FILE_TYPES.includes(value.type);
            })
            .test(
              'tamanhoArquivo',
              strings.erroTamanhoArquivo50Mb,
              function (value) {
                if (!value || !(value instanceof File)) {
                  return true;
                }

                const { from } = this;

                if (!from) {
                  return value.size <= MAX_FILE_SIZE;
                }

                const anexos = from?.[1]?.value?.anexos;

                // Se houver apenas um arquivo, verifica-se o tamanho individual
                if (!anexos || !anexos.length || anexos.length === 1) {
                  return value.size <= MAX_FILE_SIZE;
                }

                // Quando houver mais de um arquivo, verifica-se o tamanho combinado
                const tamanhoCombinado = anexos.reduce((acc, anexo) => {
                  if (anexo[FIELDS.ANEXO] instanceof File) {
                    return acc + anexo[FIELDS.ANEXO].size;
                  }

                  return acc;
                }, 0);

                // A mensagem de erro de tamanho combinado possui precedência sobre a mensagem de erro individual
                if (tamanhoCombinado > MAX_FILE_SIZE) {
                  return this.createError({
                    path: this.path,
                    message: strings.erroTamanhoCombinadoArquivosMaiorQue50MB,
                  });
                }

                return true;
              }
            ),
          [FIELDS.TIPO_ANEXO]: yup
            .object()
            .when(FIELDS.ANEXO, {
              is: (val) => val && val instanceof File,
              then: yup
                .object()
                .shape({
                  id: yup.number(),
                  descricao: yup.string(),
                })
                .nullable()
                .required(strings.tipoAnexoObrigatorio),
            })
            .nullable(),
        })
      )
      .required(),
  });
};
