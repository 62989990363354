import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { PERFIS, SITUACAO_PROCESSO } from '../../common/Constants';
import Legenda from './legenda';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import Detalhes from './detalhes';
import FormPesquisa from './form';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import {
    SttText,
    SttCircularProgress,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttSeeButton,
    SttHidden,
    SttTranslateHook,
    SttHeading
} from '@stt-componentes/core';
import { temPerfil } from '../../security/acl';

const { LABEL, COR } = SITUACAO_PROCESSO;



const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    laudoPorVencer: {
        backgroundColor: '#F7FC75'
    },
    laudoVencido: {
        backgroundColor: '#D88080'
    },
    chip: {
        '& span': {
            whiteSpace: 'pre-wrap'
        },
        height: 'auto',
        textAlign: 'center'
    },
    tableCell: {
        textAlign: 'center'
    },
    heading: {
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        SEQUENCIAL: 'sequencial',
        NUMERO_PEDIDO: 'numero_pedido',
        DATA: 'data',
        CIDADE_ORIGEM: 'cidade_uf_origem',
        CIDADE_DESTINO: 'cidade_uf_destino',
        DATA_HORA_ATENDIMENTO: 'data_hora_atendimento',
        PROCEDIMENTO_SOLICITADO: 'procedimento_solicitado',
        PACIENTE: 'paciente',
        FUNCIONARIO_PREENCHEDOR: 'funcionario_preenchedor',
        TRANSPORTE_RECOMENDADO: 'transporte_recomendado',
        SITUACAO: 'situacao',
        QTT_SOLICITACAO_PASSAGEM: 'qtt_solicitacao_passagem'
    }
}

const Pesquisa = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { user } = props;
    const classes = useStyles();

    const handleCorFundo = (row) => {
        if (!row.laudo_vencido && !row.laudo_vencendo) {
            return '#FFF';
        }

        if (row.laudo_vencido) {
            return '#D88080';
        }

        if (row.laudo_vencendo) {
            return '#F7FC75';
        }

    }

    //Filtros
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [countPesquisa, setCount] = useState(global.gConfig.pagination.count);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(null);
    const [buscar, setBuscar] = useState(false);

    //Solicitação
    const [solicitacoes, setSolicitacoes] = useState([]);
    const [totalRegistros, setTotalRegistros] = useState(0);

    //Visualização de processo
    const [idTfdSelecionado, setIdTfdSelecionado] = useState(false);
    const [idSolicitacaoSelecionada, setIdSolicitacaoSelecionada] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    }

    const handleVisualizarSolicitacao = (id) => {
        if (id) {
            setIdSolicitacaoSelecionada(id);
            setIdTfdSelecionado(true);
        }
    };

    const handleClickVoltar = () => {
        setIdSolicitacaoSelecionada(null);
        setIdTfdSelecionado(false);
        setBuscar(true);
    }

    const callbackBusca = (dados) => {
        setTotalRegistros(parseInt(dados.totalRegistros));
        setSolicitacoes(dados.itens);
        setBuscar(false);
    }

    const callbackLimpar = () => {
        setCount(global.gConfig.pagination.count);
        setPage(global.gConfig.pagination.start);
        setOrder(ORDENACAO.DIRECAO.ASC);
        setOrderBy(null);
        setSolicitacoes([]);
        setTotalRegistros(0);
    }

    //Toda vez que o componente for renderizado, realiza a busca
    useEffect(() => {
        setBuscar(true);
    }, []);

    return (
        <>
            {
                !idTfdSelecionado ?
                    (
                        <>  
                            <SttHeading variant="h1" color="primary" className={classes.heading}>
                                {strings.tituloPesquisaTfd}
                            </SttHeading>

                            <FormPesquisa
                                callbackAndamento={setBuscaEmAndamento}
                                callbackBusca={callbackBusca}
                                callbackLimpar={callbackLimpar}
                                page={page}
                                count={countPesquisa}
                                orderBy={orderBy}
                                order={order}
                                buscar={buscar}
                            />

                            <TableContainer className={classes.tableWrapper} component={Paper}>
                                <SttTable>
                                    {
                                        <>
                                            <SttHidden smDown>
                                                <caption colSpan={13}><Legenda /></caption>
                                            </SttHidden>
                                            <SttHidden mdUp>
                                                <caption width="96%"><Legenda /></caption>
                                            </SttHidden>
                                        </>
                                    }
                                    <SttTableHead>
                                        <SttTableRow>
                                            <SttHidden smDown>
                                                {
                                                    temPerfil(user, PERFIS.ADMINISTRADOR) &&
                                                    <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.SEQUENCIAL ? order : false}>
                                                        <SttTableSortLabel
                                                            active={orderBy === ORDENACAO.CAMPOS.SEQUENCIAL}
                                                            direction={orderBy === ORDENACAO.CAMPOS.SEQUENCIAL ? order : ORDENACAO.DIRECAO.ASC}
                                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.SEQUENCIAL)}
                                                        >
                                                            {strings.sequencial}
                                                        </SttTableSortLabel>
                                                    </SttTableCell>
                                                }
                                                <SttTableCell width="9%" className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.SITUACAO ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.SITUACAO}
                                                        direction={orderBy === ORDENACAO.CAMPOS.SITUACAO ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.SITUACAO)}>
                                                        {strings.situacao}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                <SttTableCell width="5%" className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.DATA ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.DATA}
                                                        direction={orderBy === ORDENACAO.CAMPOS.DATA ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA)}>
                                                        {strings.data}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                {
                                                    (!temPerfil(user, PERFIS.TFD_MUNICIPAL) && !temPerfil(user, PERFIS.TFD_ESTADUAL)) &&
                                                    <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.CIDADE_ORIGEM ? order : false}>
                                                        <SttTableSortLabel
                                                            active={orderBy === ORDENACAO.CAMPOS.CIDADE_ORIGEM}
                                                            direction={orderBy === ORDENACAO.CAMPOS.CIDADE_ORIGEM ? order : ORDENACAO.DIRECAO.ASC}
                                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE_ORIGEM)}>
                                                            {strings.cidadeOrigem}
                                                        </SttTableSortLabel>
                                                    </SttTableCell>
                                                }
                                                <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.CIDADE_DESTINO ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.CIDADE_DESTINO}
                                                        direction={orderBy === ORDENACAO.CAMPOS.CIDADE_DESTINO ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE_DESTINO)}>
                                                        {strings.cidadeDestino}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.DATA_HORA_ATENDIMENTO ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.DATA_HORA_ATENDIMENTO}
                                                        direction={orderBy === ORDENACAO.CAMPOS.DATA_HORA_ATENDIMENTO ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_HORA_ATENDIMENTO)}>
                                                        {strings.dataHoraAtendimento}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.PROCEDIMENTO_SOLICITADO ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.PROCEDIMENTO_SOLICITADO}
                                                        direction={orderBy === ORDENACAO.CAMPOS.PROCEDIMENTO_SOLICITADO ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.PROCEDIMENTO_SOLICITADO)}>
                                                        {strings.procedimentoSolicitado}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.PACIENTE ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.PACIENTE}
                                                        direction={orderBy === ORDENACAO.CAMPOS.PACIENTE ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.PACIENTE)}>
                                                        {strings.nomePaciente}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                {
                                                    (!temPerfil(user, PERFIS.TFD_MUNICIPAL) && !temPerfil(user, PERFIS.TFD_ESTADUAL)) &&
                                                    <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.FUNCIONARIO_PREENCHEDOR ? order : false}>
                                                        <SttTableSortLabel
                                                            active={orderBy === ORDENACAO.CAMPOS.FUNCIONARIO_PREENCHEDOR}
                                                            direction={orderBy === ORDENACAO.CAMPOS.FUNCIONARIO_PREENCHEDOR ? order : ORDENACAO.DIRECAO.ASC}
                                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.FUNCIONARIO_PREENCHEDOR)}>
                                                            {strings.nomeTecnico}
                                                        </SttTableSortLabel>
                                                    </SttTableCell>
                                                }
                                                {
                                                    (temPerfil(user, PERFIS.TFD_ESTADUAL)) &&
                                                    <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.TRANSPORTE_RECOMENDADO ? order : false}>
                                                        <SttTableSortLabel
                                                            active={orderBy === ORDENACAO.CAMPOS.TRANSPORTE_RECOMENDADO}
                                                            direction={orderBy === ORDENACAO.CAMPOS.TRANSPORTE_RECOMENDADO ? order : ORDENACAO.DIRECAO.ASC}
                                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.TRANSPORTE_RECOMENDADO)}>
                                                            {strings.transporteRecomendado}
                                                        </SttTableSortLabel>
                                                    </SttTableCell>
                                                }
                                                {
                                                    (!temPerfil(user, PERFIS.TFD_ESTADUAL)) &&
                                                    <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.NUMERO_PEDIDO ? order : false}>
                                                        <SttTableSortLabel
                                                            active={orderBy === ORDENACAO.CAMPOS.NUMERO_PEDIDO}
                                                            direction={orderBy === ORDENACAO.CAMPOS.NUMERO_PEDIDO ? order : ORDENACAO.DIRECAO.ASC}
                                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.NUMERO_PEDIDO)}>
                                                            {strings.numeroPedido}
                                                        </SttTableSortLabel>
                                                    </SttTableCell>
                                                }
                                                {
                                                    (!temPerfil(user, PERFIS.TFD_ESTADUAL)) &&
                                                    <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.QTT_SOLICITACAO_PASSAGEM ? order : false}>
                                                        <SttTableSortLabel
                                                            active={orderBy === ORDENACAO.CAMPOS.QTT_SOLICITACAO_PASSAGEM}
                                                            direction={orderBy === ORDENACAO.CAMPOS.QTT_SOLICITACAO_PASSAGEM ? order : ORDENACAO.DIRECAO.ASC}
                                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.QTT_SOLICITACAO_PASSAGEM)}>
                                                            {strings.solicitacoesPassagem}
                                                        </SttTableSortLabel>
                                                    </SttTableCell>
                                                }
                                                <SttTableCell style={{ position: 'sticky', right: 0, backgroundColor: 'white', backgroundClip: 'padding-box', textAlign: 'center' }}>
                                                    {strings.visualizar}
                                                </SttTableCell>
                                            </SttHidden>
                                            <SttHidden mdUp>
                                                <SttTableCell width="100%" colSpan="2">Solicitações de Passagens</SttTableCell>
                                            </SttHidden>
                                        </SttTableRow>
                                    </SttTableHead>
                                    <SttTableBody>
                                        {
                                            buscaEmAndamento
                                                ?
                                                <SttTableRow key={-1}>
                                                    <SttTableCell colSpan={13}
                                                        align="center">
                                                        <SttCircularProgress />
                                                    </SttTableCell>
                                                </SttTableRow>
                                                :
                                                (
                                                    solicitacoes.length === 0 ?
                                                        <SttTableRow key={-1}>
                                                            <SttTableCell colSpan={13}
                                                                align="center">
                                                                {strings.nenhumRegistroEncontrado}
                                                            </SttTableCell>
                                                        </SttTableRow>
                                                        :
                                                        solicitacoes.map((row, index) => (
                                                            <SttTableRow key={index} style={{ backgroundColor: handleCorFundo(row) }}>
                                                                <SttHidden smDown>
                                                                    {
                                                                        temPerfil(user, PERFIS.ADMINISTRADOR) &&
                                                                        <SttTableCell className={classes.tableCell}>{row.sequencial}</SttTableCell>
                                                                    }
                                                                    <SttTableCell className={classes.tableCell}>
                                                                        <Chip className={classes.chip} style={{ backgroundColor: COR[row.situacao] }} label={row.situacaoFormatada}
                                                                        />
                                                                    </SttTableCell>
                                                                    <SttTableCell className={classes.tableCell}>{row.data_tfd_formatada}</SttTableCell>
                                                                    {
                                                                        (!temPerfil(user, PERFIS.TFD_MUNICIPAL) && !temPerfil(user, PERFIS.TFD_ESTADUAL)) &&
                                                                        <SttTableCell className={classes.tableCell}>{row.cidade_uf_origem}</SttTableCell>
                                                                    }
                                                                    <SttTableCell className={classes.tableCell}>{row.cidade_uf_destino}</SttTableCell>
                                                                    <SttTableCell className={classes.tableCell}>{row.data_hora_atendimento_formatada}</SttTableCell>
                                                                    <SttTableCell className={classes.tableCell}>{row.procedimento_solicitado}</SttTableCell>
                                                                    <SttTableCell className={classes.tableCell}>{row.paciente}</SttTableCell>
                                                                    {
                                                                        (!temPerfil(user, PERFIS.TFD_MUNICIPAL) && !temPerfil(user, PERFIS.TFD_ESTADUAL))
                                                                            ?
                                                                            <SttTableCell className={classes.tableCell}>{row.funcionario_preenchedor}</SttTableCell>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        temPerfil(user, PERFIS.TFD_ESTADUAL) &&
                                                                        <SttTableCell className={classes.tableCell}>{row.transporte_recomendado}</SttTableCell>
                                                                    }
                                                                    {
                                                                        !temPerfil(user, PERFIS.TFD_ESTADUAL) &&
                                                                        <SttTableCell className={classes.tableCell}>{row.numero_pedido}</SttTableCell>

                                                                    }

                                                                    {
                                                                        (!temPerfil(user, PERFIS.TFD_ESTADUAL)) &&
                                                                        <SttTableCell className={classes.tableCell}>{row.qtt_solicitacao_passagem}</SttTableCell>
                                                                    }

                                                                </SttHidden>
                                                                <SttHidden mdUp>
                                                                    <SttTableCell width="96%">
                                                                        {
                                                                            temPerfil(user, PERFIS.ADMINISTRADOR) ?
                                                                                <SttText size="small">
                                                                                    <b>Sequencial:</b> {row.sequencial}
                                                                                </SttText>
                                                                                : ''
                                                                        }
                                                                        <Chip className={classes.chip} style={{ marginTop: '4px', backgroundColor: COR[row.situacao] }} label={row.situacaoFormatada} />
                                                                        <SttText size="small">
                                                                            <b>{strings.data}:</b> {row.data_tfd_formatada}
                                                                        </SttText>
                                                                        {
                                                                            (!temPerfil(user, PERFIS.TFD_MUNICIPAL) && !temPerfil(user, PERFIS.TFD_ESTADUAL))
                                                                                ?
                                                                                <SttText size="small">
                                                                                    <b>{strings.cidadeOrigem}:</b> {row.cidade_uf_origem}
                                                                                </SttText>
                                                                                : ''
                                                                        }
                                                                        <SttText size="small">
                                                                            <b>{strings.cidadeDestino}:</b> {row.cidade_uf_destino}
                                                                        </SttText>
                                                                        <SttText size="small">
                                                                            <b>{strings.dataHoraAtendimento}:</b> {row.data_hora_atendimento_formatada}
                                                                        </SttText>
                                                                        <SttText size="small">
                                                                            <b>{strings.procedimentoSolicitado}:</b> {row.procedimento_solicitado}
                                                                        </SttText>
                                                                        <SttText size="small">
                                                                            <b>{strings.nomePaciente}:</b> {row.paciente}
                                                                        </SttText>

                                                                        {
                                                                            (!temPerfil(user, PERFIS.TFD_MUNICIPAL) && !temPerfil(user, PERFIS.TFD_ESTADUAL))
                                                                                ?
                                                                                <SttText size="small">
                                                                                    <b>{strings.nomeTecnico}:</b> {row.funcionario_preenchedor}
                                                                                </SttText>
                                                                                : ''
                                                                        }
                                                                        {
                                                                            (temPerfil(user, PERFIS.TFD_ESTADUAL))
                                                                                ?
                                                                                <SttText size="small">
                                                                                    <b>{strings.transporteRecomendado}:</b> {row.transporte_recomendado}
                                                                                </SttText>
                                                                                : ''
                                                                        }
                                                                        {
                                                                            (!temPerfil(user, PERFIS.TFD_ESTADUAL))
                                                                                ?
                                                                                <SttText size="small">
                                                                                    <b>{strings.numeroPedido}:</b> {row.numero_pedido}
                                                                                </SttText>
                                                                                : ''
                                                                        }

                                                                        {
                                                                            (!temPerfil(user, PERFIS.TFD_ESTADUAL))
                                                                                ?
                                                                                <SttText size="small">
                                                                                    <b>{strings.solicitacoesPassagem}:</b> {row.qtt_solicitacao_passagem}
                                                                                </SttText>
                                                                                : ''
                                                                        }
                                                                    </SttTableCell>
                                                                </SttHidden>
                                                                <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundColor: handleCorFundo(row), backgroundClip: 'padding-box' }}>
                                                                    <SttSeeButton
                                                                        id={`btn-ver-solicitacao-${index}`}
                                                                        onClick={() => {
                                                                            handleVisualizarSolicitacao(row.id);
                                                                        }}
                                                                    />
                                                                </SttTableCell>
                                                            </SttTableRow>
                                                        ))
                                                )
                                        }
                                    </SttTableBody>
                                </SttTable>
                            </TableContainer>
                            {
                                solicitacoes.length > 0 ?
                                    (
                                        <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                                            component="div"
                                            count={totalRegistros}
                                            rowsPerPage={countPesquisa}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            labelRowsPerPage={strings.linhasPorPagina}
                                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                                        />
                                    ) : null
                            }
                        </>
                    ) : <Detalhes id={idSolicitacaoSelecionada} setIdSolicitacaoSelecionada={setIdSolicitacaoSelecionada} voltar={handleClickVoltar} />
            }
        </>
    )
}

Pesquisa.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(Pesquisa);