export default {
    SET_AUTHENTICATED: 'SET_AUTHENTICATED',
    SET_USER: 'SET_USER',
    LOGOUT: 'LOGOUT',
    SET_MESSAGE: 'SET_MESSAGE',
    SET_PARAMETROS_PESQUISA: 'SET_PARAMETROS_PESQUISA',
    LIMPAR_PARAMETROS_PESQUISA: 'LIMPAR_PARAMETROS_PESQUISA',
    SET_TFD: 'SET_TFD',
    SET_ID_COMPROVANTE_COMPARECIMENTO: 'SET_ID_COMPROVANTE_COMPARECIMENTO',
    SET_PODE_SALVAR_AUTOMATICO: 'SET_PODE_SALVAR_AUTOMATICO'
};