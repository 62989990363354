import {
    TIPO_PROCESSO,
    SITUACAO_PACIENTE,
    CARATER_ATENDIMENTO,
    CATEGORIA_ATENDIMENTO_HOSPITALAR,
    PERFIS
} from './Constants';
import moment from 'moment';
import { Util } from '@stt-utilitarios/core';
import { DADOS_GERAIS } from '../componentes/solicitacao/dadosGerais/form/fieldNames';
import { INTERESTADUAL } from '../componentes/solicitacao/interestadual/form/fieldNames';
import { JUDICIAL } from '../componentes/solicitacao/judicial/form/fieldNames';
import { DADOS_TRANSPORTE } from '../componentes/solicitacao/transporte/form/fieldNames';
import { FIELDS } from '../componentes/solicitacao/sigtap/fieldNames';
import { temPerfil } from "../security/acl";

const tratarHoraData = (data) => {
    if (data && moment(data).isValid()) {
        data = moment.utc(data);
        return data.set({ h: 12 }).toISOString();
    }

    return data;
}

const inicializarValoresSolicitacao = (dados, user) => {
    const dadosTfd = {};
    let tipoProcessoPadrao = TIPO_PROCESSO.INTERESTADUAL;

    if (temPerfil(user, PERFIS.TFD_ESTADUAL)) {
        tipoProcessoPadrao = TIPO_PROCESSO.JUDICIAL;
    }

    dados.data = tratarHoraData(dados.data);
    dados.data_preenchimento_laudo_medico = tratarHoraData(dados.data_preenchimento_laudo_medico);
    dados.prazo_cumprimento_processo = tratarHoraData(dados.prazo_cumprimento_processo);
    dados.data_nascimento_paciente = tratarHoraData(dados.data_nascimento_paciente);

    dadosTfd.dadosGerais = {
        id: dados.id,
        numeroPedido: dados.numero_pedido || '',
        tipoProcesso: dados.tipo_processo || tipoProcessoPadrao,
        data: dados.data || new Date(),
        estadoOrigem: dados.id_estado_origem || null,
        municipioOrigem: dados.id_cidade_origem || null,
        cidadeAgendadora: dados.id_tfd_cadastro_cidade_agendadora || null,
        procedimentoSolicitado: dados.procedimento_solicitado || '',
        situacaoPaciente: dados.situacao_paciente || SITUACAO_PACIENTE.PRIMEIRO_ATENDIMENTO_FORA_DOMICILIO,
        diagnosticoInicial: dados.diagnostico_inicial || '',
        caraterAtendimento: dados.carater_atendimento || CARATER_ATENDIMENTO.IGNORAR,
        categoriaAtendimentoHospitalar: dados.categoria_atendimento_hospitalar || CATEGORIA_ATENDIMENTO_HOSPITALAR.IGNORAR,
    };

    dadosTfd.interestadual = {
        historicoDoenca: dados.historico_doenca || '',
        exameFisico: dados.exame_fisico || '',
        exameComplementar: dados.exame_complementar || '',
        diagnosticoRealizado: dados.diagnostico_procedimento || '',
        tratamentosRealizados: dados.tratamento_realizado || '',
        condicoesJustificamInternacaoConsulta: dados.condicao_internacao_consulta || '',
        classeFuncionalInsuficienciaCardiaca: dados.classe_funcional_insuficiencia_cardiaca || '',
        funcaoVentricular: dados.funcao_ventricular || '',
        padraoRespiratorio: dados.padrao_respiratorio || '',
        funcaoRenalHepatica: dados.funcao_renal_hepatica || '',
        estadoNeurologicoSumario: dados.estado_neurologico_sumario || '',
        medicamentoUsoDose: dados.medicamento_dose || '',
        caracterizacaoEventualUrgencia: dados.caracterizacao_urgencia || '',
        historiaPregressa: dados.historia_pregressa_atual || '',
        justificarRazoesImpossibilitamRealizacao: dados.justificativa_nao_tratamento_local || '',
    }

    dadosTfd.cid10 = dados.cid10 || [];

    if (dados.sigtap && dados.sigtap.length) {
        let sigtaps = [];
        dados.sigtap.forEach(dadoSigtap => {
            sigtaps.push({ ano_mes_competencia: dadoSigtap.ano_mes_competencia_sigtap_procedimento, codigo: dadoSigtap.codigo_sigtap_procedimento, nome: dadoSigtap.nome_sigtap });
        });

        dadosTfd.sigtap = sigtaps;
    } else {
        dadosTfd.sigtap = [];
    }

    dadosTfd.dadosTransporte = {
        transporteRecomendavel: dados.id_transporte_recomendado || null,
        numeroAcompanhantes: dados.numero_acompanhantes || '0',
        justificativa: dados.transporte_recomendavel_justificativa || '',
        justificativaNecessidadeAcompanhante: dados.justificativa_acompanhante || ''
    };

    dadosTfd.medicoSolicitante = {
        id: (dados.id_funcionario_requisitante || null),
        nome: (dados.funcionario_requisitante || ''),
        numero_conselho: (dados.numero_conselho_funcionario_requisitante || ''),
        cpf: (dados.cpf_funcionario_requisicante || ''),
        cpfFormatado: dados.cpf_funcionario_requisicante ? Util.util.adicionarMascara(('00000000000' + dados.cpf_funcionario_requisicante.replace(/[\. ,:-]+/g, "")).slice(-11), '000.000.000-00') : ''
    };

    dadosTfd.localPreenchimentoLaudoMedico = dados.local_preenchimento_laudo_medico || '';
    dadosTfd.dataPreenchimentoLaudoMedico = dados.data_preenchimento_laudo_medico ? new Date(dados.data_preenchimento_laudo_medico) : new Date();

    dadosTfd.judicial = {
        sigla: dados.sigla_orgao_sgpe || '',
        numeroProcesso: dados.numero_processo_sgpe || '',
        anoProcesso: dados.ano_processo_sgpe || '',
        numeroAutos: dados.numero_autos_processo_sgpe || '',
        prazoCumprimento: dados.prazo_cumprimento_processo ? new Date(dados.prazo_cumprimento_processo) : null,
        unidadeAtendimento: dados.unidade_atendimento_processo || '',
        procedimentoSolicitado: dados.id_tfd_cadastro_procedimento_solicitado || null
    }
    dadosTfd.anexos = [];
    if (dados.anexos?.length) {
        //Há pelo menos um anexo já vinculado
        dados.anexos.forEach(anexo => {
            dadosTfd.anexos.push(
                {
                    id: anexo.id,
                    observacao: anexo.observacao || '',
                    anexo: new File([""], anexo.nome),
                    tipoAnexo: anexo.id_tipo_anexo || null
                }
            );
        });
        dadosTfd.quantidadeAnexos = dados.anexos.length;
    } else {
        //Nenhum anexo vinculado ainda. Mostrar um campo de anexo para o usuário
        dadosTfd.anexos = [
            {
                id: null,
                anexo: null,
                tipoAnexo: null,
                observacao: ''
            }
        ];
        dadosTfd.quantidadeAnexos = 1;
    }

    dadosTfd.anexosExcluidos = [];

    dadosTfd.paciente = {
        id: dados.id_paciente || '',
        cpf: dados.cpf_paciente ? ('00000000000' + dados.cpf_paciente.replace(/[\. ,:-]+/g, "")).slice(-11) : '', // eslint-disable-line
        cns: dados.cartao_sus_paciente || '',
        nome: dados.nome_paciente || '',
        nomeMae: dados.nome_mae || '',
        fatorRh: dados.fator_rh_paciente,
        grupoSanguineo: dados.grupo_sanguineo_paciente,
        genero: dados.id_sexo_paciente || null,
        dataNascimento: dados.data_nascimento_paciente || null,
        id_endereco_paciente: dados.id_endereco_paciente,
        municipio: dados.id_cidade_paciente || null,
        uf: dados.id_estado_paciente || null,
        numero: dados.numero_paciente || '',
        pais: dados.id_pais_paciente || null,
        peso: dados.peso_paciente || '',
        altura: dados.altura_paciente || '',
        cep: dados.cep_paciente || '',
        logradouro: dados.logradouro_paciente || '',
        bairro: dados.bairro_paciente || '',
        tipoContato1: 1,
        contato1: '',
        tipoContato2: 2,
        contato2: ''
    };

    if (dados.contatosPaciente) {
        dados.contatosPaciente.forEach(contato => {
            if (contato.id_tipo_contato == 1) {
                dadosTfd.paciente.contato1 = contato.contato;
            }

            if (contato.id_tipo_contato == 2) {
                dadosTfd.paciente.contato2 = contato.contato;
            }
        });
    }

    return dadosTfd;
};

const formatarDadosSalvarSolicitacao = (dados) => {
    const dadosTfdFormatados = {};

    const formData = new FormData();

    if (dados.dadosGerais) {
        const dadosGerais = dados.dadosGerais;

        if (dadosGerais.id) {
            formData.append('id', dadosGerais.id);
        }

        formData.append('tipo_processo', dadosGerais.tipoProcesso);

        if (dadosGerais.municipioOrigem && dadosGerais.municipioOrigem.id) {
            formData.append('id_cidade_origem', dadosGerais.municipioOrigem.id);
        }

        if (dadosGerais.cidadeAgendadora && dadosGerais.cidadeAgendadora.id) {
            formData.append('id_tfd_cadastro_cidade_agendadora', dadosGerais.cidadeAgendadora.id);
        }

        if (dadosGerais.numeroPedido && dadosGerais.numeroPedido.trim() != '') {
            formData.append('numero_pedido', dadosGerais.numeroPedido.trim().toUpperCase());
        }

        if (dadosGerais.data) {
            let dataMoment = moment(dadosGerais.data);
            if (dataMoment.isValid()) {
                formData.append('data', dataMoment.format('YYYY-MM-DD'));
            }
        }

        if (dadosGerais.situacaoPaciente) {
            formData.append('situacao_paciente', dadosGerais.situacaoPaciente);
        }

        if (dadosGerais.procedimentoSolicitado && dadosGerais.procedimentoSolicitado.trim() != '') {
            formData.append('procedimento_solicitado', dadosGerais.procedimentoSolicitado.toUpperCase());
        }

        //Tratar dados somente do processo de tipo intraestadual
        if (dadosGerais.tipoProcesso === TIPO_PROCESSO.INTRAESTADUAL) {
            if (dadosGerais.caraterAtendimento) {
                formData.append('carater_atendimento', dadosGerais.caraterAtendimento);
            }

            if (dadosGerais.caraterAtendimento == CARATER_ATENDIMENTO.HOSPITALAR && dadosGerais.categoriaAtendimentoHospitalar != CATEGORIA_ATENDIMENTO_HOSPITALAR.IGNORAR) {
                formData.append('categoria_atendimento_hospitalar', dadosGerais.categoriaAtendimentoHospitalar);
            }

            if (dadosGerais.diagnosticoInicial && dadosGerais.diagnosticoInicial.trim() != '') {
                formData.append('diagnostico_inicial', dadosGerais.diagnosticoInicial.trim().toUpperCase());
            }
        }


        //Tratar dados somente do processo de tipo judicial
        if (dadosGerais.tipoProcesso === TIPO_PROCESSO.JUDICIAL) {
            const dadosJudicial = dados.judicial;
            if (dadosJudicial.sigla) {
                formData.append('sigla_orgao_sgpe', dadosJudicial.sigla.toString().toUpperCase());
            }

            if (dadosJudicial.numeroProcesso) {
                formData.append('numero_processo_sgpe', dadosJudicial.numeroProcesso.toString().toUpperCase());
            }

            if (dadosJudicial.anoProcesso) {
                formData.append('ano_processo_sgpe', dadosJudicial.anoProcesso);
            }

            if (dadosJudicial.numeroAutos) {
                formData.append('numero_autos_processo_sgpe', dadosJudicial.numeroAutos.replace(/[\. ,:-]+/g, "").toUpperCase()); // eslint-disable-line
            }

            if (dadosJudicial.prazoCumprimento) {
                let prazoCumprimentoMoment = moment(dadosJudicial.prazoCumprimento);
                if (prazoCumprimentoMoment.isValid()) {
                    formData.append('prazo_cumprimento_processo', prazoCumprimentoMoment.format('YYYY-MM-DD'));
                }
            }

            if (dadosJudicial.unidadeAtendimento) {
                formData.append('unidade_atendimento_processo', dadosJudicial.unidadeAtendimento.toUpperCase());
            }

            if (dadosJudicial.procedimentoSolicitado && dadosJudicial.procedimentoSolicitado.id) {
                formData.append('id_tfd_cadastro_procedimento_solicitado', dadosJudicial.procedimentoSolicitado.id);
            }
        }

        //Tratamento de dados interestadual
        if (dadosGerais.tipoProcesso === TIPO_PROCESSO.INTERESTADUAL && dados.interestadual) {
            const dadosInterestadual = dados.interestadual;

            if (dadosInterestadual.historicoDoenca.trim() !== '') {
                formData.append('historico_doenca', dadosInterestadual.historicoDoenca.trim().toUpperCase());
            }

            if (dadosInterestadual.exameFisico.trim() !== '') {
                formData.append('exame_fisico', dadosInterestadual.exameFisico.trim().toUpperCase());
            }

            if (dadosInterestadual.caracterizacaoEventualUrgencia.trim() !== '') {
                formData.append('caracterizacao_urgencia', dadosInterestadual.caracterizacaoEventualUrgencia.trim().toUpperCase());
            }

            if (dadosInterestadual.classeFuncionalInsuficienciaCardiaca.trim() !== '') {
                formData.append('classe_funcional_insuficiencia_cardiaca', dadosInterestadual.classeFuncionalInsuficienciaCardiaca.trim().toUpperCase());
            }

            if (dadosInterestadual.condicoesJustificamInternacaoConsulta.trim() !== '') {
                formData.append('condicao_internacao_consulta', dadosInterestadual.condicoesJustificamInternacaoConsulta.trim().toUpperCase());
            }

            if (dadosInterestadual.diagnosticoRealizado.trim() !== '') {
                formData.append('diagnostico_procedimento', dadosInterestadual.diagnosticoRealizado.trim().toUpperCase());
            }

            if (dadosInterestadual.estadoNeurologicoSumario.trim() !== '') {
                formData.append('estado_neurologico_sumario', dadosInterestadual.estadoNeurologicoSumario.trim().toUpperCase());
            }

            if (dadosInterestadual.exameComplementar.trim() !== '') {
                formData.append('exame_complementar', dadosInterestadual.exameComplementar.trim().toUpperCase());
            }

            if (dadosInterestadual.funcaoRenalHepatica.trim() !== '') {
                formData.append('funcao_renal_hepatica', dadosInterestadual.funcaoRenalHepatica.trim().toUpperCase());
            }

            if (dadosInterestadual.funcaoVentricular.trim() !== '') {
                formData.append('funcao_ventricular', dadosInterestadual.funcaoVentricular.trim().toUpperCase());
            }

            if (dadosInterestadual.historiaPregressa.trim() !== '') {
                formData.append('historia_pregressa_atual', dadosInterestadual.historiaPregressa.trim().toUpperCase());
            }

            if (dadosInterestadual.justificarRazoesImpossibilitamRealizacao.trim() !== '') {
                formData.append('justificativa_nao_tratamento_local', dadosInterestadual.justificarRazoesImpossibilitamRealizacao.trim().toUpperCase());
            }

            if (dadosInterestadual.medicamentoUsoDose.trim() !== '') {
                formData.append('medicamento_dose', dadosInterestadual.medicamentoUsoDose.trim().toUpperCase());
            }

            if (dadosInterestadual.padraoRespiratorio.trim() !== '') {
                formData.append('padrao_respiratorio', dadosInterestadual.padraoRespiratorio.trim().toUpperCase());
            }

            if (dadosInterestadual.tratamentosRealizados.trim() !== '') {
                formData.append('tratamento_realizado', dadosInterestadual.tratamentosRealizados.trim().toUpperCase());
            }
        }
    }

    //Tratamento dos dados de CID10
    if (dados.cid10) {
        const cid10 = dados.cid10;
        const cids = [];

        cid10.forEach(cid => {
            const cid10Formatado = {};
            cid10Formatado['id_tfd'] = dadosTfdFormatados['id'];
            cid10Formatado['codigo_cid10_categoria'] = cid['codigo_categoria'];
            cid10Formatado['codigo_cid10_subcategoria'] = cid['codigo'];

            cids.push(cid10Formatado);
        });

        formData.append('cid10', JSON.stringify(cid10));
    }

    //Tratamento de dados do SIGTAP
    if (dados.sigtap && dados.sigtap[0]) {
        const dadosSigtap = dados.sigtap;
        const sigtaps = [];

        dadosSigtap.forEach(sigtap => {
            const sigtapFormatado = {};
            sigtapFormatado['id_tfd'] = dadosTfdFormatados['id'];
            sigtapFormatado['ano_mes_competencia_sigtap_procedimento'] = sigtap['ano_mes_competencia'];
            sigtapFormatado['codigo_sigtap_procedimento'] = sigtap['codigo'];

            sigtaps.push(sigtapFormatado);
        });

        formData.append('sigtap', JSON.stringify(sigtaps));
    }

    //Tratamento de dados referente ao transporte
    if (dados.dadosTransporte) {
        const dadosTransporte = dados.dadosTransporte;



        if (dadosTransporte.numeroAcompanhantes) {
            formData.append('numero_acompanhantes', dadosTransporte.numeroAcompanhantes);

            if (parseInt(dadosTransporte.numeroAcompanhantes) > 1) {
                if (dadosTransporte.justificativaNecessidadeAcompanhante && dadosTransporte.justificativaNecessidadeAcompanhante != '') {
                    formData.append('justificativa_acompanhante', dadosTransporte.justificativaNecessidadeAcompanhante.trim().toUpperCase());
                }
            }
        }

        if (dadosTransporte.transporteRecomendavel && dadosTransporte.transporteRecomendavel.id) {
            formData.append('id_tfd_cadastro_transporte_recomendavel', dadosTransporte.transporteRecomendavel.id);

            formData.append('exige_justificativa_transporte_recomendavel', dadosTransporte.transporteRecomendavel.exige_justificativa);

            if (dadosTransporte.transporteRecomendavel.exige_justificativa && dadosTransporte.justificativa && dadosTransporte.justificativa.trim().length) {
                formData.append('transporte_recomendavel_justificativa', dadosTransporte.justificativa.trim().toUpperCase());
            }
        }
    }

    //Tratamento de dados referente ao médico solicitante
    if (dados.localPreenchimentoLaudoMedico && dados.localPreenchimentoLaudoMedico.trim().length) {
        formData.append('local_preenchimento_laudo_medico', dados.localPreenchimentoLaudoMedico.trim().toUpperCase());
    }

    if (dados.dataPreenchimentoLaudoMedico) {
        formData.append('data_preenchimento_laudo_medico', moment(dados.dataPreenchimentoLaudoMedico).format('YYYY-MM-DD'));
    }

    if (dados.medicoSolicitante && dados.medicoSolicitante.id) {
        formData.append('id_funcionario_requisitante', dados.medicoSolicitante.id);
    }

    //Tratamento de dados de transporte
    if (dados.anexos?.length) {
        const dadosAnexos = [];
        dados.anexos.forEach((umAnexo, indice) => {
            if (umAnexo.anexo && umAnexo.anexo.name && umAnexo.tipoAnexo && umAnexo.tipoAnexo.id) {
                let observacao = null;
                if (umAnexo.observacao) {
                    observacao = umAnexo.observacao.trim().toUpperCase();
                }

                dadosAnexos.push(
                    {
                        id: umAnexo.id,
                        nome: umAnexo.anexo.name,
                        observacao: observacao,
                        id_tfd_cadastro_tipo_anexo: umAnexo.tipoAnexo ? umAnexo.tipoAnexo.id : null
                    }
                );
                formData.append(`anexo.${indice}`, umAnexo.anexo);
            }
        });
        formData.append('dadosAnexos', JSON.stringify(dadosAnexos));
    }

    //Tratamento de anexos excluídos
    if (dados.anexosExcluidos && dados.anexosExcluidos.length) {
        formData.append('dadosAnexosExcluidos', JSON.stringify(dados.anexosExcluidos));
    }

    //Tratamento dos dados do paciente
    if (dados.paciente) {
        if (dados.paciente.cpf) {
            dados.paciente.cpf = dados.paciente.cpf.replace(/\D+/g, '');
        }
        if (dados.paciente.cep) {
            dados.paciente.cep = ('' + dados.paciente.cep).replace(/\D+/g, '');
        }
    }
    formData.append('paciente', JSON.stringify(dados.paciente));

    if (dados.encaminhamento) {
        formData.append('encaminhamento', JSON.stringify(dados.encaminhamento));
    }

    return formData;
}

const verificarErrosOrdemPrecedencia = (erros, secao) => {
    const ordemPrecedencia = [
        DADOS_GERAIS,
        'paciente',
        FIELDS.SIGTAP,
        INTERESTADUAL,
        JUDICIAL,
        DADOS_TRANSPORTE
    ];
    for (const ordem of ordemPrecedencia) {
        if (secao === ordem) {
            return false;
        }

        if (erros[ordem]) {
            return true;
        }
    }
}

export default { tratarHoraData, inicializarValoresSolicitacao, formatarDadosSalvarSolicitacao, verificarErrosOrdemPrecedencia };