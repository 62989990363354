export const INTERESTADUAL = 'interestadual';
export const HISTORICO_DOENCA = 'historicoDoenca';
export const EXAME_FISICO = 'exameFisico';
export const EXAME_COMPLEMENTAR = 'exameComplementar';
export const DIAGNOSTICO_RELACIONADO_PROCEDIMENTO = 'diagnosticoRealizado';
export const TRATAMENTOS_REALIZADOS = 'tratamentosRealizados';
export const CONDICOES_JUSTIFICAM_INTERNACAO_CONSULTA = 'condicoesJustificamInternacaoConsulta';
export const CLASSE_FUNCIONAL_INSUFICIENCIA_CARDIACA = 'classeFuncionalInsuficienciaCardiaca';
export const FUNCAO_VENTRICULAR = 'funcaoVentricular';
export const PADRAO_RESPIRATORIO = 'padraoRespiratorio';
export const FUNCAO_RENAL_HEPATICA = 'funcaoRenalHepatica';
export const ESTADO_NEUROLOGICO_SUMARIO = 'estadoNeurologicoSumario';
export const MEDICAMENTO_USO_DOSE = 'medicamentoUsoDose';
export const CARACTERIZACAO_EVENTUAL_URGENCIA = 'caracterizacaoEventualUrgencia';
export const HISTORIA_PREGRESSA = 'historiaPregressa';
export const JUSTIFICAR_RAZOES_IMPOSSIBILITAM_REALIZACAO = 'justificarRazoesImpossibilitamRealizacao';