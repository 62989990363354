import React, { useRef, useState, useEffect, useContext } from "react";
import {
    SttButton,
    SttGrid,
    SttInput,
    SttHeading,
    SttFormControl,
    SttRadioGroup,
    SttFormControlLabel,
    SttRadioButton,
    SttFormHelperText,
    SttFileSelect,
    SttAutocomplete,
    SttCircularProgress,
    SttNumberInput,
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import {
    Formik,
    FastField,
    Field,
    FieldArray
} from 'formik';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { makeStyles } from '@material-ui/core/styles';
import {
    VINCULO_COMPROVANTE,
    ANEXOS_COMPARECIMENTO,
    SOLICITACAO_PASSAGEM_VINCULO,
    PERNOITES,
    DIARIAS,
    NUMERO_ACOMPANHANTES,
    OBSERVACAO,
    JUSTIFICATIVA,
    ID
} from './fieldNames';
import { COMPROVANTE_COMPARECIMENTO, TIPO_VINCULO_COMPROVANTE_COMPARECIMENTO_CONSTANTES } from '../../../common/Constants';

const validationSchema = (strings) => {
    return yup.object().shape({
        [VINCULO_COMPROVANTE]: yup
            .string()
            .nullable()
            .required(strings.tipoVinculoComprovanteObrigatorio),
        [PERNOITES]: yup
            .number()
            .typeError(strings.somenteNumerosPermitidos)
            .nullable()
            .required(strings.campoObrigatorio),
        [DIARIAS]: yup
            .number()
            .typeError(strings.somenteNumerosPermitidos)
            .nullable()
            .required(strings.campoObrigatorio),
        [NUMERO_ACOMPANHANTES]: yup
            .number()
            .typeError(strings.somenteNumerosPermitidos)
            .nullable()
            .required(strings.campoObrigatorio),
        [JUSTIFICATIVA]: yup.string()
            .when(VINCULO_COMPROVANTE, {
                is: (val) => val === TIPO_VINCULO_COMPROVANTE_COMPARECIMENTO_CONSTANTES.TFD,
                then: yup.string()
                    .nullable()
                    .required(strings.campoObrigatorio)
            }),
        [SOLICITACAO_PASSAGEM_VINCULO]: yup.object().when(VINCULO_COMPROVANTE, {
            is: (val) => val === TIPO_VINCULO_COMPROVANTE_COMPARECIMENTO_CONSTANTES.PASSAGEM,
            then: yup.object().shape({
                id: yup.number()
            }).required(strings.campoObrigatorio)
        }).nullable()
    });
}
const useStyles = makeStyles(theme => ({
    botaoMais: {
        minWidth: 0,
        marginLeft: 0
    },
    divExternaImagens: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        overflowY: 'auto'
    },
    botaoMenos: {
        minWidth: 0
    },
    container: {
        marginBottom: 0
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    autocomplete: {
        flexGrow: '1'
    }
}));

const OperacaoAnexarComprovanteComparecimento = ({ callback, callbackSucesso, id, alerta, ...props }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const schema = validationSchema(strings);
    const formRef = useRef();
    const TFD_API_BASE_URL = global.gConfig.url_base_tfd;

    const initialValues = {
        [VINCULO_COMPROVANTE]: TIPO_VINCULO_COMPROVANTE_COMPARECIMENTO_CONSTANTES.TFD,
        [ANEXOS_COMPARECIMENTO]: [{}],
        [PERNOITES]: '',
        [DIARIAS]: '',
        [NUMERO_ACOMPANHANTES]: '',
        [JUSTIFICATIVA]: '',
        [SOLICITACAO_PASSAGEM_VINCULO]: null,
        [OBSERVACAO]: ''
    };

    const {
        setMostrarAlerta,
        setTituloAlerta,
        setMensagemAlerta,
        setTipoAlerta,
        setOpcoesAlerta,
        setOnCloseAlerta
    } = alerta;

    const [solicitacoesPassagem, setSolicitacoesPassagem] = useState(null);

    const [carregado, setCarregado] = useState(false);

    const validarAnexo = (value) => {
        let error;

        if (!value || !(value instanceof File)) {
            return strings.selecionarArquivoValido;
        }
        if (value.type !== 'image/jpeg' && value.type !== 'image/png' && value.type !== 'application/pdf') {
            return strings.formatoArquivoInvalido;
        }
        return error;
    }

    useEffect(() => {
        axios
            .get(`${TFD_API_BASE_URL}/processos-transporte-por-id-tfd/${id}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const itens = response.data.data;
                    setSolicitacoesPassagem(itens);
                    setCarregado(true);
                }
            })
            .catch(err => {
                setSolicitacoesPassagem([]);
                setTituloAlerta(strings.erro);
                setMensagemAlerta(strings.erroRecuperacaoProcessosTransporte);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        }
                    }
                ]);
                setOnCloseAlerta(() => () => {
                    setMostrarAlerta(false);
                });
                setMostrarAlerta(true);
            });
    }, []);

    const closeAlerta = (resetForm) => {
        setMostrarAlerta(false);
        resetForm({ values: initialValues });
        callbackSucesso();
    }

    return (
        <>
            {
                carregado ?
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(dados, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            const formData = new FormData();

                            formData.append(ID, id);
                            formData.append(JUSTIFICATIVA, dados.justificativa.trim().toUpperCase());
                            formData.append(OBSERVACAO, dados.observacao.trim().toUpperCase());
                            formData.append(VINCULO_COMPROVANTE, dados.vinculo_comprovante);
                            if (dados.vinculo_comprovante == COMPROVANTE_COMPARECIMENTO.VINCULO_COMPROVANTE_PASSAGENS) {
                                formData.append(SOLICITACAO_PASSAGEM_VINCULO, dados.solicitacao_passagem_vinculo.id);
                            }
                            formData.append(PERNOITES, dados.pernoites);
                            formData.append(DIARIAS, dados.diarias);
                            formData.append(NUMERO_ACOMPANHANTES, dados.numero_acompanhantes);

                            dados.anexos_comparecimento.forEach((anexo, index) => {
                                if (anexo && (anexo instanceof File)) {
                                    formData.append(`anexos_comparecimento.${index}`, anexo);
                                }
                            });

                            axios.post(`${TFD_API_BASE_URL}/anexar-comprovante-comparecimento`, formData, { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' } })
                                .then((response) => {

                                    const { data } = response;
                                    if (response.status === HttpStatus.OK) {
                                        setTituloAlerta(strings.sucesso);
                                        setMensagemAlerta(data.message ? data.message : strings.sucessoOperacao);
                                        setTipoAlerta('success');
                                        setOpcoesAlerta([
                                            {
                                                title: strings.ok,
                                                onClick: () => {
                                                    closeAlerta(resetForm);
                                                }
                                            }
                                        ]);
                                        setOnCloseAlerta(() => () => {
                                            closeAlerta(resetForm);
                                        });
                                        setMostrarAlerta(true);
                                    }
                                })
                                .catch(err => {
                                    const { response } = err;
                                    let mensagem = strings.mensagemErroGeral;

                                    if (response) {
                                        if (response.status === HttpStatus.BAD_REQUEST) {
                                            const { data } = response;
                                            let arrMensagem = [];
                                            data.errors.forEach(error => {
                                                arrMensagem.push(`- ${error.message}`);
                                            });

                                            if (arrMensagem.length) {
                                                mensagem = arrMensagem.join('\n');
                                            }
                                        }
                                    }

                                    setTituloAlerta(strings.erro);
                                    setMensagemAlerta(mensagem);
                                    setTipoAlerta('error');
                                    setOpcoesAlerta([
                                        {
                                            title: strings.ok,
                                            onClick: () => {
                                                setMostrarAlerta(false);
                                            }
                                        }
                                    ]);
                                    setOnCloseAlerta(() => () => {
                                        setMostrarAlerta(false);
                                    });
                                    setMostrarAlerta(true);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit,
                                resetForm,
                                values
                            }) => {
                                return (
                                    <>
                                        <form onSubmit={handleSubmit} noValidate>
                                            <SttGrid container spacing={3}>
                                                <SttGrid item xs={12}>
                                                    <Field name={VINCULO_COMPROVANTE}>
                                                        {({
                                                            field,
                                                            form,
                                                            meta,
                                                        }) => (
                                                            <div>
                                                                <SttHeading required variant="h4" color="primary">{strings.vinculoComprovante}</SttHeading>
                                                                <br />
                                                                <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined">
                                                                    <SttRadioGroup row>

                                                                        {
                                                                            <SttFormControlLabel
                                                                                {...field}
                                                                                control={
                                                                                    <SttRadioButton
                                                                                        type="radio"
                                                                                        value={TIPO_VINCULO_COMPROVANTE_COMPARECIMENTO_CONSTANTES.TFD}
                                                                                        color="primary"
                                                                                        checked={field.value === TIPO_VINCULO_COMPROVANTE_COMPARECIMENTO_CONSTANTES.TFD}
                                                                                    />
                                                                                }
                                                                                disabled={solicitacoesPassagem.length == 0}
                                                                                label={strings.processoTfd}
                                                                            />
                                                                        }

                                                                        <SttFormControlLabel
                                                                            {...field}
                                                                            control={
                                                                                <SttRadioButton
                                                                                    type="radio"
                                                                                    value={TIPO_VINCULO_COMPROVANTE_COMPARECIMENTO_CONSTANTES.PASSAGEM}
                                                                                    color="primary"
                                                                                    checked={field.value === TIPO_VINCULO_COMPROVANTE_COMPARECIMENTO_CONSTANTES.PASSAGEM}
                                                                                />
                                                                            }
                                                                            disabled={solicitacoesPassagem.length == 0}
                                                                            label={strings.processoPassagem}
                                                                        />
                                                                    </SttRadioGroup>
                                                                    {
                                                                        meta.touched && meta.error &&
                                                                        <SttFormHelperText error>
                                                                            {meta.error}
                                                                        </SttFormHelperText>
                                                                    }
                                                                </SttFormControl>
                                                            </div>
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12}>
                                                    <SttHeading variant="h5" color="primary">{strings.comprovanteComparecimento}</SttHeading>
                                                    <FieldArray name="anexos_comparecimento" render={
                                                        ({ remove, push }) => (
                                                            <>
                                                                <div className={classes.divExternaImagens}>
                                                                    {
                                                                        values[ANEXOS_COMPARECIMENTO].map((anexo, indice) => (
                                                                            <Field key={indice} name={`${ANEXOS_COMPARECIMENTO}.${indice}`} validate={validarAnexo}>
                                                                                {({
                                                                                    field: { name, value },
                                                                                    form: { setFieldValue },
                                                                                    meta
                                                                                }) => (
                                                                                    <>
                                                                                        <SttFileSelect
                                                                                            labelInput='Arquivo (.pdf, .jpg, .jpeg)'
                                                                                            onFileChange={(event) => {
                                                                                                //Caso a imagem tenha sido excluída, remove a mesma do array de imagens no formulário e retira do array de imagens
                                                                                                if (!event) {
                                                                                                    if (values[ANEXOS_COMPARECIMENTO].length > 1) {
                                                                                                        remove(indice);
                                                                                                        return;
                                                                                                    }
                                                                                                    setFieldValue(`${ANEXOS_COMPARECIMENTO}[${indice}]`, {});
                                                                                                    return;
                                                                                                }

                                                                                                if (event.target.files[0]) {
                                                                                                    //Seta o valor da imagem no campo correspondente do formulário
                                                                                                    setFieldValue(`${ANEXOS_COMPARECIMENTO}[${indice}]`, event.target.files[0]);
                                                                                                }
                                                                                            }}
                                                                                            file={value}
                                                                                            inputprops={{
                                                                                                name: name,
                                                                                                label: 'Arquivo (.pdf, .jpg, .jpeg)',
                                                                                                value: value && value.name,
                                                                                                error: meta.touched && meta.error ? meta.error : undefined
                                                                                            }}
                                                                                            urlDownload={anexo.id ? `${TFD_API_BASE_URL}/arquivo-comprovante-comparecimento/${window.btoa(anexo.id)}/${encodeURIComponent(anexo.nome)}` : null}
                                                                                            headers={getHeaders()}
                                                                                            accept={["image/*", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel", "application/pdf", 'application/vnd.ms-excel']}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </Field>
                                                                        ))
                                                                    }
                                                                </div>
                                                                <SttButton
                                                                    className={classes.botaoMais}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        let chave = (values[ANEXOS_COMPARECIMENTO].length + 1);
                                                                        let obj = {};
                                                                        obj[chave] = {};
                                                                        push({});
                                                                    }}
                                                                >
                                                                    {strings.maisSimbolo}
                                                                </SttButton>
                                                                <SttButton
                                                                    className={classes.botaoMenos}
                                                                    variant="contained"
                                                                    disabled={values[ANEXOS_COMPARECIMENTO].length == 1}
                                                                    onClick={() => {
                                                                        if (values[ANEXOS_COMPARECIMENTO].length > 1) {
                                                                            let indice = values[ANEXOS_COMPARECIMENTO].length - 1;
                                                                            remove(indice);
                                                                        }
                                                                    }}
                                                                    color="primary">
                                                                    {strings.menosSimbolo}
                                                                </SttButton>
                                                            </>
                                                        )}
                                                    />
                                                </SttGrid>
                                                {
                                                    values.vinculo_comprovante == TIPO_VINCULO_COMPROVANTE_COMPARECIMENTO_CONSTANTES.TFD &&
                                                    <SttGrid item xs={12}>
                                                        <FastField name={JUSTIFICATIVA}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttInput
                                                                    multiline
                                                                    rows={5}
                                                                    required={true}
                                                                    {...field}
                                                                    label={strings.justificativa}
                                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>
                                                }

                                                {
                                                    values.vinculo_comprovante == TIPO_VINCULO_COMPROVANTE_COMPARECIMENTO_CONSTANTES.PASSAGEM &&
                                                    <SttGrid item xs={12}>
                                                        <Field name={SOLICITACAO_PASSAGEM_VINCULO}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue },
                                                                meta
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.solicitacaoPassagemVinculo,
                                                                        error: meta.touched && meta.error ? meta.error : undefined
                                                                    }}
                                                                    getOptionLabel={option => option && option.requisicao || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                    options={solicitacoesPassagem}
                                                                    carregando={!solicitacoesPassagem}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        setFieldValue(SOLICITACAO_PASSAGEM_VINCULO, item || null);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>
                                                }

                                                <SttGrid item xs={4}>
                                                    <FastField name={PERNOITES}>
                                                        {({
                                                            field,
                                                            meta
                                                        }) => (
                                                            <SttNumberInput
                                                                inputProps={{
                                                                    maxLength: 2,
                                                                    min: 0,
                                                                    max: 99
                                                                }}
                                                                {...field}
                                                                label={strings.ajudaCustoComPernoite}
                                                                className={classes.autocomplete}
                                                                required
                                                                error={meta.touched && meta.error ? true : false}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>

                                                <SttGrid item xs={4}>
                                                    <FastField name={DIARIAS}>
                                                        {({
                                                            field,
                                                            meta
                                                        }) => (
                                                            <SttNumberInput
                                                                inputProps={{
                                                                    maxLength: 2,
                                                                    min: 0,
                                                                    max: 99
                                                                }}
                                                                {...field}
                                                                label={strings.ajudaCustoSemPernoite}
                                                                className={classes.autocomplete}
                                                                required
                                                                error={meta.touched && meta.error ? true : false}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>

                                                <SttGrid item xs={4}>
                                                    <FastField name={NUMERO_ACOMPANHANTES}>
                                                        {({
                                                            field,
                                                            meta
                                                        }) => (
                                                            <SttNumberInput
                                                                inputProps={{
                                                                    maxLength: 1,
                                                                    min: 0,
                                                                    max: 9
                                                                }}
                                                                {...field}
                                                                label={strings.numeroAcompanhantes}
                                                                className={classes.autocomplete}
                                                                required
                                                                error={meta.touched && meta.error ? true : false}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>

                                                <SttGrid item xs={12}>
                                                    <FastField name={OBSERVACAO}>
                                                        {({
                                                            field,
                                                            meta
                                                        }) => (
                                                            <SttInput
                                                                multiline
                                                                rows={5}
                                                                {...field}
                                                                label={strings.observacoes}
                                                                inputProps={{ style: { textTransform: "uppercase" } }}
                                                                error={meta.touched && meta.error ? true : false}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                            </SttGrid>
                                            <SttGrid container spacing={3}>
                                                <SttGrid item xs={12}>
                                                    <SttButton
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        nomarginleft="true"
                                                    >
                                                        {strings.confirmar}
                                                    </SttButton>
                                                    <SttButton
                                                        type="button"
                                                        variant="outlined"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                        onClick={() => {
                                                            resetForm({ values: initialValues });
                                                        }}
                                                    >
                                                        {strings.limpar}
                                                    </SttButton>
                                                    <SttButton
                                                        type="button"
                                                        variant="outlined"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                        onClick={() => {
                                                            resetForm({ values: initialValues });
                                                            callback();
                                                        }}
                                                    >
                                                        {strings.fechar}
                                                    </SttButton>
                                                </SttGrid>
                                            </SttGrid>
                                        </form>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.carregando}
                                        />
                                    </>
                                )
                            }
                        }
                    </Formik> : (
                        <div className={classes.carregando}>
                            <SttCircularProgress color="primary" />
                        </div>
                    )
            }

        </>
    );
}

export default OperacaoAnexarComprovanteComparecimento;