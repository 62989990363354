import * as yup from 'yup';
import {
  DADOS_TRANSPORTE,
  NUMERO_ACOMPANHANTES,
  TRANSPORTE_RECOMENDAVEL,
  JUSTIFICATIVA,
  JUSTIFICATIVA_NECESSIDADE_ACOMPANHANTE,
} from './fieldNames';
import moment from 'moment';
import { FIELDS } from '../../paciente/fieldNames';

export default (strings) => {
  const numeroAcompanhantesObrigatorioMenorIdade = (parent) => {
    let dados = parent.value;
    if (
      !(
        dados[FIELDS.PACIENTE] && dados[FIELDS.PACIENTE][FIELDS.DATA_NASCIMENTO]
      )
    ) {
      return true;
    }

    if (moment(dados[FIELDS.PACIENTE][FIELDS.DATA_NASCIMENTO]).isValid()) {
      return !(
        moment().diff(dados[FIELDS.PACIENTE][FIELDS.DATA_NASCIMENTO], 'years') <
          18 && parseInt(dados[DADOS_TRANSPORTE][NUMERO_ACOMPANHANTES]) === 0
      );
    }
  };

  return yup.object().shape({
    [DADOS_TRANSPORTE]: yup
      .object()
      .shape({
        [TRANSPORTE_RECOMENDAVEL]: yup
          .object()
          .shape({
            id: yup.number(),
            descricao: yup.string(),
          })
          .nullable()
          .required(strings.transporteRecomendadoObrigatorio),
        [NUMERO_ACOMPANHANTES]: yup
          .number()
          .test(
            'pacienteMenorIdade',
            strings.numeroAcompanhantesObrigatorioMenorIdade,
            function () {
              const { from } = this;
              return numeroAcompanhantesObrigatorioMenorIdade(from[1]);
            }
          )
          .test('tamanho', strings.numeroAcompanhantesNegativo, (val) => {
            if (!val) {
              return true;
            }

            return val > 0;
          })
          .nullable()
          .required(strings.numeroAcompanhantesObrigatorio),
        [JUSTIFICATIVA]: yup
          .string()
          .when(TRANSPORTE_RECOMENDAVEL, {
            is: (val) => val && val.exige_justificativa === true,
            then: yup
              .string()
              .trim()
              .required(strings.justificativaObrigatoria),
          })
          .nullable(),
        [JUSTIFICATIVA_NECESSIDADE_ACOMPANHANTE]: yup
          .string()
          .when(NUMERO_ACOMPANHANTES, {
            is: (val) => val && val >= 1,
            then: yup
              .string()
              .trim()
              .required(strings.justificativaNumeroAcompanhantesObrigatoria),
          })
          .nullable(),
      })
      .required(),
  });
};
