import React, { useEffect, useRef, useState, lazy, Suspense, useContext } from "react";
import {
    SttButton,
    SttLoading,
    SttGrid,
    SttInput,
    SttRadioButton,
    SttRadioGroup,
    SttFormControlLabel,
    SttFormHelperText,
    SttFormControl,
    SttHeading,
    SttCircularProgress,
    SttDatePicker,
    SttTranslateHook
} from '@stt-componentes/core';
import {
    Formik,
    FastField,
    Field
} from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../../request';
import {
    TIPO_ENCAMINHAMENTO,
    PROCEDIMENTO,
    MOVIMENTACAO,
    INSTITUICAO,
    REGULADOR,
    PERIODO_ATENDIMENTO,
    DATA_HORA,
    AVALIADOR
} from "./fieldNames";
import {
    PERFIS,
    TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES,
    TIPO_ENCAMINHAMENTO_REGULADOR_CONSTANTES,
    PERIODO_ATENDIMENTO_CONSTANTES,
    situacao as SITUACAO_CONSTANTES
} from "../../../common/Constants";
const SttFuncionario = lazy(() => import('@stt-componentes/funcionario'));
const SttInstituicao = lazy(() => import('@stt-componentes/instituicao'));

const validationSchema = (strings) => {
    return yup.object().shape({
        [TIPO_ENCAMINHAMENTO]: yup
            .string()
            .nullable()
            .required(strings.campoObrigatorio),
        [INSTITUICAO]: yup.object().when(TIPO_ENCAMINHAMENTO, {
            is: (val) => val === TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.AUTORIZAR,
            then: yup.object().shape({
                id: yup.number()
            }).required(strings.campoObrigatorio)
        }).nullable(),
        [DATA_HORA]: yup.date().when(TIPO_ENCAMINHAMENTO, {
            is: (val) => val === TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.AUTORIZAR,
            then: yup.date().typeError('Data inválida').required('Campo obrigatório')
        }).nullable(),
        [REGULADOR]: yup.object().when(TIPO_ENCAMINHAMENTO, {
            is: (val) => val === TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.ENCAMINHAR_TFD_REGULADOR,
            then: yup.object().shape({
                id: yup.number().required(),
                nome: yup.string().required()
            }).required(strings.campoObrigatorio)
        }).nullable(),
        [AVALIADOR]: yup.object().when(TIPO_ENCAMINHAMENTO, {
            is: (val) => val === TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.ENCAMINHAR_TFD_AVALIADOR,
            then: yup.object().shape({
                id: yup.number().required(),
                nome: yup.string().required()
            }).required(strings.campoObrigatorio)
        }).nullable(),
    });
}

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }
}));

const OperacaoEncaminhamentoEstadual = ({ callback, callbackSucesso, id, tipoProcesso, alerta, situacao, ...props }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const schema = validationSchema(strings);
    const formRef = useRef();
    const TFD_API_BASE_URL = global.gConfig.url_base_tfd;

    const {
        setMostrarAlerta,
        setTituloAlerta,
        setMensagemAlerta,
        setTipoAlerta,
        setOpcoesAlerta,
        setOnCloseAlerta
    } = alerta;

    const [initialValues, setInitialValues] = useState(
        {
            tipoEncaminhamento: null,
            instituicao: null,
            regulador: null,
            movimentacao: '',
            data_hora: null,
            parecer_inconclusivo_regulador: null,
            periodoAtendimento: null
        }
    );
    const [carregado, setCarregado] = useState(false);

    const estruturarFiltros = (data) => {
        let filtros = {
            ...data
        };

        if (data.instituicao) {
            filtros.instituicao = data.instituicao.id;
        }

        if (data.regulador) {
            filtros.regulador = data.regulador.id;
        }

        if (data.avaliador) {
            filtros.avaliador = data.avaliador.id;
        }

        if (data.movimentacao) {
            data.movimentacao = data.movimentacao.trim().toUpperCase();
        }

        if (data.procedimento) {
            data.procedimento = data.procedimento.trim().toUpperCase();
        }

        if (data.data_hora) {
            let dataFormatada = new Date(data.data_hora.valueOf() - data.data_hora.getTimezoneOffset() * 60000);
            filtros.data_hora = dataFormatada.toISOString();
        }

        return filtros;
    };

    useEffect(() => {
        if (id) {
            let tipoEncaminhamento = null;

            if (situacao === SITUACAO_CONSTANTES.AGUARDANDO_AVALIACAO_TFD_ESTADUAL || situacao === SITUACAO_CONSTANTES.AGUARDANDO_AGENDAMENTO_TFD_ESTADUAL || situacao === SITUACAO_CONSTANTES.AGUARDANDO_NOVAMENTE_AVALIACAO_TFD_ESTADUAL) {
                tipoEncaminhamento = TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.AUTORIZAR;
            }

            if (situacao === SITUACAO_CONSTANTES.AGUARDANDO_AVALIACAO_TFD_REGULADOR) {
                tipoEncaminhamento = TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.ENCAMINHAR_TFD_REGULADOR;
            }

            if (situacao === SITUACAO_CONSTANTES.AGUARDANDO_AVALIACAO_AVALIADOR) {
                tipoEncaminhamento = TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.ENCAMINHAR_TFD_AVALIADOR;
            }
            verificarTipoEncaminhamento(tipoEncaminhamento);
        }
    }, [id, formRef]);

    const verificarTipoEncaminhamento = (tipoEncaminhamento) => {
        if (tipoEncaminhamento) {
            setCarregado(false);
            if (tipoEncaminhamento === TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.AUTORIZAR) {
                axios
                    .get(`${TFD_API_BASE_URL}/agendamento/${id}`, { headers: getHeaders() })
                    .then((response) => {
                        if (response.data) {
                            const { itens } = response.data.data;
                            const agendamento = itens[0];

                            if (agendamento.id_instituicao_executante) {
                                initialValues.instituicao = {
                                    id: agendamento.id_instituicao_executante,
                                    nome: agendamento.nome_instituicao_executante
                                };
                            }

                            if (agendamento.id_funcionario_executante) {
                                initialValues.funcionario = {
                                    id: agendamento.id_funcionario_executante,
                                    nome: agendamento.nome_funcionario_executante
                                };
                            }

                            if (agendamento.preparo_procedimento) {
                                initialValues.procedimento = agendamento.preparo_procedimento;
                            }

                            if (agendamento.data_hora_atendimento) {
                                initialValues.data_hora = new Date(agendamento.data_hora_atendimento);
                            }


                            if (agendamento.periodo_atendimento) {
                                initialValues.periodoAtendimento = agendamento.periodo_atendimento;
                            }

                            if (agendamento.parecer_inconclusivo_regulador) {
                                initialValues.parecer_inconclusivo_regulador = agendamento.parecer_inconclusivo_regulador;
                            }

                            delete initialValues.regulador;
                            delete initialValues.avaliador;
                            initialValues.movimentacao = '';

                            initialValues.tipoEncaminhamento = tipoEncaminhamento;

                            if (formRef.current) {
                                formRef.current.resetForm(initialValues);
                            } else {
                                setInitialValues(initialValues);
                            }
                            setCarregado(true);
                        }
                    })
                    .catch(err => {
                        setTituloAlerta(strings.erro);
                        setMensagemAlerta(strings.erroRecuperacaoAgendamento);
                        setTipoAlerta('error');
                        setOpcoesAlerta([
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setMostrarAlerta(false);
                                }
                            }
                        ]);
                        setOnCloseAlerta(() => () => {
                            setMostrarAlerta(false);
                        });
                        setMostrarAlerta(true);
                    });

            }

            if (tipoEncaminhamento === TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.ENCAMINHAR_TFD_REGULADOR) {
                axios
                    .get(`${TFD_API_BASE_URL}/encaminhar-regulador/${id}`, { headers: getHeaders() })
                    .then((response) => {
                        if (response.data) {
                            const retorno = response.data.data;

                            if (retorno?.length) {
                                initialValues.regulador = retorno[0];
                            }
                            initialValues.movimentacao = '';
                            initialValues.tipoEncaminhamento = tipoEncaminhamento;
                            delete initialValues.instituicao;
                            delete initialValues.data_hora;
                            delete initialValues.periodoAtendimento;
                            delete initialValues.procedimento;

                            if (formRef.current) {
                                formRef.current.resetForm(initialValues);
                            } else {
                                setInitialValues(initialValues);
                            }
                            setCarregado(true);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        setTituloAlerta(strings.erro);
                        setMensagemAlerta(strings.erroRecuperacaoRegulacao);
                        setTipoAlerta('error');
                        setOpcoesAlerta([
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setMostrarAlerta(false);
                                }
                            }
                        ]);
                        setOnCloseAlerta(() => () => {
                            setMostrarAlerta(false);
                        });
                        setMostrarAlerta(true);
                    });
            }

            if (tipoEncaminhamento === TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.ENCAMINHAR_TFD_AVALIADOR) {
                axios
                    .get(`${TFD_API_BASE_URL}/encaminhar-avaliador/${id}`, { headers: getHeaders() })
                    .then((response) => {
                        if (response.data) {
                            const retorno = response.data.data;

                            if (retorno?.length) {
                                initialValues.avaliador = retorno[0];
                            }
                            initialValues.movimentacao = '';
                            initialValues.tipoEncaminhamento = tipoEncaminhamento;
                            initialValues.parecer_inconclusivo_regulador = TIPO_ENCAMINHAMENTO_REGULADOR_CONSTANTES.INCONCLUSIVO_PARECER_AVALIADOR;
                            delete initialValues.regulador;
                            delete initialValues.instituicao;
                            delete initialValues.data_hora;
                            delete initialValues.periodoAtendimento;
                            delete initialValues.procedimento;
                            if (formRef.current) {
                                formRef.current.resetForm(initialValues);
                            } else {
                                setInitialValues(initialValues);
                            }
                            setCarregado(true);
                        }
                    })
                    .catch(err => {
                        setTituloAlerta(strings.erro);
                        setMensagemAlerta(strings.erroRecuperacaoAvaliacao);
                        setTipoAlerta('error');
                        setOpcoesAlerta([
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setMostrarAlerta(false);
                                }
                            }
                        ]);
                        setOnCloseAlerta(() => () => {
                            setMostrarAlerta(false);
                        });
                        setMostrarAlerta(true);
                    });
            }
        }
    }

    const closeAlerta = (resetForm) => {
        setMostrarAlerta(false);
        resetForm({ values: initialValues });
        callbackSucesso();
    }

    return (
        <>
            {
                carregado ?
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(dadosSubmit, { setSubmitting, resetForm }) => {
                            setSubmitting(true);

                            dadosSubmit.movimentacao = dadosSubmit.movimentacao.trim();
                            dadosSubmit.id = id;
                            let url = `${TFD_API_BASE_URL}/autorizar`;

                            //Caso seja encaminhamento ao regulador
                            if (dadosSubmit.tipoEncaminhamento == TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.ENCAMINHAR_TFD_REGULADOR) {
                                url = `${TFD_API_BASE_URL}/encaminhar-regulador`;
                            }

                            //Caso seja encaminhamento ao avaliador
                            if (dadosSubmit.tipoEncaminhamento == TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.ENCAMINHAR_TFD_AVALIADOR) {
                                url = `${TFD_API_BASE_URL}/encaminhar-avaliador`;
                            }

                            axios.post(url, estruturarFiltros(dadosSubmit), { headers: getHeaders() })
                                .then((response) => {
                                    const { data } = response;
                                    if (response.status === HttpStatus.OK) {
                                        setTituloAlerta(strings.sucesso);
                                        setMensagemAlerta(data.message ? data.message : strings.sucessoOperacao);
                                        setTipoAlerta('success');
                                        setOpcoesAlerta([
                                            {
                                                title: strings.ok,
                                                onClick: () => {
                                                    closeAlerta(resetForm);
                                                }
                                            }
                                        ]);
                                        setOnCloseAlerta(() => () => {
                                            closeAlerta(resetForm);
                                        });
                                        setMostrarAlerta(true);
                                    }
                                })
                                .catch(err => {
                                    const { response } = err;
                                    let mensagem = strings.mensagemErroGeral;

                                    if (response) {
                                        if (response.status === HttpStatus.BAD_REQUEST) {
                                            const { data } = response;
                                            let arrMensagem = [];
                                            data.errors.forEach(error => {
                                                arrMensagem.push(`- ${error.message}`);
                                            });
                                            if (arrMensagem) {
                                                mensagem = arrMensagem.join('\n');
                                            }
                                        }
                                    }

                                    setTituloAlerta(strings.erro);
                                    setMensagemAlerta(mensagem);
                                    setTipoAlerta('error');
                                    setOpcoesAlerta([
                                        {
                                            title: strings.ok,
                                            onClick: () => {
                                                setMostrarAlerta(false);
                                            }
                                        }
                                    ]);
                                    setOnCloseAlerta(() => () => {
                                        setMostrarAlerta(false);
                                    });
                                    setMostrarAlerta(true);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit,
                                resetForm,
                                values,
                                ...formikEvents
                            }) => {
                                return (
                                    <form onSubmit={handleSubmit} noValidate>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                <Field name={TIPO_ENCAMINHAMENTO}>
                                                    {({
                                                        field,
                                                        meta,
                                                    }) => (
                                                        <div>
                                                            <SttHeading required variant="h4" color="primary">{strings.tipoEncaminhamento}</SttHeading>
                                                            <br />
                                                            <SttFormControl
                                                                required
                                                                error={meta.touched && meta.error ? true : false}
                                                                variant="outlined"
                                                                onChange={(e, item) => {
                                                                    verificarTipoEncaminhamento(e.target.value);
                                                                }}
                                                            >
                                                                <SttRadioGroup row>

                                                                    {
                                                                        <SttFormControlLabel
                                                                            {...field}
                                                                            control={
                                                                                <SttRadioButton
                                                                                    type="radio"
                                                                                    value={TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.AUTORIZAR}
                                                                                    color="primary"
                                                                                    checked={field.value === TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.AUTORIZAR}
                                                                                />
                                                                            }
                                                                            label={strings.autorizar}
                                                                        />
                                                                    }

                                                                    <SttFormControlLabel
                                                                        {...field}
                                                                        control={
                                                                            <SttRadioButton
                                                                                type="radio"
                                                                                value={TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.ENCAMINHAR_TFD_REGULADOR}
                                                                                color="primary"
                                                                                checked={field.value === TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.ENCAMINHAR_TFD_REGULADOR}
                                                                            />
                                                                        }
                                                                        label={strings.encaminharRegulador}
                                                                    />

                                                                    {
                                                                        (values.parecer_inconclusivo_regulador == TIPO_ENCAMINHAMENTO_REGULADOR_CONSTANTES.INCONCLUSIVO_PARECER_AVALIADOR) &&
                                                                        <SttFormControlLabel
                                                                            {...field}
                                                                            control={
                                                                                <SttRadioButton
                                                                                    type="radio"
                                                                                    value={TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.ENCAMINHAR_TFD_AVALIADOR}
                                                                                    color="primary"
                                                                                    checked={field.value === TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.ENCAMINHAR_TFD_AVALIADOR}
                                                                                />
                                                                            }
                                                                            label={strings.encaminharAvaliador}
                                                                        />
                                                                    }
                                                                </SttRadioGroup>
                                                                {
                                                                    meta.touched && meta.error &&
                                                                    <SttFormHelperText error>
                                                                        {meta.error}
                                                                    </SttFormHelperText>
                                                                }
                                                            </SttFormControl>
                                                        </div>
                                                    )}
                                                </Field>
                                            </SttGrid>

                                            {
                                                (values.tipoEncaminhamento == TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.ENCAMINHAR_TFD_REGULADOR) &&
                                                <SttGrid item xs={12} sm={6}>
                                                    <SttHeading required variant="h4" color="primary">{strings.funcionarioRegulador}</SttHeading>
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <SttFuncionario
                                                            perfis={[PERFIS.TFD_REGULADOR]}
                                                            fieldName={'regulador'}
                                                            headers={getHeaders()}
                                                            strings={strings}
                                                            obrigatorio={true}
                                                            formExterno={{
                                                                funcionario: values.regulador,
                                                                ...formikEvents
                                                            }}
                                                        />
                                                    </Suspense>
                                                </SttGrid>
                                            }

                                            {
                                                (values.tipoEncaminhamento == TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.ENCAMINHAR_TFD_AVALIADOR) &&
                                                <SttGrid item xs={12} sm={6}>
                                                    <SttHeading required variant="h4" color="primary">{strings.funcionarioAvaliador}</SttHeading>
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <SttFuncionario
                                                            perfis={[PERFIS.TFD_AVALIADOR]}
                                                            fieldName={'avaliador'}
                                                            headers={getHeaders()}
                                                            strings={strings}
                                                            obrigatorio={true}
                                                            formExterno={{
                                                                funcionario: values.avaliador,
                                                                ...formikEvents
                                                            }}
                                                        />
                                                    </Suspense>
                                                </SttGrid>
                                            }

                                            {
                                                (values.tipoEncaminhamento == TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES.AUTORIZAR) &&
                                                <>
                                                    <SttGrid item xs={12} sm={6}>
                                                        <SttHeading required variant="h4" color="primary">{strings.instituicaoExecutante}</SttHeading>
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <SttInstituicao
                                                                fieldName={'instituicao'}
                                                                headers={getHeaders()}
                                                                strings={strings}
                                                                obrigatorio={true}
                                                                formExterno={{
                                                                    instituicao: values.instituicao,
                                                                    ...formikEvents
                                                                }}
                                                            />
                                                        </Suspense>
                                                    </SttGrid>

                                                    <div style={{ width: '100%' }}></div>
                                                    <SttGrid item xs={12} sm={6}>
                                                        <SttHeading required variant="h4" color="primary">{strings.dataAtendimento}</SttHeading>
                                                        <FastField name={'data_hora'}>
                                                            {({
                                                                field: { name, value, },
                                                                form: { setFieldValue, setFieldTouched },
                                                                meta
                                                            }) => {
                                                                return (
                                                                    <SttDatePicker
                                                                        label={strings.dataAtendimento}
                                                                        inputprops={{
                                                                            name: name
                                                                        }}
                                                                        required
                                                                        error={meta.touched && meta.error ? true : false}
                                                                        value={value}
                                                                        onBlur={() => {
                                                                            setFieldTouched('data_hora', true);
                                                                        }}
                                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                        onChange={date => setFieldValue('data_hora', date, true)}
                                                                        onClose={() => setFieldTouched('data_hora', true)}
                                                                    />
                                                                );
                                                            }}
                                                        </FastField>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6}>
                                                        <Field name={PERIODO_ATENDIMENTO}>
                                                            {({
                                                                field,
                                                                form,
                                                                meta,
                                                            }) => (
                                                                <div>
                                                                    <SttHeading variant="h4" color="primary">{strings.periodoAtendimento}</SttHeading>
                                                                    <br />
                                                                    <SttFormControl
                                                                        required
                                                                        error={meta.touched && meta.error ? true : false}
                                                                        variant="outlined"
                                                                    >
                                                                        <SttRadioGroup row>
                                                                            <SttFormControlLabel
                                                                                {...field}
                                                                                control={
                                                                                    <SttRadioButton
                                                                                        type="radio"
                                                                                        value={PERIODO_ATENDIMENTO_CONSTANTES.MATUTINO}
                                                                                        color="primary"
                                                                                        checked={field.value === PERIODO_ATENDIMENTO_CONSTANTES.MATUTINO}
                                                                                    />
                                                                                }
                                                                                label={strings.matutino}
                                                                            />
                                                                            <SttFormControlLabel
                                                                                {...field}
                                                                                control={
                                                                                    <SttRadioButton
                                                                                        type="radio"
                                                                                        value={PERIODO_ATENDIMENTO_CONSTANTES.VESPERTINO}
                                                                                        color="primary"
                                                                                        checked={field.value === PERIODO_ATENDIMENTO_CONSTANTES.VESPERTINO}
                                                                                    />
                                                                                }
                                                                                label={strings.vespertino}
                                                                            />
                                                                            <SttFormControlLabel
                                                                                {...field}
                                                                                control={
                                                                                    <SttRadioButton
                                                                                        type="radio"
                                                                                        value={PERIODO_ATENDIMENTO_CONSTANTES.INTERNACAO}
                                                                                        color="primary"
                                                                                        checked={field.value === PERIODO_ATENDIMENTO_CONSTANTES.INTERNACAO}
                                                                                    />
                                                                                }
                                                                                label={strings.internacao}
                                                                            />
                                                                        </SttRadioGroup>
                                                                        {
                                                                            meta.touched && meta.error &&
                                                                            <SttFormHelperText error>
                                                                                {meta.error}
                                                                            </SttFormHelperText>
                                                                        }
                                                                    </SttFormControl>
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12}>
                                                        <FastField name={PROCEDIMENTO}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttInput
                                                                    multiline
                                                                    rows={5}
                                                                    {...field}
                                                                    label={strings.preparoProcedimento}
                                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>
                                                </>
                                            }

                                            {
                                                values.tipoEncaminhamento &&
                                                <SttGrid item xs={12}>
                                                    <FastField name={MOVIMENTACAO}>
                                                        {({
                                                            field,
                                                            meta
                                                        }) => (
                                                            <SttInput
                                                                multiline
                                                                rows={5}
                                                                {...field}
                                                                label={strings.movimentacao}
                                                                inputProps={{ style: { textTransform: "uppercase" } }}
                                                                error={meta.touched && meta.error ? true : false}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                            }
                                            <SttLoading
                                                open={isSubmitting}
                                                text={strings.salvandoMensagemEspera}
                                            />
                                        </SttGrid>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12}>
                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    nomarginleft="true"
                                                    disabled={isSubmitting}
                                                >
                                                    {strings.confirmar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        resetForm({ values: initialValues });
                                                    }}
                                                >
                                                    {strings.limpar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        resetForm({ values: initialValues });
                                                        callback();
                                                    }}
                                                >
                                                    {strings.fechar}
                                                </SttButton>
                                            </SttGrid>
                                        </SttGrid>
                                    </form>
                                )
                            }
                        }
                    </Formik> :
                    (
                        <div className={classes.carregando}>
                            <SttCircularProgress color="primary" />
                        </div>
                    )
            }
        </>
    );
}

export default OperacaoEncaminhamentoEstadual;