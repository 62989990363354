import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import SttHeading from '@stt-componentes/core/dist/lib/SttTypography/SttHeading';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { SttTranslateHook } from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    }
}));

const Logout = (...props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ExitToAppIcon color="primary" fontSize="large" />
            <SttHeading variant="h1" color="primary" align="center">
                {strings.logout}
            </SttHeading>
        </div>
    );
};

export default Logout;
