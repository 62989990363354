import React, { useRef, Suspense, lazy, useContext } from "react";
import {
    SttButton,
    SttLoading,
    SttGrid,
    SttInput,
    SttRadioButton,
    SttRadioGroup,
    SttFormControlLabel,
    SttFormHelperText,
    SttFormControl,
    SttHeading,
    SttTranslateHook,
    SttCircularProgress
} from '@stt-componentes/core';
import {
    Formik,
    FastField
} from 'formik';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../../request';
import {
    TIPO_ENCAMINHAMENTO,
    MOVIMENTACAO,
    REGULADOR
} from "./fieldNames";
import { PERFIS, TIPO_ENCAMINHAMENTO_REGULADOR_CONSTANTES } from "../../../common/Constants";

const SttFuncionario = lazy(() => import('@stt-componentes/funcionario'));

const validationSchema = (strings) => {
    return yup.object().shape({
        [TIPO_ENCAMINHAMENTO]: yup
            .string()
            .required(strings.campoObrigatorio),
        [REGULADOR]: yup.object().when(TIPO_ENCAMINHAMENTO, {
            is: (val) => val === TIPO_ENCAMINHAMENTO_REGULADOR_CONSTANTES.ENCAMINHAR_TFD_REGULADOR,
            then: yup.object().shape({
                id: yup.number()
            }).required(strings.campoObrigatorio)
        }).nullable(),
    });
}

const OperacaoRegular = ({ callback, callbackSucesso, id, tipoProcesso, alerta, ...props }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const formRef = useRef();
    const TFD_API_BASE_URL = global.gConfig.url_base_tfd;

    const {
        setMostrarAlerta,
        setTituloAlerta,
        setMensagemAlerta,
        setTipoAlerta,
        setOpcoesAlerta,
        setOnCloseAlerta
    } = alerta;

    const initialValues = {
        tipoEncaminhamento: '',
        instituicao: null,
        funcionario: null,
        regulador: null,
        movimentacao: '',
        data_hora: null
    };

    const estruturarFiltros = (data) => {
        let filtros = {
            ...data
        };

        if (data.regulador) {
            filtros.regulador = data.regulador.id;
        }

        return filtros;
    };

    const closeAlerta = (resetForm) => {
        setMostrarAlerta(false);
        resetForm({ values: initialValues });
        callbackSucesso();
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(dataPost, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                dataPost.movimentacao = dataPost.movimentacao.trim().toUpperCase();
                dataPost.id = id;

                axios.post(`${TFD_API_BASE_URL}/regular`, estruturarFiltros(dataPost), { headers: getHeaders() })
                    .then((response) => {
                        const { data } = response;
                        if (response.status === HttpStatus.OK) {
                            setTituloAlerta(strings.sucesso);
                            setMensagemAlerta(data.message ? data.message : strings.sucessoOperacao);
                            setTipoAlerta('success');
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        closeAlerta(resetForm);
                                    }
                                }
                            ]);
                            setOnCloseAlerta(() => () => {
                                closeAlerta(resetForm);
                            });
                            setMostrarAlerta(true);
                        }
                    })
                    .catch(err => {
                        const { response } = err;
                        let mensagem = strings.mensagemErroGeral;

                        if (response) {
                            if (response.status === HttpStatus.BAD_REQUEST) {
                                const { data } = response;
                                let arrMensagem = [];
                                data.errors.forEach(error => {
                                    arrMensagem.push(`- ${error.message}`);
                                });
                                if (arrMensagem) {
                                    mensagem = arrMensagem.join('\n');
                                }
                            }
                        }

                        setTituloAlerta(strings.erro);
                        setMensagemAlerta(mensagem);
                        setTipoAlerta('error');
                        setOpcoesAlerta([
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setMostrarAlerta(false);
                                }
                            }
                        ]);
                        setOnCloseAlerta(() => () => {
                            setMostrarAlerta(false);
                        });
                        setMostrarAlerta(true);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {
                ({
                    isSubmitting,
                    handleSubmit,
                    resetForm,
                    values,
                    ...formikEvents
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FastField name={TIPO_ENCAMINHAMENTO}>
                                        {({
                                            field,
                                            meta,
                                        }) => (
                                            <div>
                                                <SttHeading required variant="h4" color="primary">{strings.tipoEncaminhamento}</SttHeading>
                                                <br />
                                                <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined">
                                                    <SttRadioGroup row>
                                                        <SttFormControlLabel
                                                            {...field}
                                                            control={
                                                                <SttRadioButton
                                                                    type="radio"
                                                                    value={TIPO_ENCAMINHAMENTO_REGULADOR_CONSTANTES.AUTORIZAR}
                                                                    color="primary"
                                                                    checked={field.value === TIPO_ENCAMINHAMENTO_REGULADOR_CONSTANTES.AUTORIZAR}
                                                                />
                                                            }
                                                            label={strings.autorizar}
                                                        />

                                                        <SttFormControlLabel
                                                            {...field}
                                                            control={
                                                                <SttRadioButton
                                                                    type="radio"
                                                                    value={TIPO_ENCAMINHAMENTO_REGULADOR_CONSTANTES.DEVOLVER}
                                                                    color="primary"
                                                                    checked={field.value === TIPO_ENCAMINHAMENTO_REGULADOR_CONSTANTES.DEVOLVER}
                                                                />
                                                            }
                                                            label={strings.devolver}
                                                        />

                                                        <SttFormControlLabel
                                                            {...field}
                                                            control={
                                                                <SttRadioButton
                                                                    type="radio"
                                                                    value={TIPO_ENCAMINHAMENTO_REGULADOR_CONSTANTES.INCONCLUSIVO_PARECER_AVALIADOR}
                                                                    color="primary"
                                                                    checked={field.value === TIPO_ENCAMINHAMENTO_REGULADOR_CONSTANTES.INCONCLUSIVO_PARECER_AVALIADOR}
                                                                />
                                                            }
                                                            label={strings.inconclusivo}
                                                        />

                                                        <SttFormControlLabel
                                                            {...field}
                                                            control={
                                                                <SttRadioButton
                                                                    type="radio"
                                                                    value={TIPO_ENCAMINHAMENTO_REGULADOR_CONSTANTES.NEGAR}
                                                                    color="primary"
                                                                    checked={field.value === TIPO_ENCAMINHAMENTO_REGULADOR_CONSTANTES.NEGAR}
                                                                />
                                                            }
                                                            label={strings.negar}
                                                        />
                                                    </SttRadioGroup>
                                                    {
                                                        meta.touched && meta.error &&
                                                        <SttFormHelperText error>
                                                            {meta.error}
                                                        </SttFormHelperText>
                                                    }
                                                </SttFormControl>
                                            </div>
                                        )}
                                    </FastField>
                                </SttGrid>

                                {
                                    (values.tipoEncaminhamento == TIPO_ENCAMINHAMENTO_REGULADOR_CONSTANTES.ENCAMINHAR_TFD_REGULADOR) &&
                                    <SttGrid item xs={12} sm={6}>
                                        <SttHeading required variant="h4" color="primary">{strings.funcionarioRegulador}</SttHeading>
                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                            <SttFuncionario
                                                perfis={[PERFIS.TFD_REGULADOR]}
                                                fieldName={REGULADOR}
                                                headers={getHeaders()}
                                                strings={strings}
                                                formExterno={{
                                                    funcionario: values.regulador,
                                                    ...formikEvents
                                                }}
                                            />
                                        </Suspense>
                                    </SttGrid>
                                }

                                {
                                    values.tipoEncaminhamento &&
                                    <SttGrid item xs={12}>
                                        <FastField name={MOVIMENTACAO}>
                                            {({
                                                field,
                                                meta
                                            }) => (
                                                <SttInput
                                                    multiline
                                                    rows={5}
                                                    {...field}
                                                    label={strings.movimentacao}
                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                    error={meta.touched && meta.error ? true : false}
                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                />
                                            )}
                                        </FastField>
                                    </SttGrid>
                                }
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.salvandoMensagemEspera}
                                />
                            </SttGrid>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        nomarginleft="true"
                                        disabled={isSubmitting}
                                    >
                                        {strings.confirmar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                        }}
                                    >
                                        {strings.limpar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                            callback();
                                        }}
                                    >
                                        {strings.fechar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        </form>
                    )
                }
            }
        </Formik>
    );
}

export default OperacaoRegular;