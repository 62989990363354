module.exports = {
    abrirModulo: 'Abrir exame no módulo de',
    adicionarAnexo: 'Adicionar anexo',
    adicionarSecao: 'Adicionar seção',
    agendados: 'Agendados',
    alteracaoLaudoAtalho: 'Alterar o Atalho',
    alteracaoModeloLaudo: 'Alteração de modelo de laudo',
    anexoInserido: 'Anexo registrado',
    assinando: 'Assinando',
    assinarDepois: 'Assinar depois',
    assinarEsteEProximosLaudos: 'Assinar este e os próximos laudos',
    assinarEsteLaudoApenas: 'Assinar este laudo apenas',
    assinaturaDigital: 'Assinatura digital',
    assinaturaNecessaria: 'A assinatuda digital dos laudo é obrigatória. O que deseja fazer?',
    atalho: 'Atalho',
    atalhoAtivar: 'Ativar',
    atalhoAtivo: 'Ativo',
    atalhoAtivoDesativoComSucesso: 'Atalho "$" ! com sucesso.',
    atalhoInativar: 'Inativar',
    atalhoInativo: 'Inativo',
    atalhoLaudo: 'Atalhos de Laudo',
    baixandoExame: 'Baixando exame',
    cadastroLaudoAtalho: 'Atalhos para laudo',
    cadastroModeloLaudo: 'Cadastro de modelo de laudo',
    campoNaoPodeSerVazio: 'O Campo não deve começar com espaço',
    carregandoExame: 'Carregando o exame',
    carregandoHistoricoExames: 'Carregando histórico do exame',
    categoria: 'Categoria',
    comImagem: 'Com imagem',
    comLaudo: 'Com laudo',
    complementoLaudo: 'Complemento de laudo',
    confirmacaoAtivarDesativarAtalho: 'Deseja realmente ! o Atalho: "$".',
    confirmacaoVinculoDados: 'Você confirma o vínculo dessas informações ao exame atual?',
    confirmaRemocaoAnexo: 'Você confirma a remoção do anexo?',
    confirmaRemocaoModeloLaudo: 'Você confirma a remoção do modelo de laudo?',
    confirmaRemocaoSecaoModeloLaudo: 'Você confirma a remoção da seção?',
    confirmarEscolhaNecessidadeInformarCpfPaciente: 'Você confirma esta escolha? O sistema irá vincular o exame a este paciente e prosseguir com a impressão do protocolo.',
    confirmarEscolhaNecessidadeInformarCpfPaciente: 'Você confirma esta escolha? O sistema irá vincular o exame a este paciente e prosseguir com a impressão do protocolo.',
    contraRefercia: 'Contra-referência',
    copiandoLaudo: 'Copiando laudo',
    copiarLaudo: 'Copiar laudo',
    dataAcao: 'Data da Ação',
    dataHoraAtendimento: 'Data/hora do atendimento',
    dataLaudo: 'Data do laudo',
    dataUltimaAcao: 'Data da última ação',
    descricaoDoAtalho: 'Descrição do atalho',
    descricaoExame: 'Descrição do exame',
    descricaoNecessidadeInformarCpfPaciente: 'Este paciente não possui CPF cadastrado no sistema. Por favor, informe o CPF, clique em "Buscar" e selecione um paciente da listagem para continuar com a impressão do protocolo',
    descricaoNecessidadeInformarCpfPaciente: 'Este paciente não possui CPF cadastrado no sistema. Por favor, informe o CPF, clique em "Buscar" e selecione um paciente da listagem para continuar com a impressão do protocolo',
    descricaoNecessidadeInformarDataNascimentoPaciente: 'Este paciente não possui data de nascimento cadastrada no sistema. Por favor, informe a data de nascimento e clique em Salvar para finalizar o complemento deste cadastro.',
    desejaAdicionarOuSubstituir: 'Adicionar ao modelo atual ou substituir o modelo atual?',
    devolverListaGeral: 'Devolver para lista geral',
    direcionado: 'Direcionado',
    direcionados: 'Direcionado',
    direcionadosAMim: 'Direcionados a mim',
    encaminhamentoRevisao: 'Encaminhamento do laudo para revisão',
    erroAlterarAtalhoAtivo: 'Erro ao alternar ativo do atalho. Por favor, tente novamente.',
    erroCarregarAtalhos: 'Não foi possível carregar os atalhos.',
    erroCarregarExame: 'Não foi possível carregar o exame',
    erroCarregarMedicosExecutores: 'Não foi possível carregar os médicos executores. Não será possível encaminhar o laudo para complemento.',
    erroCarregarModelosInstituicao: 'Não foi possível carregar os modelos de laudo da instituição.',
    erroCarregarModelosUsuario: 'Não foi possível carregar os modelos de laudo do usuário.',
    erroCarregarParametrosAssinaturaDigital: 'Não foi possível carregar os parâmetros de configuração da assinatura digital.',
    erroDownloadWeasis: 'Download do Weasis indisponível. Tente novamente mais tarde.',
    exameAtual: 'Exame atual',
    exameDevolvidoListaGeral: 'Exame devolvido para lista geral',
    examesAgendados: 'Agendados',
    examesComImagens: 'Com imagens',
    exameSelecionado: 'Exame selecionado',
    exameSemImagens: 'Este exame ainda não possui imagens',
    exameSemPermissaoGaleria: 'A visualização de imagens deste exame não é permitida.',
    examesInvalidos: 'Inválidos',
    examesPacs: 'PACS',
    examesSelecionados: 'Exames selecionados',
    examesValidos: 'Válidos',
    exameValidado: 'Exame validado',
    formularioSolicitacaoMed: 'Formulário de solicitação de medicamentos',
    historicoPaciente: 'Histórico do paciente',
    identificacaoPaciente: 'Identificação do paciente',
    identificador: 'Identificador',
    indicacao: 'Indicação',
    indicacaoClinicaRegistrada: 'Indicação clínica registrada',
    inicio: 'Início',
    integracaoExames: 'Integrações de Exames',
    invalido: 'Inválido',
    laudadoPorMim: 'Laudados por mim',
    laudoAguardandoAssinatura: 'Laudo aguardando assinatura',
    laudoAssinado: 'Laudo assinado',
    laudoRegistrado: 'Laudo registrado',
    laudos: 'Laudos',
    laudosAssinados: 'laudos assinados',
    laudosGraves: 'Laudos graves',
    laudoTemporario: 'Laudo temporário',
    medicoExecutor: 'Médico executor',
    mensagem: 'Mensagem',
    mensagemAlteracaoAtalho: 'Deseja alterar o Atalho: "$".',
    modeloLaudo: 'Modelos de laudo',
    modelosLaudoInstituicao: 'Modelo de laudo da instituição',
    modelosLaudoUsuario: 'Modelo de laudo do usuário',
    naoPossuoCpfPaciente: 'Não possuo os dados desse paciente',
    necessidadeInformarCpfPaciente: 'Informe o CPF do paciente',
    necessidadeInformarCpfPaciente: 'Informe o CPF do paciente',
    necessidadeInformarDataNascimentoPaciente: 'Informe a data de nascimento do paciente',
    nomeDoAtalho: 'Nome',
    novaRede: 'Nova rede',
    numero_exame: 'Número do exame',
    numeroExame: 'Número do Exame',
    numeroLaudo: 'Número do laudo',
    ohif: 'OHIF',
    opcoes: 'Opções',
    oQueDesejaFazer: 'O que deseja fazer?',
    outrasInformacoes: 'Outras informações',
    oviyam: 'Oviyam',
    pacienteNaoEncontradoInformarCpf: 'Nenhum paciente foi encontrado com este CPF no sistema ou na base de dados do CADSUS. Você deseja vincular este CPF mesmo assim?',
    pacienteNaoEncontradoInformarCpf: 'Nenhum paciente foi encontrado com este CPF no sistema ou na base de dados do CADSUS. Você deseja vincular este CPF mesmo assim?',
    pacs: 'PACS',
    pdfIndisponivel: 'A geração do PDF deste exame apresentou erros e não está disponível. Tente novamente. Se o erro persistir, entre em contato com o suporte.',
    pesquisaAvancada: 'Pesquisa avançada',
    pesquisar: 'Pesquisar',
    pesquisaSimples: 'Pesquisa simples',
    placeholderSepareTermos: 'Separe os termos com espaço',
    preparandoEditorTexto: 'Preparando o editor de texto',
    preparandoEstudo: 'Preparando estudo',
    priorizado: 'Priorizado',
    profissional: 'Profissional',
    protocoloMed: 'Protocolo de medicamento',
    qualquerExecutor: 'Qualquer médico executor da instituição',
    qualquerTermo: 'Qualquer um dos termos',
    realizacaoExame: 'Realização do exame',
    receitaMedica: 'Receita médica',
    redeAlterada: 'Rede alterada',
    removerSecao: 'Remover seção',
    responsavelEnvio: 'Responsável pelo envio',
    resumo: 'Visualizar resumo',
    resumoLaudo: 'Resumo do laudo',
    rotina: 'Rotina',
    secoesModelo: 'Seções do modelo',
    selecionado: 'selecionado',
    semImagensExibicao: 'Não há imagens disponíveis para exibição.',
    situacaoOrigem: 'Situação/Origem',
    somenteImagens: 'Somente exames com imagens',
    status: 'Status',
    substituir: 'Substituir',
    mensagemImprimirImagensExame: 'Você gostaria de imprimir também as imagens deste exame?',
    sucessoNecessidadeInformarCpfPaciente: 'O paciente foi vinculado com sucesso',
    sucessoNecessidadeInformarCpfPaciente: 'O paciente foi vinculado com sucesso',
    telediagnostico: 'Telediagnóstico',
    termosDescricao: 'Descrição do exame',
    textoAjudaAssinarDepois: 'Este e os próximos laudos serão adicionados às pendências e deverão ser assinados posteriormente.',
    textoAjudaAssinarEsteApenas: 'Apenas este laudo será assinado.',
    textoAjudaSessaoAssinatura: 'Será iniciada uma sessão de assinatura e os laudos serão assinados automaticamente a medida que forem emitidos.',
    textoSecao: 'Texto da seção',
    tipoBusca: 'Tipo de busca por termo',
    tituloPacs: 'Titulo do PACS',
    tituloSecao: 'Título da seção',
    tituloVisualizacao: 'Visualização de Histórico',
    todosTermos: 'Todos os termos',
    trocarRede: 'Trocar rede',
    ultrapassouTempoPrevisto: 'Ultrapassou o tempo previsto para laudo',
    urlPacs: 'URL do PACS',
    validado: 'Validado',
    valido: 'Válido',
    visualizacaoPreviaModeloLaudo: 'Visualização prévia do modelo',
    weasis: 'WEASIS'
};