import { Strings } from '@stt-utilitarios/core';
const {tfd} = Strings;

export const CONTATO = {
    CATEGORIA: {
        TELEFONE_RESIDENCIAL: 1,
        TELEFONE_CELULAR: 2,
        TELEFONE_COMERCIAL: 3,
        EMAIL: 4,
        FAX: 5,
        OUTRO: 6
    }
}

export const PERFIS = {
    TFD_VISUALIZADOR: 'tfdVisualizador',
    TFD_MUNICIPAL: 'tfdMunicipal',
    TFD_AGENDADOR: 'tfdAgendador',
    TFD_ESTADUAL: 'tfdEstadual',
    TFD_AVALIADOR: 'tfdAvaliador',
    TFD_REGULADOR: 'tfdRegulador',
    ADMIN_TELEMEDICINA: 'adminTelemedicina',
    ADMINISTRADOR: 'administrador'
}

export const TIPO_PROCESSO = {
    INTRAESTADUAL: 'A',
    INTERESTADUAL: 'E',
    JUDICIAL: 'J'
}

export const COMPROVANTE_COMPARECIMENTO = {
    VINCULO_COMPROVANTE_TFD: 'T',
    VINCULO_COMPROVANTE_PASSAGENS: 'P'
}

export const CARATER_ATENDIMENTO = {
    HOSPITALAR: 'H',
    AMBULATORIAL: 'A',
    IGNORAR: 'I'
}

export const CATEGORIA_ATENDIMENTO_HOSPITALAR = {
    IGNORAR: 'I',
    URGENCIA: 'U',
    ELETIVO: 'E'
}

export const SITUACAO_PACIENTE = {
    PRIMEIRO_ATENDIMENTO_FORA_DOMICILIO: 'P',
    EM_TRATAMENTO: 'T'
}

export const TIPO_USUARIO = {
    INTERNO: 'INTERNO',
    EXTERNO: 'EXTERNO'
}

export const TIPO_SUBMIT = {
    RASCUNHO: 'RASCUNHO',
    ENCAMINHAMENTO: 'ENCAMINHAMENTO',
    ALTERACAO: 'ALTERACAO'
}

export const TIPO_OPERACAO = {
    DEVOLUCAO: 'devolucao',
    CANCELAMENTO: 'cancelamento',
    NEGACAO: 'negacao',
    ENCAMINHAMENTO: 'encaminhamento',
    ENCAMINHAMENTO_TFD_ESTADUAL: 'encaminhamento_tfd_estadual',
    AGUARDANDO_AGENDAMENTO_CNRAC: 'aguardando_agendamento_cnrac',
    INFORMAR_NUMERO_CNRAC: 'informar_numero_cnrac',
    REGULAR: 'regular',
    ANEXAR_COMPROVANTE_COMPARECIMENTO: 'anexar_comprovante_comparecimento',
    INFORMAR_PROCEDIMENTO_SOLICITADO: 'informar_procedimento_solicitado',
    CONFIRMAR_AGENDAMENTO: 'confirmar_agendamento',
    INATIVAR_COMPROVANTE_COMPARECIMENTO: 'inativar_comprovante_comparecimento',
    ARQUIVAR_PROCESSO: 'arquivar_processo'
}

//Encaminhamento
export const TIPO_ENCAMINHAMENTO_CONSTANTES = {
    AGENDAMENTO_LOCAL: 'A',
    ENCAMINHAR_TFD_ESTADUAL: 'E',
    ENCAMINHAR_SISREG: 'S'
}

//Tipos de agendamento
export const TIPO_AGENDAMENTO_CONSTANTES = {
    COM_AGENDAMENTO: 'C',
    SEM_AGENDAMENTO: 'S',
    AGENDAMENTO_CNRAC: 'A'
}

export const PERIODO_ATENDIMENTO_CONSTANTES = {
    MATUTINO: 'M',
    VESPERTINO: 'V',
    INTERNACAO: 'I',
    SEM_HORARIO: null
}

//Tipos de encaminhamento por parte do TFD Estadual
export const TIPO_ENCAMINHAMENTO_ESTADUAL_CONSTANTES = {
    AUTORIZAR: 'A',
    ENCAMINHAR_TFD_REGULADOR: 'E',
    ENCAMINHAR_TFD_AVALIADOR: 'EA'
}

//Tipos de encaminhamento por parte do TFD Regulador
export const TIPO_ENCAMINHAMENTO_REGULADOR_CONSTANTES = {
    AUTORIZAR: 'Z',
    ENCAMINHAR_TFD_REGULADOR: 'E',
    INCONCLUSIVO_PARECER_AVALIADOR: 'S',
    INCONCLUSIVO_ANEXAR_EXAMES: 'A',
    NEGAR: 'N',
    DEVOLVER: 'D'
}

//Comprovante de comparecimento
export const TIPO_VINCULO_COMPROVANTE_COMPARECIMENTO_CONSTANTES = {
    TFD: 'T',
    PASSAGEM: 'P'
};

export const situacao = {
    TODOS: -50,
    CANCELADO: -10,
    NEGADO: -5,
    RASCUNHO: 0,
    EM_ATUALIZACAO: 3,
    DEVOLVIDO: 4,
    AGUARDANDO_AGENDAMENTO_PELO_AGENDADOR: 5,
    SENDO_ATUALIZADO_PELO_AGENDADOR: 7,
    REGISTRADO_SISREG: 10,
    AGENDAMENTO_SOLICITADO: 11,
    SENDO_ATUALIZADO_TFD_ESTADUAL: 13,
    AGUARDANDO_AVALIACAO_TFD_ESTADUAL: 15,
    SENDO_ATUALIZADO_TFD_REGULADOR: 17,
    AGUARDANDO_AVALIACAO_TFD_REGULADOR: 20,
    SENDO_ATUALIZADO_NOVAMENTE_TFD_ESTADUAL: 23,
    AGUARDANDO_NOVAMENTE_AVALIACAO_TFD_ESTADUAL: 25,
    SENDO_ATUALIZADO_AVALIADOR: 27,
    AGUARDANDO_AVALIACAO_AVALIADOR: 30,
    AGUARDANDO_AGENDAMENTO: 32,
    SENDO_ATUALIZADO_CNRAC_TFD_ESTADUAL: 33,
    AGUARDANDO_ENVIO_CNRAC: 35,
    SENDO_ATUALIZADO_AGENDAMENTO_TFD_ESTADUAL: 37,
    AGUARDANDO_AGENDAMENTO_TFD_ESTADUAL: 40,
    AUTORIZADO: 50,
    ARQUIVADO: 60
}

export const SITUACAO_PROCESSO_FILTRO = [
    {
        id: situacao.TODOS,
        descricao: tfd.todos
    },
    {
        id: situacao.RASCUNHO,
        descricao: tfd.rascunho
    },
    {
        id: situacao.NEGADO,
        descricao: tfd.negado
    },
    {
        id: situacao.CANCELADO,
        descricao: tfd.cancelado
    },
    {
        id: situacao.DEVOLVIDO,
        descricao: tfd.devolvido
    },
    {
        id: situacao.AUTORIZADO,
        descricao: tfd.autorizado
    }, 
    {
        id: situacao.ARQUIVADO,
        descricao: tfd.arquivado
    },   
    {
        id: situacao.AGUARDANDO_AGENDAMENTO_PELO_AGENDADOR,
        descricao: tfd.aguardandoAgendamentoPeloAgendador
    },
    {
        id: situacao.REGISTRADO_SISREG,
        descricao: tfd.registradoSisreg
    },
    {
        id: situacao.AGENDAMENTO_SOLICITADO,
        descricao: tfd.agendamentoSolicitado
    },
    {
        id: situacao.AGUARDANDO_AVALIACAO_TFD_ESTADUAL,
        descricao: tfd.aguardandoAvaliacaoTfdEstadual
    },
    {
        id: situacao.AGUARDANDO_AVALIACAO_TFD_REGULADOR,
        descricao: tfd.aguardandoAvaliacaoTfdRegulador
    },
    {
        id: situacao.AGUARDANDO_NOVAMENTE_AVALIACAO_TFD_ESTADUAL,
        descricao: tfd.aguardandoNovamenteAvaliacaoTfdEstadual
    },
    {
        id: situacao.AGUARDANDO_AVALIACAO_AVALIADOR,
        descricao: tfd.aguardandoAvaliacaoTfdAvaliador
    },
    {
        id: situacao.AGUARDANDO_ENVIO_CNRAC,
        descricao: tfd.aguardandoEnvioCnrac
    },
    {
        id: situacao.AGUARDANDO_AGENDAMENTO,
        descricao: tfd.aguardandoAgendamento
    },
    {
        id: situacao.AGUARDANDO_AGENDAMENTO_TFD_ESTADUAL,
        descricao: tfd.aguardandoAgendamentoTfdEstadual
    }
];

export const SITUACAO_PROCESSO = {
    SITUACAO: { ...situacao },
    LABEL: {
        [situacao.CANCELADO]: tfd.cancelado,
        [situacao.RASCUNHO]: tfd.rascunho,
        [situacao.NEGADO]: tfd.negado,
        [situacao.EM_ATUALIZACAO]: tfd.emAtualizacao,
        [situacao.DEVOLVIDO]: tfd.devolvido,
        [situacao.AGUARDANDO_AGENDAMENTO_PELO_AGENDADOR]: tfd.aguardandoAgendamentoPeloAgendador,
        [situacao.SENDO_ATUALIZADO_PELO_AGENDADOR]: tfd.sendoAtualizacoPeloAgendador,
        [situacao.REGISTRADO_SISREG]: tfd.registradoSisreg,
        [situacao.AGENDAMENTO_SOLICITADO]: tfd.agendamentoSolicitado,
        [situacao.SENDO_ATUALIZADO_TFD_ESTADUAL]: tfd.sendoAtualizadoTfdEstadual,
        [situacao.AGUARDANDO_AVALIACAO_TFD_ESTADUAL]: tfd.aguardandoAvaliacaoTfdEstadual,
        [situacao.SENDO_ATUALIZADO_TFD_REGULADOR]: tfd.sendoAtualizadoTfdRegulador,
        [situacao.AGUARDANDO_AVALIACAO_TFD_REGULADOR]: tfd.aguardandoAvaliacaoTfdRegulador,
        [situacao.SENDO_ATUALIZADO_NOVAMENTE_TFD_ESTADUAL]: tfd.sendoAtualizadoNovamenteTfdEstadual,
        [situacao.AGUARDANDO_NOVAMENTE_AVALIACAO_TFD_ESTADUAL]: tfd.aguardandoNovamenteAvaliacaoTfdEstadual,
        [situacao.SENDO_ATUALIZADO_AVALIADOR]: tfd.sendoAtualizadoAvaliador,
        [situacao.AGUARDANDO_AVALIACAO_AVALIADOR]: tfd.aguardandoAvaliacaoTfdAvaliador,
        [situacao.SENDO_ATUALIZADO_CNRAC_TFD_ESTADUAL]: tfd.sendoAtualizadoCnracTfdEstadual,
        [situacao.AGUARDANDO_AGENDAMENTO]: tfd.aguardandoAgendamento,
        [situacao.AGUARDANDO_ENVIO_CNRAC]: tfd.aguardandoEnvioCnrac,
        [situacao.SENDO_ATUALIZADO_AGENDAMENTO_TFD_ESTADUAL]: tfd.sendoAtualizadoTfdEstadual,
        [situacao.AGUARDANDO_AGENDAMENTO_TFD_ESTADUAL]: tfd.aguardandoAgendamentoTfdEstadual,
        [situacao.AUTORIZADO]: tfd.autorizado,
        [situacao.ARQUIVADO]: tfd.arquivado
    },
    COR: {
        [situacao.RASCUNHO]: '#E3C5FC',
        [situacao.NEGADO]: '#fc6060',
        [situacao.CANCELADO]: '#999999',
        [situacao.DEVOLVIDO]: '#f7bd94',
        [situacao.AUTORIZADO]: '#8AC58B',
        [situacao.ARQUIVADO]: '#999999',
        [situacao.EM_ATUALIZACAO]: '#bfe32b',
        [situacao.AGUARDANDO_AGENDAMENTO_PELO_AGENDADOR]: '#34bfb9',
        [situacao.SENDO_ATUALIZADO_PELO_AGENDADOR]: '#9ceeea',
        [situacao.REGISTRADO_SISREG]: '#cf91c8',
        [situacao.AGENDAMENTO_SOLICITADO]: '#ff7f98',
        [situacao.SENDO_ATUALIZADO_TFD_ESTADUAL]: '#edefb7',
        [situacao.AGUARDANDO_AVALIACAO_TFD_ESTADUAL]: '#b1b551',
        [situacao.SENDO_ATUALIZADO_TFD_REGULADOR]: '#4b9df5',
        [situacao.AGUARDANDO_AVALIACAO_TFD_REGULADOR]: '#94afcc',
        [situacao.SENDO_ATUALIZADO_NOVAMENTE_TFD_ESTADUAL]: '#ea820d', 
        [situacao.AGUARDANDO_NOVAMENTE_AVALIACAO_TFD_ESTADUAL]: '#c97635',
        [situacao.SENDO_ATUALIZADO_AVALIADOR]: '#5eb250',
        [situacao.AGUARDANDO_AVALIACAO_AVALIADOR]: '#9dc097',
        [situacao.SENDO_ATUALIZADO_CNRAC_TFD_ESTADUAL]: '#246e18',
        [situacao.AGUARDANDO_ENVIO_CNRAC]: '#a396cd',
        [situacao.AGUARDANDO_AGENDAMENTO]: '#6fa2b8',
        [situacao.AGUARDANDO_AGENDAMENTO_TFD_ESTADUAL]: '#9564ff',
        [situacao.SENDO_ATUALIZADO_AGENDAMENTO_TFD_ESTADUAL]: '#7d64ca'
    }
}

export const GRUPOS_SANGUINEOS = ['A', 'B', 'AB', 'O'];
export const FATORES_RH = ['+', "-"];

export const mensagemAlertaComecoSolicitacao = tfd.mensagemAberturaProcesso;

export const PERMISSOES = {
    'TFD': 'TFD_TFD',
    'PESQUISAR': 'TFD_PESQUISAR',
    'VISUALIZAR': 'TFD_VISUALIZAR',
    'CRIAR': 'TFD_CRIAR',
    'CANCELAR': 'TFD_CANCELAR',
    'IMPRIMIR': 'TFD_IMPRIMIR',
    'SOLICITAR_TRANSPORTE': 'TFD_SOLICITAR_TRANSPORTE',
    'ANEXAR_COMPRO_COMPAR': 'TFD_ANEXAR_COMPRO_COMPAR',
    'DEVOLVER': 'TFD_DEVOLVER',
    'NEGAR': 'TFD_NEGAR',
    'ENCAMINHAR': 'TFD_ENCAMINHAR',
    'AGENDAR': 'TFD_AGENDAR',
    'ENCAMINHAR_REGULADOR': 'TFD_ENCAMINHAR_REGULADOR',
    'ENCAMINHAR_AVALIADOR': 'TFD_ENCAMINHAR_AVALIADOR',
    'AUTORIZAR': 'TFD_AUTORIZAR',
    'CONFIRMAR_AGEN_CNRAC': 'TFD_CONFIRMAR_AGEN_CNRAC',
    'INFORMAR_NUM_CNRAC': 'TFD_INFORMAR_NUM_CNRAC',
    'REGULAR': 'TFD_REGULAR',
    'AVALIAR': 'TFD_AVALIAR',
    'INSERIR_PROCEDIMENTO': 'TFD_INSERIR_PROCEDIMENTO',
    'ARQUIVAR_PROCESSO': 'TFD_ARQUIVAR_PROCESSO',
    'INFORMAR_AGENDAMENTO': 'TFD_INFORMAR_AGENDAMENTO'
}

export const CONSTANTE_SIGLA_ESTADO_SC = 'SC';
export const CONSTANTE_INSTANCIA_RCTM = 'rctm';