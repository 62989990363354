import React, {
  useRef,
  useState,
  useEffect,
  memo,
  Suspense,
  lazy,
  useContext,
} from 'react';
import {
  SttExpansionPanel,
  SttDivider,
  SttContainer,
  SttLoading,
  SttAlerta,
  SttHeading,
  SttCircularProgress,
  SttButton,
  SttModal,
  SttTranslateHook,
  SttFormHelperText,
} from '@stt-componentes/core';
import { connect } from 'react-redux';
import HttpStatus from 'http-status-codes';
import { Formik } from 'formik';
import Functions from '../../common/Functions';
import { makeStyles } from '@material-ui/core/styles';
import validationSchema from './validationSchema';
import { useHistory } from 'react-router-dom';
import {
  DADOS_GERAIS,
  TIPO_PROCESSO,
} from '../../componentes/solicitacao/dadosGerais/form/fieldNames';
import {
  mensagemAlertaComecoSolicitacao,
  TIPO_SUBMIT,
  situacao as SITUACAO_CONSTANTES,
  TIPO_PROCESSO as TIPO_PROCESSO_CONSTANTES,
  PERFIS,
} from '../../common/Constants';
import axios from 'axios';
import { getHeaders } from '../../request';
import {
  setTfd as setTfdAction,
  setPodeSalvarAutomatico as setPodeSalvarAutomaticoAction,
} from '../../reducers/actions/index';
import Encaminhamento from './../../componentes/solicitacao/encaminhamento/index';
import { NotificationManager } from 'react-notifications';
import { temPerfil } from '../../security/acl';

const DadosGerais = lazy(() =>
  import('../../componentes/solicitacao/dadosGerais')
);
const DadosInterestadual = lazy(() =>
  import('../../componentes/solicitacao/interestadual')
);
const DadosJudicial = lazy(() =>
  import('../../componentes/solicitacao/judicial')
);
const Paciente = lazy(() => import('../../componentes/solicitacao/paciente'));
const Sigtap = lazy(() => import('../../componentes/solicitacao/sigtap'));
const Transporte = lazy(() =>
  import('../../componentes/solicitacao/transporte')
);
const MedicoSolicitante = lazy(() =>
  import('../../componentes/solicitacao/medicoSolicitante')
);
const Anexos = lazy(() => import('../../componentes/solicitacao/anexos'));
const SttCid10 = lazy(() => import('@stt-componentes/cid10'));

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    marginTop: theme.spacing(1),
  },
  carregando: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  botaoVoltar: {
    marginTop: theme.spacing(2),
  },
}));

const Divider = memo((props) => {
  return <SttDivider {...props} />;
});

const Alerta = memo((props) => {
  return <SttAlerta {...props} />;
});

const campos = {
  camposBusca: {
    cpf: { obrigatorio: false },
    cns: { obrigatorio: false },
    nome: { obrigatorio: false },
  },
  camposCadastro: [
    [
      {
        nome: 'nome',
        obrigatorio: true,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 6, md: 6 },
      },
      {
        nome: 'genero',
        obrigatorio: true,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 3, md: 3 },
      },
      {
        nome: 'dataNascimento',
        obrigatorio: true,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 3, md: 3 },
      },
    ],
    [
      {
        nome: 'nomeMae',
        obrigatorio: true,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 6, md: 6 },
      },
      {
        nome: 'cpf',
        obrigatorio: true,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 3, md: 3 },
      },
      {
        nome: 'cns',
        obrigatorio: true,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 3, md: 3 },
      },
    ],
    [
      {
        nome: 'cep',
        obrigatorio: true,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 4, md: 4 },
      },
      {
        nome: 'numero',
        obrigatorio: true,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 2, md: 2 },
      },
      {
        nome: 'logradouro',
        obrigatorio: true,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 6, md: 6 },
      },
    ],
    [
      {
        nome: 'bairro',
        obrigatorio: true,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 6, md: 6 },
      },
      {
        nome: 'pais',
        obrigatorio: true,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 4, md: 4 },
      },
      {
        nome: 'uf',
        obrigatorio: true,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 2, md: 2 },
      },
    ],
    [
      {
        nome: 'municipio',
        obrigatorio: true,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 6, md: 6 },
      },
    ],
    [
      {
        nome: 'contato1',
        obrigatorio: true,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 6, md: 6 },
      },
      {
        nome: 'contato2',
        obrigatorio: false,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 6, md: 6 },
      },
    ],
    [
      {
        nome: 'peso',
        obrigatorio: false,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 2, md: 2 },
      },
      {
        nome: 'altura',
        obrigatorio: false,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 2, md: 2 },
      },
      {
        nome: 'grupoSanguineo',
        obrigatorio: false,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 3, md: 3 },
      },
      {
        nome: 'fatorRh',
        obrigatorio: false,
        somenteLeitura: false,
        tamanho: { xs: 12, sm: 2, md: 2 },
      },
    ],
  ],
};

const Solicitacao = (params) => {
  const { strings } = useContext(SttTranslateHook.I18nContext);
  const { tfd, user, setTfd, setPodeSalvarAutomatico, podeSalvarAutomatico } =
    params;
  const { id: idTfd, situacao } = tfd;
  const TFD_API_BASE_URL = global.gConfig.url_base_tfd;
  const schema = validationSchema(strings, campos.camposCadastro);
  const formRef = useRef();
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState(null);
  const [carregado, setCarregado] = useState(false);
  const [erroCarregamento, setErroCarregamento] = useState(false);
  const [salvarAutomatico, setSalvarAutomatico] = useState(false);
  const [idAnexos, setIdAnexos] = useState([]);
  const [timer, setTimer] = useState(null);
  const [callbackEncaminhamento, setCallbackEncaminhamento] = useState(null);
  const history = useHistory();

  //Propriedades do modal
  const tituloModal = strings.encaminhamento;
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
    if (situacao == SITUACAO_CONSTANTES.RASCUNHO) {
      setPodeSalvarAutomatico(true);
    }
  };

  //Alerta
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [tituloAlerta, setTituloAlerta] = useState('');
  const [tipoAlerta, setTipoAlerta] = useState('alert');
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [opcoesAlerta, setOpcoesAlerta] = useState([
    {
      title: strings.ok,
      onClick: () => {
        setMostrarAlerta(false);
      },
    },
  ]);
  const [onClose, setOnClose] = useState(null);

  const mostrarAlertaComponente = (
    titulo,
    mensagem,
    tipo,
    opcoes,
    close = () => {
      setMostrarAlerta(false);
    }
  ) => {
    if (!tipo) {
      tipo = 'alert';
    }

    setTituloAlerta(titulo);
    setMensagemAlerta(mensagem);
    setTipoAlerta(tipo);
    if (opcoes) {
      setOpcoesAlerta(opcoes);
    }
    setOnClose(close);
    setMostrarAlerta(true);
  };

  //Quando a situação do processo mudar, verifica se é rascunho e seta salvamento automático
  useEffect(() => {
    if (situacao == SITUACAO_CONSTANTES.RASCUNHO) {
      setPodeSalvarAutomatico(true);
    }
  }, [situacao]);

  useEffect(() => {
    clearTimeout(timer);
    if (podeSalvarAutomatico) {
      setTimer(criarTimeout());

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
  }, [salvarAutomatico, podeSalvarAutomatico]);

  useEffect(() => {
    if (!salvarAutomatico) {
      return;
    }

    const { errors, values, touched, setTouched } = formRef.current;

    const existemErrosAnexos =
      errors && errors.anexos && errors.anexos.length > 0;

    if (!existemErrosAnexos) {
      enviarForm(values, true);
      return;
    }

    const errosDeAnexos = {
      anexos: [...errors.anexos],
    };
    setTouched({ ...touched, ...errosDeAnexos });

    setSalvarAutomatico(false);
    setPodeSalvarAutomatico(true);
  }, [salvarAutomatico]);

  useEffect(() => {
    if (idTfd) {
      handleIniciarAlteracao(idTfd);
    } else {
      handleIniciarSolicitacao();
    }

    // else {
    //     mostrarAlertaComponente(
    //         strings.solicitacaoNovoTfd,
    //         mensagemAlertaComecoSolicitacao,
    //         'alert',

    //         [
    //             {
    //                 title: strings.sim,
    //                 onClick: handleIniciarSolicitacao
    //             },
    //             {
    //                 title: strings.nao,
    //                 onClick: handleCancelarInicioSolicitacao
    //             }
    //         ],
    //         () => () => handleCancelarInicioSolicitacao()
    //     );
    // }
  }, [idTfd]);

  const criarTimeout = () => {
    return setTimeout(() => {
      setSalvarAutomatico(true);
    }, 60 * 1000);
  };

  const enviarForm = (dados, rascunho, setSubmitting) => {
    const dadosTfdFormatados = Functions.formatarDadosSalvarSolicitacao(dados);

    let url = '';
    //Caso o processo esteja devolvido, e quem fez a alteração é um técnico
    if (
      situacao == SITUACAO_CONSTANTES.DEVOLVIDO &&
      temPerfil(user, PERFIS.TFD_MUNICIPAL)
    ) {
      url = `${TFD_API_BASE_URL}/encaminhar`;
      dadosTfdFormatados.append('tipoSubmit', TIPO_SUBMIT.ENCAMINHAMENTO);
    } else {
      //Se o processo não está em RASCUNHO, edita o mesmo e mantem a situação
      if (situacao != SITUACAO_CONSTANTES.RASCUNHO) {
        url = `${TFD_API_BASE_URL}/editar`;
        dadosTfdFormatados.append('tipoSubmit', TIPO_SUBMIT.ALTERACAO);
        dadosTfdFormatados.delete('encaminhamento');
      } else {
        //Caso o processo esteja EM RASCUNHO, verifica se SALVA RASCUNHO ou ENCAMINHA
        if (rascunho) {
          dadosTfdFormatados.append('tipoSubmit', TIPO_SUBMIT.RASCUNHO);
          url = `${TFD_API_BASE_URL}/editar`;
          dadosTfdFormatados.delete('encaminhamento');
        } else {
          dadosTfdFormatados.append('tipoSubmit', TIPO_SUBMIT.ENCAMINHAMENTO);
          url = `${TFD_API_BASE_URL}/encaminhar`;
        }
      }
    }

    if (salvarAutomatico) {
      dadosTfdFormatados.append('salvarAutomatico', salvarAutomatico);
    }
    //Faz sentido para o rascunho e para o encaminhamento, mas não para o salvamento automático
    if (setSubmitting) {
      setSubmitting(true);
    }
    //Desativa o salvamento automático enquanto a requisição não retorna
    setPodeSalvarAutomatico(false);
    axios
      .post(url, dadosTfdFormatados, {
        headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        if (response.data?.data?.idAnexos?.length) {
          setIdAnexos(response.data.data.idAnexos);
        }

        if (response.data?.data?.idPaciente) {
          formRef.current.setFieldValue(
            `${'paciente'}.${'id'}`,
            response.data.data.idPaciente
          );
        }

        //Se é a partir de salvamento automático, notifica usuário que o salvamento ocorreu
        if (salvarAutomatico) {
          NotificationManager.success(strings.rascunhoSalvoSucesso);
          setPodeSalvarAutomatico(true);
        }

        //Se for salvamento de rascunho e não é automático (usuário clicou no botão):
        if (!salvarAutomatico && rascunho) {
          mostrarAlertaComponente(
            strings.confirmacao,
            strings.rascunhoSalvoSucesso,
            'success',
            [
              {
                title: strings.ok,
                onClick: () => {
                  closeAlertaRedirecionarPesquisa();
                },
              },
            ],
            () => () => {
              closeAlertaRedirecionarPesquisa();
            }
          );
          return;
        }

        if (setSubmitting) {
          setSubmitting(false);
        }

        if (!rascunho) {
          let mensagem = strings.solicitacaoAlteradaSucesso;
          if (situacao == SITUACAO_CONSTANTES.RASCUNHO) {
            mensagem = strings.solicitacaoEncaminhadaSucesso;
          }

          mostrarAlertaComponente(
            strings.confirmacao,
            mensagem,
            'success',
            [
              {
                title: strings.ok,
                onClick: () => {
                  closeAlertaRedirecionarPesquisa();
                },
              },
            ],
            () => () => {
              closeAlertaRedirecionarPesquisa();
            }
          );
          return;
        }

        setSalvarAutomatico(false);
      })
      .catch((err) => {
        if (setSubmitting) {
          setSubmitting(false);
        }

        const { response } = err;
        let mensagem = strings.erroOperacao;

        if (response) {
          if (response.status === HttpStatus.BAD_REQUEST) {
            const { data } = response;

            if (data && data.errors && data.errors.length > 0) {
              let arrMensagem = [];

              data.errors.forEach((error) => {
                arrMensagem.push(`- ${error.message}`);
              });

              if (arrMensagem.length > 0) {
                mensagem = arrMensagem.join('\n');
              }
            } else {
              mensagem =
                `${response.statusText} (código: ${response.status})` ||
                strings.erro;
            }
          } else {
            mensagem =
              `${response.statusText} (código: ${response.status})` ||
              strings.erro;
          }
        } else {
          mensagem = `${err.message} (código: ${err.code})` || strings.erro;
        }

        //Se é a partir de salvamento automático, notifica usuário que o salvamento ocorreu
        if (salvarAutomatico) {
          NotificationManager.error(
            strings.erroSalvarSolicitacaoAutomaticamente
          );
        } else {
          mostrarAlertaComponente(
            strings.erro,
            mensagem,
            'error',
            [
              {
                title: strings.ok,
                onClick: () => {
                  setMostrarAlerta(false);
                },
              },
            ],
            () => () => setMostrarAlerta(false)
          );
        }

        if (situacao == SITUACAO_CONSTANTES.RASCUNHO) {
          setPodeSalvarAutomatico(true);
        }

        setSalvarAutomatico(false);
      });
  };

  const tratarOpcoesEncaminhamento = (data, setSubmitting) => {
    setCallbackEncaminhamento({
      callback: (dadosEncaminhamento, setSubmittingModal) => {
        data.encaminhamento = dadosEncaminhamento;
        enviarForm(data, false, setSubmitting);
        setSubmittingModal(false);
      },
    });
    setPodeSalvarAutomatico(false);
    setOpenModal(true);
  };

  const submitForm =
    (rascunho) =>
    (data, { setSubmitting }) => {
      setSubmitting(false);

      if (rascunho) {
        //Trocar a situação aqui
        if (formRef.current) {
          setSubmitting(true);
          //Se há um timer, limpa
          if (timer) {
            clearTimeout(timer);
          }

          enviarForm(data, rascunho, setSubmitting);
        }
      } else {
        //Caso o processo esteja como rascunho ou devolvido, encaminha
        if (
          situacao == SITUACAO_CONSTANTES.RASCUNHO ||
          situacao == SITUACAO_CONSTANTES.DEVOLVIDO
        ) {
          tratarOpcoesEncaminhamento(data, setSubmitting);
          return;
        }

        if (formRef.current) {
          setSubmitting(true);
          enviarForm(data, rascunho, setSubmitting);
        }
      }
    };

  const onSubmit = submitForm(false);
  const onSubmitRascunho = submitForm(true);

  const handleIniciarSolicitacao = () => {
    axios
      .post(
        `${TFD_API_BASE_URL}/iniciar-solicitacao`,
        {},
        { headers: getHeaders() }
      )
      .then((response) => {
        setInitialValues(
          Functions.inicializarValoresSolicitacao(
            { id: response.data.data.id },
            user
          )
        );
        setTfd({
          id: response.data.data.id,
          situacao: SITUACAO_CONSTANTES.RASCUNHO,
        });
        setMostrarAlerta(false);
        setCarregado(true);
      })
      .catch((err) => {
        const { response } = err;
        let mensagem = strings.erroOperacao;

        if (response) {
          if (response.status === HttpStatus.BAD_REQUEST) {
            const { data } = response;

            if (data && data.errors && data.errors.length > 0) {
              let arrMensagem = [];

              data.errors.forEach((error) => {
                arrMensagem.push(`- ${error.message}`);
              });

              if (arrMensagem.length > 0) {
                mensagem = arrMensagem.join('\n');
              }
            } else {
              mensagem =
                `${response.statusText} (código: ${response.status})` ||
                strings.erro;
            }
          } else {
            mensagem =
              `${response.statusText} (código: ${response.status})` ||
              strings.erro;
          }
        } else {
          mensagem = `${err.message} (código: ${err.code})` || strings.erro;
        }

        setTituloAlerta(strings.erroIniciarSolicitacao);
        setMensagemAlerta(mensagem);
        setTipoAlerta('error');
        setOpcoesAlerta([
          {
            title: strings.ok,
            onClick: () => {
              setMostrarAlerta(false);
            },
          },
        ]);
        setMostrarAlerta(true);
        setErroCarregamento(true);
      });
  };

  const closeAlertaRedirecionarPesquisa = () => {
    setTfd({ ...tfd, id: null, situacao: null });
    history.replace('/');
    setMostrarAlerta(false);
  };

  const handleCancelarInicioSolicitacao = () => {
    setTfd({ id: null, situacao: null });
    history.replace('/');

    setTituloAlerta(strings.confirmacao);
    setMensagemAlerta(strings.retornarTelaPesquisa);
    setTipoAlerta('alert');
    setOpcoesAlerta([
      {
        title: strings.ok,
        onClick: () => {
          closeAlertaRedirecionarPesquisa();
        },
      },
    ]);
    setMostrarAlerta(true);
  };

  const resetarArquivosAnexos = () => {
    delete formRef.current.errors.anexos;
    delete formRef.current.values.anexos;
  };

  //Alteração de processo
  const handleIniciarAlteracao = (id) => {
    axios
      .get(`${TFD_API_BASE_URL}/solicitacao/${id}`, { headers: getHeaders() })
      .then((response) => {
        const dados = response.data.data;

        setInitialValues(Functions.inicializarValoresSolicitacao(dados, user));
        setTfd({ id: response.data.data.id, situacao: dados.situacao });
        setCarregado(true);
      })
      .catch((err) => {
        const { response } = err;
        let mensagem = strings.erroOperacao;

        if (response) {
          if (response.status === HttpStatus.BAD_REQUEST) {
            const { data } = response;

            if (data && data.errors && data.errors.length > 0) {
              let arrMensagem = [];

              data.errors.forEach((error) => {
                arrMensagem.push(`- ${error.message}`);
              });

              if (arrMensagem.length > 0) {
                mensagem = arrMensagem.join('\n');
              }
            } else {
              mensagem =
                `${response.statusText} (código: ${response.status})` ||
                strings.erro;
            }
          } else {
            mensagem =
              `${response.statusText} (código: ${response.status})` ||
              strings.erro;
          }
        } else {
          mensagem = `${err.message} (código: ${err.code})` || strings.erro;
        }

        setTituloAlerta(strings.erroCarregarSolicitacao);
        setMensagemAlerta(mensagem);
        setTipoAlerta('error');
        setOpcoesAlerta([
          {
            title: strings.ok,
            onClick: () => {
              setMostrarAlerta(false);
            },
          },
        ]);
        setMostrarAlerta(true);
        setErroCarregamento(true);
      });
  };

  const closeAlerta = () => {
    setMostrarAlerta(false);
    if (situacao == SITUACAO_CONSTANTES.RASCUNHO) {
      setPodeSalvarAutomatico(true);
    }
  };

  if (erroCarregamento) {
    return (
      <SttContainer>
        <SttButton
          type='button'
          variant='outlined'
          color='primary'
          className={classes.botaoVoltar}
          onClick={() => {
            setPodeSalvarAutomatico(false);
            mostrarAlertaComponente(
              strings.confirmacao,
              strings.retornarTelaPesquisa,
              'alert',
              [
                {
                  title: strings.sim,
                  onClick: () => {
                    closeAlertaRedirecionarPesquisa();
                  },
                },
                {
                  title: strings.nao,
                  onClick: () => {
                    closeAlerta();
                  },
                },
              ],
              () => () => {
                closeAlerta();
              }
            );
          }}
          nomarginleft='true'
        >
          Voltar
        </SttButton>

        <Alerta
          open={mostrarAlerta}
          title={tituloAlerta}
          message={mensagemAlerta}
          type={tipoAlerta}
          options={opcoesAlerta}
          onClose={onClose}
        />
      </SttContainer>
    );
  }

  return (
    <>
      {carregado ? (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
          validateOnChange={true} //necessário para poder validar com o Yup o tamanho dos arquivos anexos assim que são selecionados
        >
          {({
            values,
            isSubmitting,
            handleSubmit,
            setSubmitting,
            resetForm,
            errors,
            touched,
            submitCount,
            setFieldValue,
            validateForm,
            setTouched,
            setErrors,
          }) => {
            return (
              <SttContainer>
                <form onSubmit={handleSubmit} noValidate>
                  <SttButton
                    type='button'
                    variant='outlined'
                    color='primary'
                    className={classes.botaoVoltar}
                    onClick={() => {
                      setPodeSalvarAutomatico(false);
                      mostrarAlertaComponente(
                        strings.confirmacao,
                        strings.retornarTelaPesquisa,
                        'alert',
                        [
                          {
                            title: strings.sim,
                            onClick: () => {
                              closeAlertaRedirecionarPesquisa();
                            },
                          },
                          {
                            title: strings.nao,
                            onClick: () => {
                              closeAlerta();
                            },
                          },
                        ],
                        () => () => {
                          closeAlerta();
                        }
                      );
                    }}
                    nomarginleft='true'
                  >
                    Voltar
                  </SttButton>
                  <SttHeading
                    variant='h1'
                    color='primary'
                    align='center'
                    className={classes.header}
                  >
                    Tratamento fora do domicílio - {values.dadosGerais.id}
                  </SttHeading>
                  {/* Dados gerais */}
                  <SttExpansionPanel
                    title={strings.dadosGerais}
                    children={
                      <Suspense
                        fallback={<SttCircularProgress color='primary' />}
                      >
                        <DadosGerais
                          id={idTfd}
                          user={user}
                          mostrarAlertaComponente={mostrarAlertaComponente}
                        />
                      </Suspense>
                    }
                  />
                  <Divider />

                  {/* Dados do paciente */}
                  <SttExpansionPanel
                    title={strings.dadosPaciente}
                    children={
                      <Suspense
                        fallback={<SttCircularProgress color='primary' />}
                      >
                        <Paciente campos={campos} />
                      </Suspense>
                    }
                  />
                  <Divider />

                  {/* Dados do CID10 */}
                  <SttExpansionPanel
                    title={strings.cid10}
                    children={
                      <Suspense
                        fallback={<SttCircularProgress color='primary' />}
                      >
                        <SttCid10
                          strings={strings}
                          headers={getHeaders()}
                          multiplos={true}
                          formExterno={{
                            cid10: values.cid10,
                            isSubmitting,
                            errors,
                            submitCount,
                            setFieldValue,
                          }}
                        />
                      </Suspense>
                    }
                  />
                  <Divider />

                  {/* Dados do SIGTAP */}
                  {(values[DADOS_GERAIS][TIPO_PROCESSO] ==
                    TIPO_PROCESSO_CONSTANTES.INTERESTADUAL ||
                    values[DADOS_GERAIS][TIPO_PROCESSO] ==
                      TIPO_PROCESSO_CONSTANTES.JUDICIAL) && (
                    <SttExpansionPanel
                      title={strings.sigtap}
                      children={
                        <Suspense
                          fallback={<SttCircularProgress color='primary' />}
                        >
                          <Sigtap />
                        </Suspense>
                      }
                    />
                  )}
                  <Divider />
                  {/* Dados do processo de tipo Interestadual */}
                  {values[DADOS_GERAIS][TIPO_PROCESSO] ==
                    TIPO_PROCESSO_CONSTANTES.INTERESTADUAL && (
                    <>
                      <SttExpansionPanel
                        title={strings.dadosInterestadual}
                        children={
                          <Suspense
                            fallback={<SttCircularProgress color='primary' />}
                          >
                            <DadosInterestadual />
                          </Suspense>
                        }
                      />
                      <Divider />
                    </>
                  )}

                  {/* Dados do processo de tipo Interestadual */}
                  {values[DADOS_GERAIS][TIPO_PROCESSO] ==
                    TIPO_PROCESSO_CONSTANTES.JUDICIAL && (
                    <>
                      <SttExpansionPanel
                        title={strings.dadosJudicial}
                        children={
                          <Suspense
                            fallback={<SttCircularProgress color='primary' />}
                          >
                            <DadosJudicial />
                          </Suspense>
                        }
                      />
                      <Divider />
                    </>
                  )}

                  <SttExpansionPanel
                    title={strings.dadosTransporte}
                    children={
                      <Suspense
                        fallback={<SttCircularProgress color='primary' />}
                      >
                        <Transporte />
                      </Suspense>
                    }
                  />
                  <Divider />

                  {values[DADOS_GERAIS][TIPO_PROCESSO] !==
                    TIPO_PROCESSO_CONSTANTES.JUDICIAL && (
                    <>
                      <SttExpansionPanel
                        title={strings.medicoSolicitante}
                        children={
                          <Suspense
                            fallback={<SttCircularProgress color='primary' />}
                          >
                            <MedicoSolicitante />
                          </Suspense>
                        }
                      />
                      <Divider />
                    </>
                  )}

                  <SttExpansionPanel
                    title={strings.anexos}
                    children={
                      <Suspense
                        fallback={<SttCircularProgress color='primary' />}
                      >
                        <Anexos
                          idAnexos={idAnexos}
                          resetarArquivosAnexos={resetarArquivosAnexos}
                        />
                      </Suspense>
                    }
                  />
                  <Divider />

                  <div className={classes.buttonWrapper}>
                    {situacao == SITUACAO_CONSTANTES.RASCUNHO && (
                      <SttButton
                        type='button'
                        variant='contained'
                        color='primary'
                        disabled={isSubmitting}
                        onClick={() =>
                          validateForm().then(() => {
                            //não deixa salvar o rascunho com arquivo grande
                            if (
                              errors &&
                              errors.anexos &&
                              errors.anexos.length > 0
                            ) {
                              //valida apenas o tamanho dos arquivos no salvamento do rascunho
                              const errosDeAnexos = {
                                anexos: [...errors.anexos],
                              };
                              setTouched({ ...touched, ...errosDeAnexos });
                            } else {
                              onSubmitRascunho(values, {
                                setSubmitting,
                                resetForm,
                              });
                            }
                          })
                        }
                        nomarginleft='true'
                      >
                        {strings.salvarRascunho}
                      </SttButton>
                    )}
                    {(situacao == SITUACAO_CONSTANTES.RASCUNHO ||
                      (situacao == SITUACAO_CONSTANTES.DEVOLVIDO &&
                        temPerfil(user, PERFIS.TFD_MUNICIPAL))) && (
                      <SttButton
                        type='submit'
                        variant='contained'
                        color='primary'
                        disabled={isSubmitting}
                      >
                        {strings.encaminhar}
                      </SttButton>
                    )}
                    {situacao != SITUACAO_CONSTANTES.RASCUNHO &&
                      !(
                        situacao == SITUACAO_CONSTANTES.DEVOLVIDO &&
                        temPerfil(user, PERFIS.TFD_MUNICIPAL)
                      ) && (
                        <SttButton
                          type='submit'
                          variant='contained'
                          color='primary'
                          disabled={isSubmitting}
                        >
                          {strings.salvar}
                        </SttButton>
                      )}
                  </div>
                </form>
                <SttLoading
                  open={isSubmitting && !salvarAutomatico}
                  text={strings.salvandoSolicitacao}
                />

                <SttModal
                  title={tituloModal}
                  open={openModal}
                  maxWidth='lg'
                  outModalClose={handleCloseModal}
                  iconClose={handleCloseModal}
                  fullWidth={true}
                  children={
                    <Encaminhamento
                      callback={
                        callbackEncaminhamento
                          ? callbackEncaminhamento.callback
                          : ''
                      }
                      callbackFecharModal={handleCloseModal}
                      tipoProcesso={values[DADOS_GERAIS][TIPO_PROCESSO]}
                      id={idTfd}
                    />
                  }
                />
              </SttContainer>
            );
          }}
        </Formik>
      ) : (
        <div className={classes.carregando}>
          <SttCircularProgress color='primary' />
        </div>
      )}
      <Alerta
        open={mostrarAlerta}
        title={tituloAlerta}
        message={mensagemAlerta}
        type={tipoAlerta}
        options={opcoesAlerta}
        onClose={onClose}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.index.user,
    tfd: state.index.tfd,
    podeSalvarAutomatico: state.index.podeSalvarAutomatico,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTfd: (id) => dispatch(setTfdAction(id)),
    setPodeSalvarAutomatico: (podeSalvarAutomatico) =>
      dispatch(setPodeSalvarAutomaticoAction(podeSalvarAutomatico)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Solicitacao);
