export const MOTIVO = 'motivo';
export const JUSTIFICATIVA = 'justificativa';
export const NUMERO_CNRAC = 'numero_cnrac';
export const TIPO_ENCAMINHAMENTO = 'tipoEncaminhamento';
export const MOVIMENTACAO = 'movimentacao';
export const REGULADOR = 'regulador';
export const PROCEDIMENTO = 'procedimento';
export const REGISTRO_SISREG = 'registroSISREG';
export const TIPO_AGENDAMENTO = 'tipoAgendamento';
export const DATA_HORA = 'data_hora';
export const INSTITUICAO = 'instituicao';
export const VINCULO_COMPROVANTE = 'vinculo_comprovante';
export const ANEXOS_COMPARECIMENTO = 'anexos_comparecimento';
export const SOLICITACAO_PASSAGEM_VINCULO = 'solicitacao_passagem_vinculo';
export const PERNOITES = 'pernoites';
export const DIARIAS = 'diarias';
export const NUMERO_ACOMPANHANTES = 'numero_acompanhantes';
export const OBSERVACAO = 'observacao';
export const ID = 'id';
export const PERIODO_ATENDIMENTO = 'periodoAtendimento';