import * as yup from 'yup';
import {
    FIELDS
} from './fieldNames';

import {
    TIPO_PROCESSO,
    DADOS_GERAIS
} from '../dadosGerais/form/fieldNames';

import {
    TIPO_PROCESSO as TIPO_PROCESSO_CONSTANTES
} from '../../../common/Constants';

const hoje = new Date();
hoje.setHours(23, 59, 0, 0);

export default (strings) => {
    return yup.object().shape({
        [FIELDS.MEDICO_SOLICITANTE]: yup
            .object()
            .when(`${DADOS_GERAIS}.${TIPO_PROCESSO}`, {
                is: (val) => val !== TIPO_PROCESSO_CONSTANTES.JUDICIAL,
                then: yup.object()
                    .test('preenchimentoMedico', strings.medicoSolicitanteObrigatorio, val => {
                        if (!val.id || val.id == '') {
                            return false;
                        }
                        return true;
                    })
                    .nullable()
                    .required(strings.medicoSolicitanteObrigatorio)
            }),
        [FIELDS.DATA_PREENCHIMENTO_LAUDO_MEDICO]: yup
            .date()
            .when(TIPO_PROCESSO, {
                is: (val) => val !== TIPO_PROCESSO_CONSTANTES.JUDICIAL,
                then: yup.date()
                    .typeError(strings.dataInvalida)
                    .max(hoje, strings.erroDataFutura)
                    .nullable()
                    .required(strings.dataPreenchimentoLaudoMedicoObrigatoria)
            })
    });
}
