import { combineReducers } from 'redux';
import { estadoInicial } from '../componentes/pesquisa/estadoInicial';
import TYPES from './types';

const INITIAL_STATE = {
    isAuthenticated: false,
    message: '',
    temPerfilAdministrador: false,
    user: {
        nome: '',
        email: '',
        foto: ''
    },
    parametrosPesquisa: estadoInicial,
    tfd: {
        id: null,
        situacao: null
    },
    idComprovanteComparecimento: null,
    podeSalvarAutomatico: false
};

const index = (state, action) => {
    if(!state) {
        state = INITIAL_STATE;
    }
    
    const { type, payload } = action;

    switch (type) {
        case TYPES.SET_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: payload.authenticated
            }
        case TYPES.SET_USER:
            return {
                ...state,
                user: payload.user
            }
        case TYPES.LOGOUT:
            return INITIAL_STATE;

        case TYPES.SET_MESSAGE:
            return {
                ...state,
                message: payload.message
            }
        case TYPES.SET_PARAMETROS_PESQUISA:
            return {
                ...state,
                parametrosPesquisa: payload.parametros
            }

        case TYPES.LIMPAR_PARAMETROS_PESQUISA:
            return {
                ...state,
                parametrosPesquisa: estadoInicial
            }

        case TYPES.SET_TFD:
            return {
                ...state,
                tfd: payload
            }

        case TYPES.SET_ID_COMPROVANTE_COMPARECIMENTO:
            return {
                ...state,
                idComprovanteComparecimento: payload
            }
        
        case TYPES.SET_PODE_SALVAR_AUTOMATICO:
            return {
                ...state,
                podeSalvarAutomatico: payload
            }

        default:
            return state;
    }
}

export const Reducers = combineReducers({
    index
});
