const build = (config) => {
    const AUTH_URL = config.url_base_oauth2;
    const CALLBACK_URL = config.url_base_callback;

    const OAUTH_CALLBACK_URL = `${AUTH_URL}/?redirect_uri=${encodeURI(CALLBACK_URL)}`;
    const MEUS_DADOS_URL = `${config.url_base_oauth2}/meus-dados`;
    return { OAUTH_CALLBACK_URL, MEUS_DADOS_URL };
};

export default { build };
