export const estadoInicial = {
    numero_pedido: '',
    data_inicial: null,
    data_final: null,
    uf_origem: null,
    cidade_origem: null,
    cidade_agendadora: null,
    uf_destino: null,
    cidade_destino: null,
    nome_paciente: '',
    data_nascimento: null,
    cpf_paciente: '',
    situacao: -50,
    atrasados: false,
    regulador: null,
    avaliador: null,
    regional: null
};