import * as yup from 'yup';
import validarCpf from 'validar-cpf';
import {
    NUMERO_PEDIDO,
    DATA_INICIAL,
    DATA_FINAL,
    CPF_PACIENTE,
    NOME_PACIENTE,
    DATA_NASCIMENTO
} from './fieldNames';

const hoje = new Date();
hoje.setHours(23, 59, 0, 0);

export default (strings) => {
    return yup.object().shape({
        [NUMERO_PEDIDO]: yup
                .string()
                .test('tamanho', strings.numeroPedidoExcederVinteCaracteres, (val) => {
                    if (!val) {
                        return true;
                    }
                    return val.toString().length <= 20;
                }),
        [DATA_INICIAL]: yup
            .date()
            .typeError(strings.dataInvalida)
            .max(hoje, strings.erroDataFutura)
            .nullable(),
        [DATA_FINAL]: yup
            .date()
            .typeError(strings.dataInvalida)
            .min(yup.ref(DATA_INICIAL), strings.dataFinalAnteriorDataInicial)
            .max(new Date(), strings.erroDataFutura)
            .nullable(),
        [CPF_PACIENTE]: yup
            .string()
            .nullable()
            .test('cpf-valido', strings.cpfInvalido, (cpf) => {
                cpf = (cpf || '').replace(/\D+/g, '');
                if (cpf.length === 0) {
                    return true;
                }
                return validarCpf(cpf);
            }),
        [NOME_PACIENTE]: yup
            .string()
            .test('nome', strings.nomePacienteSomenteLetras, val => {
                if (val && !(/^[a-zA-Z ]+$/.test(val))) {
                    return false;
                }

                return true;
            })
            .nullable(),
        [DATA_NASCIMENTO]: yup
            .date()
            .typeError(strings.dataInvalida)
            .max(hoje, strings.erroDataFutura)
            .nullable(),
    });
}
