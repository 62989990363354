export const DADOS_GERAIS = 'dadosGerais';
export const TIPO_PROCESSO = 'tipoProcesso';
export const NUMERO_PEDIDO = 'numeroPedido';
export const ESTADO_ORIGEM = 'estadoOrigem';
export const DATA = 'data';
export const CIDADE_AGENDADORA = 'cidadeAgendadora';
export const MUNICIPIO_ORIGEM = 'municipioOrigem';
export const PROCEDIMENTO_SOLICITADO = 'procedimentoSolicitado';
export const SITUACAO_PACIENTE = 'situacaoPaciente';
export const DIAGNOSTICO_INICIAL = 'diagnosticoInicial';
export const CARATER_ATENDIMENTO = 'caraterAtendimento';
export const CATEGORIA_ATENDIMENTO_HOSPITALAR = 'categoriaAtendimentoHospitalar';