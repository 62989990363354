import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from '../hooks';
import axios from 'axios';
import { connect } from 'react-redux';
import { 
    setMessage as setMessageAction
} from '../reducers/actions';
import Url from '../common/Url';
import { useHistory } from 'react-router-dom';
import HttpStatus from 'http-status-codes';

import { SttCircularProgress } from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    wrapper: {
        paddingBottom: theme.spacing(4)
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    }
}));

const Externo = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { setMessage } = props;
    const { OAUTH_CALLBACK_URL } = Url.build(global.gConfig);

    /** get token */
    const query = useQuery();
    const tokenQuery = query.get('token');
    const tokenLocalStorate = localStorage.getItem('stt-authorization');
    const token = tokenQuery || tokenLocalStorate;
    
    useEffect(() => {
        if (token) {
            const OAUTH_BASE_URL = global.gConfig.url_base_api_oauth2;
            axios
                .post(`${OAUTH_BASE_URL}/auth/external-login`, null, { headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    const { data } = response;
                    const accessToken = data.access_token;

                    // Armazena o token no localStorage
                    localStorage.setItem('stt-authorization', accessToken);

                    history.replace('/solicitacao');
                })
                .catch((err) => {
                    const { response } = err;
                    if (response && response.status === HttpStatus.UNAUTHORIZED) {
                        setMessage(response.data.message);
                        history.push('/nao-autorizado');
                    } else {
                        setMessage(response.data.message);
                        history.push('/erro');
                    }
                });
        } else {
            window.location.href = OAUTH_CALLBACK_URL;
        }
    }, [OAUTH_CALLBACK_URL, history, setMessage, token]);


    return (
        <div className={classes.loading}>
            <SttCircularProgress />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMessage: (message) => dispatch(setMessageAction(message)),
    };
};

export default connect(null, mapDispatchToProps)(Externo);
