import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { setTfd as setTfdAction } from '../reducers/actions';
import { useHistory, withRouter, useLocation } from "react-router-dom";
import { getHeaders } from '../request';
import { SttHeader, SttMainNavigationBar, SttAlerta, SttSplashScreen, SttTranslateHook, MateriaisSuporte, SttNotificationScreen, SttHelpdeskShortcut } from '@stt-componentes/core';
import { mensagemAlertaComecoSolicitacao } from '../common/Constants';
import { temPermissaoRBAC } from '../security/acl';

const Menu = ({ user, setTfd, tfd }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const location = useLocation();
    const history = useHistory();
    const [tabAtual, setTabAtual] = useState(0);
    const [submenus, setSubmenus] = useState([]);

    //Alertas
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);

    const [openSS, setOpenSS] = useState(false);
    const [openMS, setOpenMS] = useState(false);
    const [exibirNotificacao, setExibirNotificacao] = useState(false);

    useEffect(() => {
        let menuOptions = [];
        if (user.credenciais) {
            if (!temPermissaoRBAC(user, 'TFD_TFD')) {
                history.push("/nao-autorizado");
                return;
            }

            setExibirNotificacao(true);
            if (temPermissaoRBAC(user, 'TFD_PESQUISAR')) {
                menuOptions = [...menuOptions, {
                    id: '/',
                    text: 'Pesquisa',
                    onClick: () => {
                        if (history.location.pathname === '/solicitacao') {
                            setTfd({ ...tfd, id: null, situacao: null });
                            setTituloAlerta(strings.atencao);
                            setMensagemAlerta(strings.retornarTelaPesquisa);
                            setOpcoesAlerta(
                                [
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            history.push("/");
                                            setMostrarAlerta(false);
                                        }
                                    },
                                    {
                                        title: strings.cancelar,
                                        onClick: () => {
                                            setMostrarAlerta(false);
                                        }
                                    }
                                ]
                            );
                            setMostrarAlerta(true);
                        } else {
                            history.push("/");
                        }
                    }
                }];
            }

            if (temPermissaoRBAC(user, 'TFD_CRIAR')) {
                menuOptions = [...menuOptions, {
                    id: '/solicitacao',
                    text: 'Solicitação',
                    onClick: () => {
                        if (history.location.pathname !== '/solicitacao') {
                            setTfd({ ...tfd, id: null, situacao: null });
                            setTabAtual(1);
                            setTituloAlerta(strings.solicitacaoNovoTfd);
                            setMensagemAlerta(mensagemAlertaComecoSolicitacao);
                            setOpcoesAlerta(
                                [
                                    {
                                        title: strings.sim,
                                        onClick: () => {
                                            history.push(`/solicitacao`);
                                            setMostrarAlerta(false);
                                        }
                                    },
                                    {
                                        title: strings.nao,
                                        onClick: () => {
                                            setTabAtual(0);
                                            setMostrarAlerta(false);
                                        }
                                    }
                                ]
                            );
                            setMostrarAlerta(true);
                        } else {
                            setMostrarAlerta(false);
                        }
                    }


                }];
            }
            setSubmenus(menuOptions);
        }
    }, [history, location.pathname, setTfd, tfd, user, user.credenciais]);

    useEffect(() => {
        submenus.forEach((menu, i) => {
            if (menu.id === location.pathname) {
                setTabAtual(i);
            }
        });
    }, [location.pathname, submenus]);

    return (
        <div>
            <SttHeader
                titulo={strings.nomeSistema}
                config={global.gConfig}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => setOpenSS(!openSS),
                    onClickMateriaisSuporte: () => setOpenMS(!openMS),
                    strings
                }}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                perfisRBAC={user.perfisRBAC}
                open={openSS}
                setOpen={setOpenSS}
            />
            <SttMainNavigationBar
                titulo={strings.tituloModuloTfd}
                config={global.gConfig}
                submenus={submenus}
                selectedTab={tabAtual}
            />
            <SttAlerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={'alert'}
                onClose={() => {
                    setMostrarAlerta(false);
                }}
                options={opcoesAlerta}
            />
            <MateriaisSuporte
                strings={strings}
                config={global.gConfig}
                headers={getHeaders()}
                open={openMS}
                setOpen={setOpenMS}
            />
            {
                exibirNotificacao &&
                <SttNotificationScreen
                    config={global.gConfig}
                    strings={strings}
                    headers={getHeaders()}
                    perfisRBAC={user.perfisRBAC}
                />
            }
            <SttHelpdeskShortcut
                config={global.gConfig}
                usuario={user}
            />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTfd: id => dispatch(setTfdAction(id)),
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        tfd: state.index.tfd
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));
