import * as yup from 'yup';
import {
    HISTORICO_DOENCA,
    INTERESTADUAL
} from './fieldNames';
import { DADOS_GERAIS, TIPO_PROCESSO } from '../../dadosGerais/form/fieldNames';
import {
    TIPO_PROCESSO as TIPO_PROCESSO_CONSTANTES
} from '../../../../common/Constants';


export default (strings) => {
    return yup.object().shape({
        [INTERESTADUAL]: yup.object()
            .when(`${DADOS_GERAIS}.${TIPO_PROCESSO}`, {
                is: (val) => val === TIPO_PROCESSO_CONSTANTES.INTERESTADUAL,
                then: yup.object().shape({
                    [HISTORICO_DOENCA]: yup
                        .string()
                        .nullable()
                        .required(strings.campoObrigatorio)
                }).required()
            })
    });
}